import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import React from 'react';
import { tokens } from '../../../theme';
import { Color, Variant } from '../../../types/mui';


interface ButtonProps{
    label:string;
    type?:any;
    sx?:any;
    loading:boolean;
    handleClick?:any;
    color?:Color;
    variant:Variant;
    leftIcon?:any;
    rightIcon?:any
}
export default function ButtonLoading ({label,type,sx,loading,handleClick,color,variant,leftIcon,rightIcon}:ButtonProps){
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

return(
  <React.Fragment>
    <Box sx={{ my: 1,position: 'relative'}}>
    <Button
      variant={variant}
      color={color}
      sx={sx}
      disabled={loading}
      onClick={handleClick}
      type={type}
      fullWidth
    >
      {leftIcon&&leftIcon}
      { label }
      {rightIcon&&rightIcon}
    </Button>
    {loading && (
      <CircularProgress
        size={24}
        sx={{
          color: colors.green[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    )}
  </Box>
  </React.Fragment>
)
}
