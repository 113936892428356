import { Button, Card, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import TruncatedTextCard from '../../ui/Typography/description';
import { Vente } from '../../../types';


export default function VenteCard({item}:{item:Vente}){

    return(
        <>
            <Card  sx={{maxWidth:350,border:'solid 1px green',borderRadius:'1rem',":hover":{elevation:10,cursor:"pointer"}}}>
                        <CardMedia
                            component="img"
                            alt=" Outlier"
                            height="200"
                            image={JSON.parse(String(item.image)).thumbnail }
                        />
                        <CardContent>
                            <Typography sx={{fontWeight:700}} gutterBottom variant="h6" component="div">
                                Description
                            </Typography>
                            <TruncatedTextCard text={item.description} link={`/ventes/detail/${item.id_vente}`} />
                            <Typography  sx={{paddingTop:2.5,}}>
                                 <Typography component="span" sx={{fontWeight:600}}>
                                  Prix : {item.prix}
                                 </Typography>
                            </Typography>
                            <Typography component="span" sx={{fontWeight:600}}>
                                 Delais :{item.delais} jour(s)
                        </Typography>
                        </CardContent>
                        <CardActions>
                            <Stack direction="row"  
                            sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width:"100%"
                                }}>
                                <Button fullWidth size="small" 
                                variant='contained'color='secondary' href={`/ventes/reservation/${item.id_vente}`}>Réserver</Button>
                             </Stack>
                        </CardActions> 
               </Card>
         </>
    )
}