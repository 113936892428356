import { QueryOptions, Service, ServiceInput } from '../types';
import { ServicePaginator } from '../types/paginator';
import { ServiceQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';

export const ServiceClient = {
    ...crudFactory<Service, QueryOptions, ServiceInput>(API_ENDPOINTS.SERVICES),
    alls: ({ nom_service, ...params }: Partial<ServiceQueryOptions>) => {
      return HttpClient.get<ServicePaginator>(API_ENDPOINTS.SERVICES, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ nom_service }),
      });
    },
  };