import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Attachment } from '../../../types';
import { useUploadMutation } from '../../../data/upload';
// import { ACCEPTED_FILE_TYPES } from '../../../utils/constants';
import { useSnackbarAction } from '../snackbar/snackbar-context';
import { Box, CircularProgress, Fab, IconButton, Stack, Typography } from '@mui/material';
import { Close, FolderZip, Image, Upload } from '@mui/icons-material';

const getPreviewImage = (value: any) => {
  let images: any[] = [];
  if (value) {
    images = Array.isArray(value) ? value : [{ ...value }];
  }
  return images;
};
interface UploadProps{
  onChange?:any;
  value?:any;
  multiple:boolean;
  acceptFile?:string
  sx?:any;
  type?:"input"|"icon";
  setSelectedFile:any;
  helperText?:string;
}

export default function Uploader({
  onChange,
  value,
  multiple,
  acceptFile,
  sx,
  type="input",
  setSelectedFile,
  helperText,
}: UploadProps) {
    
  const [files, setFiles] = useState<Attachment[]>(getPreviewImage(value));
  const { mutate: upload, isLoading: loading } = useUploadMutation();
  const [error, setError] = useState<string | null>(null);
 const { openSnackbar } = useSnackbarAction()
 
  const { getRootProps, getInputProps } = useDropzone({
     accept: acceptFile !=="document" ? { 'image/jpeg': ['.jpg', '.jpeg'],
                                       'image/png': ['.png'],}: 
                                       {
                                        'application/pdf': ['.pdf'],
                                        'application/msword': ['.doc'],
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                                       } ,
     multiple,
    onDrop: async (acceptedFiles:any) => {
      const fileSizeLimit = 2 * 1024 * 1024; // 2 MB in bytes
      const validFiles = acceptedFiles.filter((file:any) => file.size <= fileSizeLimit);
  
      if (validFiles.length === 0) {
        // Handle the case when all files exceed the size limit
        openSnackbar('Il y a une erreur durant le téléchargement de votr fichier','error')
      
      }
  
      if (acceptedFiles.length) {

        upload(
          acceptedFiles, // it will be an array of uploaded attachments
          {
            onSuccess: (data: any) => {
              // Process Digital File Name section
              if(data) {
               data?.map((file: any, idx: number) => {
                  const splitArray = file?.original?.split('/');
                  const fileSplitName = splitArray[splitArray?.length - 1]?.split('.');
                  const fileType = fileSplitName?.pop(); // it will pop the last item from the fileSplitName arr which is the file ext
                  const filename = fileSplitName?.join('.'); // it will join the array with dot, which restore the original filename
                  data[idx]['file_name'] = filename + '.' + fileType;
                });
                }
              let mergedData;
              if (multiple) {
                mergedData = files.concat(data);
                setFiles(files.concat(data));
              } else {
                mergedData = data[0];
                setFiles(data);
                
              }
              if (onChange) {
                onChange(mergedData);
                
              }
              
              setSelectedFile(mergedData)
            }
          }
        );
      }
    },

    onDropRejected: (fileRejections:any) => {
      fileRejections.forEach((file:any) => {
        file?.errors?.forEach((error:any) => {
          // console.log(error)
          // if (error?.code === 'file-too-large') {
            setError(error?.message);
          // } else if (error?.code === 'file-invalid-type') {
          //   setError('error-invalid-file-type');
          // }
        });
      });
    },
  });

  const handleDelete = (image: string) => {
    const images = files.filter((file) => file.thumbnail !== image);
    setFiles(images);
    if (onChange) {
      onChange(images);
    }
  };

  const thumbs = files?.map((file: any, idx) => {
    const imgTypes = [
      'tif',
      'tiff',
      'bmp',
      'jpg',
      'jpeg',
      'gif',
      'png',
      'eps',
      'raw',
    ];
    // let filename, fileType, isImage;
   
    if (file && file.id) {
      // const processedFile = processFileWithName(file);
      const source = file.thumbnail
      const splitArray = file?.file_name
        ? file?.file_name.split('.')
        : file?.thumbnail?.split('.');
      const fileType = splitArray?.pop(); // it will pop the last item from the fileSplitName arr which is the file ext
      const filename = splitArray?.join('.'); // it will join the array with dot, which restore the original filename
      const isImage = file?.thumbnail && imgTypes.includes(fileType); // check if the original filename has the img ext
    
      return (
        <Box component="div"
          sx={{position:'relative',display:"flex", mt:2 , overflow:'hidden' }}
          key={idx}
        >
          {/* {file?.thumbnail && isImage ? ( */}
          {isImage ? (
            <figure>
              <Box component="img"
                src={source}
                alt={filename}
                width={28}
                height={25}
                sizes="(max-width: 768px) 100vw"
                className="object-contain"
              />
            </figure>
          ) : (
             <Box sx={{display:"flex",flexDirection:"column",justifyContent:'center',itemAlign:'center'}} >
  
              <Typography component="p" className="flex cursor-default items-baseline p-1 text-xs text-body">
                <FolderZip />
                <Typography component="span"  noWrap
                 sx={{paddingLeft:1,maxWidth:30,textOverflow: 'ellipsis', overflow: 'hidden',}}
                  title={`${filename}.${fileType}`}
                >
                  {filename}
                </Typography>
                .{fileType}
              </Typography>
            </Box>
          )}
          {multiple ? (
            <IconButton  sx={{justifyItems:'center',borderRadius:5,width:10,height:10,bgcolor:"red",":hover":{color:"red"}}}
              onClick={() => handleDelete(file.thumbnail)}
            >
              <Close fontSize='small'/>
            </IconButton>
          ) : null}
        </Box>
      );
    }
  });

  useEffect(
    () => () => {
      // Reset error after upload new file
      setError(null);

      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.thumbnail));
    },
    [files]
  );

  return (
    <section className="upload">
     
      <Box style={{ display: type!=='icon'? "none" : 'block'}} {...getRootProps({ })}>
        <Fab
            color="secondary"
            size="small"
            component="span"
            aria-label="add"
            variant="extended"
            sx={sx}
            >
              <Image />
        </Fab>
       </Box>

      <Box 
         sx={{display: type=='input'? "flex" : 'none',border:'1px dashed grey',paddingTop:2.5,cursor:'pointer', flexDirection: 'row',justifyItem:'center',justifyContent:'center',":focus":"1px dashed green",height:60}}
        {...getRootProps({// 'border-dashed border-2 border-border-base h-36 rounded flex flex-col justify-center items-center cursor-pointer focus:border-accent-400 focus:outline-none',
        })}
      >
        <input {...getInputProps()} />
        <Upload color='disabled' /> 
        <Typography color="textDisabled" component="p" sx={{textAlign:'center'}} className="mt-4 text-center text-sm text-body">
         
          {helperText ? (
            <span className="font-semibold text-gray-500">{helperText}</span>
          ) : (
            <Stack direction="row" spacing={1}>
              <Typography sx={{fontWeight:'semibold'}} className="font-semibold text-accent">
                Télécharger un image ou glisser et deposer
              </Typography>
              <Typography variant="body2" className="text-xs text-body"> PNG, JPG</Typography>
            </Stack>
          )}
        </Typography>
        
      </Box>

      {error && (
        <Typography component="p" sx={{textAlign:'center',color:'red' ,padding:2}}>
          {error}
        </Typography>
      )}

      {(!!thumbs.length || loading) && (
        <aside className="mt-2 flex flex-wrap">
          {!!thumbs.length && thumbs}
          {loading && (
            <Box sx={{mt:2,textAlign:'center'}} >
              <CircularProgress color="secondary" size={16}/>
            </Box>
          )}
        </aside>
      )}
    </section>
  );
}
