import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { GetParams, TypePaiement } from '../types';
import { TypePaiementPaginator } from '../types/paginator';
import { TypePaiementQueryOptions } from '../types/queryOptions';
import { TypePaiementClient } from '../api/type paiement';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { useModalAction } from '../components/ui/modal/modal.context';


export const useCreateTypePaiementMutation = () => {

  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbarAction()

  return useMutation(TypePaiementClient.create, {
    onSuccess: (data: any) => {
      
      openSnackbar( data?.message);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([API_ENDPOINTS.TYPEPAIEMENTS]);
    },
  });
};

export const useDeleteTypePaiementMutation = () => {

  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbarAction()

  return useMutation(TypePaiementClient.delete, {
    onSuccess: (data:any) => {
      console.log(data)
      openSnackbar( data?.message);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([API_ENDPOINTS.TYPEPAIEMENTS]);
    },
  });
};

export const useUpdateTypePaiementMutation = () => {

  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbarAction()
  const { closeModal} = useModalAction()
  
  return useMutation(TypePaiementClient.update, {
    onSuccess: (data:any) => {
      openSnackbar(data?.message);
      closeModal()
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([API_ENDPOINTS.TYPEPAIEMENTS]);
    },
  });
};

export const useTypePaiementQuery = ({ slug, language }: GetParams) => {
  return useQuery<TypePaiement, Error>({
   queryKey: [API_ENDPOINTS.TYPEPAIEMENTS, { slug, language }],
   queryFn: () => TypePaiementClient.get({ id:slug, language })
  });
};

export const useTypePaiementsQuery = (options?: Partial<TypePaiementQueryOptions>) => {
  const { data, isLoading, error } = useQuery<TypePaiementPaginator, Error>({
   queryKey:[API_ENDPOINTS.TYPEPAIEMENTS, options],
   queryFn: ({ queryKey, pageParam }) =>
      TypePaiementClient.alls(Object.assign({}, queryKey[1], pageParam)),
   keepPreviousData: true,

  });
  return {
    typePaiements: data ?? [],
    loading: isLoading,
    error,
  };
};
