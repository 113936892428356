import { SEO } from '../../../../components/Seo';



export default function TypePaimentPage() {
    return(
        <>
        <SEO  title='Type de Paiement'/>
          
        </>
    )
}