import { Box, Button, Typography,Grid2 as Grid } from '@mui/material'
import logos from '../../../logo 4x4.png';
import React, { useState } from 'react'
import Input from '../../Input';
import { TypePaiement } from '../../../types';
import ButtonLoading from '../../ui/button/button-loading';
import { useCreateTypePaiementMutation, useUpdateTypePaiementMutation } from '../../../data/type paiement';
import { useModalAction } from '../../ui/modal/modal.context';
// import { ButtonUpload } from '../../ui/button/button-upload';
import { typographyFont } from '../../../theme';
import Uploader from '../../ui/input/uploader';

export const TypePaiementForm = ({defaultValues}:{defaultValues:TypePaiement}) => {

  const { mutate: createType, isLoading: loading } = useCreateTypePaiementMutation()
  const { mutate: updateType, isLoading } = useUpdateTypePaiementMutation()
  const update = Boolean(defaultValues?.nom_type)
  
  const { closeModal } = useModalAction() 
  const defaultLogo = update ? JSON.parse(defaultValues.logo) : {thumbnail:logos}

  const [typePaiement, setTypePaiement] = useState(defaultValues.nom_type);
  const [numero, setNumero] = useState(defaultValues.numero);
  const [codeTransfert, setCodeTransfert]= useState(defaultValues.code_transfert);
  const [logo,setLogo] = useState<any>(defaultLogo)

  const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
      event.preventDefault(); 
      // console.log('Type paiement :', typePaiement,' ','logo:',logo)
      if(update){
        updateType({
          id: String(defaultValues?.id_type),
          nom_type: typePaiement,
          logo: JSON.stringify(logo),
          numero: numero,
          code_transfert: codeTransfert
        },{
          onSuccess: ()=>{
                closeModal()
          }
        })
      }else{
        createType({
          nom_type: typePaiement,
          logo: JSON.stringify(logo),
          numero: numero,
          code_transfert: codeTransfert
        },{
          onSuccess: ()=>{
                closeModal()
  
          }
        })
      }
  };
  
    const clearForm = () =>{
      closeModal()
    }

  return (
    
      <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
        <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire Type de Paiement</Typography>
         <Grid container> 
          <Grid size={{ xs:12 ,sm:5,md:4}} sx={{textAlign:'center'}}>
               <Box component="img" src={logo.thumbnail} alt="logo" width={{lg:200,md:200,sm:180,xs:150}}/>
          </Grid>
          <Grid size={{ xs:12 ,sm:7,md:8}}>
            <Uploader multiple={false}
                      setSelectedFile={setLogo}
                      value={logo}
                      // acceptFile={acceptFile}
                      // helperText={helperText} 
                 />
              {/* <ButtonUpload setSelectedFile={setSelectedFile} setValue={setLogo} /> */}
              <Input name='typePaiement'
                    placeholder='Type Paiement'
                    type='text' required 
                    value={typePaiement}
                    onChange={(e) => setTypePaiement(e.target.value)}
              />
             <Input name='numero'
                    placeholder='Numéro téléphone ou  carte bancaire'
                    type='text' required 
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
              />
              <Input name='codeTransfert'
                    placeholder='Code de transfert'
                    type='text'  
                    value={codeTransfert}
                    onChange={(e) => setCodeTransfert(e.target.value)}
              />
                
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid> 
            </Grid>
        </Grid>
      </Box>
  )
}