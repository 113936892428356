import PageContainer from '../../../components/layout/container';
import { Box } from '@mui/material';
import { SEO } from '../../../components/Seo';
import { HeaderTitle } from '../../../components/header/title';
import React from 'react';
import EmploieList from '../../../components/admin/emploie/liste';

export default function EmploieAdminPage (){
  return(
    <React.Fragment>
       <SEO title='Liste des emploies'/>
        <PageContainer>
            <Box sx={{px:4,pt:4,borderRadius:2}}>
                <HeaderTitle title="Emploies" addModalView="ADD_EMPLOIE" />
            </Box>
            <EmploieList />
        </PageContainer>
    </React.Fragment>
  )
}