import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';

const SocialButtons: React.FC = () => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginY: 2, marginTop: 1 }}>
        <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'white' }} />
        <Typography fontSize={{xl:20}} variant="body2" sx={{ margin: '0 10px', color: 'white' }}>
          Ou continuer avec
        </Typography>
        <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'white' }} />
      </Box>

      <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 1 }}>
        <Grid size={{xs:12}} >
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: '#EA4335',
              color: 'white',
              fontSize:{xl:20},
              borderRadius: '15px',
              '&:hover': {
                backgroundColor: '#D12E2A',
              },
            }}
            startIcon={<GoogleIcon />}
          >
            Google
          </Button>
        </Grid>
        <Grid size={{xs:12}}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: '#4267B2',
              color: 'white',
              fontSize:{xl:20},
              borderRadius: '15px',
              '&:hover': {
                backgroundColor: '#365E8C',
              },
            }}
            startIcon={<FacebookIcon />}
          >
            Facebook
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SocialButtons;