
export const  toMoney = (value:any) => {
  let newValue ="";
    if(value === null || value === undefined ){
      newValue = '0 Ar'
    }else {
      const n = String(value)
      const res = n.replace(/(\d)(?=(\d{3})+\b)/g,'$1 ')
      newValue = res +'Ar'
    }
   return newValue
   }

export const  groupByKey = (array:any,key:string) => {
     const data = array.reduce((r:any,a:any) => {
       r[a[key]] = [...r[a[key]] || [], a]
       return r
     }, {})
     return data
    }

export const Somme = (value1:any,value2:any) => {
    return Number(value1) + Number(value2)
}


