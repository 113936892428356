import { Reservation } from '../types';


export const getItemName = (record:Reservation) =>{

    if(record?.service_id){
        return  record?.service?.nom_service
    }else if(record?.vente_id){
        return `Achat ${record?.vente?.type_vente?.nom_type}-${record?.vente?.plateforme?.nom_plateforme}`
    }else{
        return "Inscription au "+record?.formation?.nom_formation
    }
}