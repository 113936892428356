import React from 'react';
import { useModalAction, useModalState } from './ui/modal/modal.context';
import ConfirmCard from './confirm-card';


export default function ConfirmDelete(){

  const { closeModal } = useModalAction();
  const { data } = useModalState();
  const { deleteMutation ,id,  deleteMessage,  } = data;


  const { mutate: deleteData, isLoading: loading } = deleteMutation()
  const handleConfirm = () =>{
        deleteData({id:id})
        closeModal();
  }

   return(
      <React.Fragment>
        <ConfirmCard title="Suppression"
            description={deleteMessage} acceptBtnText="Supprimer"
            cancelBtnText="Annuler" onCancel={closeModal}
            onAccept={ handleConfirm } acceptBtnLoading={loading} />
       </React.Fragment>)
}
