import React from 'react'
import { useDeleteFormationMutation, useFormationsQuery } from '../../../data/formation'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { Typography } from '@mui/material'
import { Formation } from '../../../types'
import Logo from '../../ui/image/logo'

export default function FormationList(){

    const { formations } = useFormationsQuery({})
    const headers =[
      {name:"Image",value:'image',
        template: function Render(record: Formation){
        
          return(
            <>
         {    record?.image &&( <Logo logo={record?.image} />) }
            </>
          )
        }
      },
      {name:"Nom de formation", value:"nom_formation"},
      {name:"Type de formation",value:"type_id",
        template:(record:Formation)=>{
          return(
              <Typography>{ record?.type_formation?.nom_type } </Typography>)
        }
      },
      {name:"Plateforme", value:"plateforme",
        template:(record:Formation)=>{
          return(
              <Typography>{ record?.plateforme?.nom_plateforme } </Typography>)
        }
      },
      {name:"Prix",value:"prix",
        template: (record: Formation) => (
          <Typography>Ar {record.prix}</Typography>
      )
      },
      {name:"Delais",value:"delais",
        template: (record: Formation) => (
          <Typography>{record.delais} Mois</Typography>
      )
      },
         { name: "Disponible", value: "is_disponible",
        template: function Render(record: any){
            
          return record.is_disponible == 1 ? 'Oui' : 'Non';
        }

      },
      {name:"Description",value:"description"},
      {name:"Action",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_formation,
                  deleteMutation:useDeleteFormationMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.id_formation}</b> de la liste des types de formation ?`,
                  ...record
                }}
                deleteModalView='DELETE'
                editModalView="ADD_FORMATION"
                detailLink={`/admin/formations/${record?.slug}`} 
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={formations} headers={headers}/>
      </React.Fragment>
    )
  }
  