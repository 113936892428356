import { crudFactory } from './crud-factory';
import { QueryOptions, TypeFormation, TypeFormationInput } from '../types';
import { API_ENDPOINTS } from './api-endpoints';
import { TypeFormationQueryOptions } from '../types/queryOptions';
import { HttpClient } from './http-clients';
import { TypeFormationPaginator } from '../types/paginator';

export const TypeFormationClient = {
    ...crudFactory<TypeFormation, QueryOptions, TypeFormationInput>(API_ENDPOINTS.TYPEFORMATIONS),
    alls: ({ nom_type, ...params }: Partial<TypeFormationQueryOptions>) => {
      return HttpClient.get<TypeFormationPaginator>(API_ENDPOINTS.TYPEFORMATIONS, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ nom_type }),
      });
    },
  };
  
