import { QueryOptions, TypeService, TypeServiceInput } from '../types';
import { TypeServicePaginator } from '../types/paginator';
import { TypeServiceQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';

export const TypeServiceClient = {
    ...crudFactory<TypeService, QueryOptions, TypeServiceInput>(API_ENDPOINTS.TYPESERVICES),
    alls: ({ nom_type, ...params }: Partial<TypeServiceQueryOptions>) => {
      return HttpClient.get<TypeServicePaginator>(API_ENDPOINTS.TYPESERVICES, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ nom_type }),
      });
    },
  };