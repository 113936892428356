import { Box, Button, Grid2, Rating, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { typographyFont } from '../../../theme';
import TruncatedTextCard from '../../ui/Typography/description';
import { useEmploiesQuery } from '../../../data/emploie';
import Logo from '../../ui/image/logo';


export default function ListeEmploies() {
  const [value] = React.useState<number | null>(3);
  
  const { emploies } = useEmploiesQuery({})
 
    return(
    <>
       <Stack  sx={{cursor:"pointer"}}>
        {
            emploies.map(offre  => ( 
                <Grid2 key= {offre.id_emploie} direction="row"  spacing={3} sx={{border:'solid 1px grey',borderRadius:'1rem'}} margin={3} padding={3} container>
                  <Grid2 size={{xs:12,sm:3}}>
                    <Logo logo={offre?.image} width="90%" height={{xs:110,sm:250}} />
                  </Grid2>
                  <Grid2 size={{xs:12,sm:9}}>
                        <Typography sx={{...typographyFont.h4,fontWeight:500,}}>{offre.titre_emploie}</Typography>
                        <Box height={130}>
                           <TruncatedTextCard text={offre.description} link={`/emploies/detail/${offre.id_emploie}`} height={200}/>
                         </Box>
                        <Grid2 spacing={2}> 
                        <Rating name="read-only" value={value} readOnly />   
                          {/* {offre.skills.map(skill  => ( 
                            <Typography component="span"
                                        key={skill.id} 
                                        style={{background:colors.grey[700],
                                                color:"white",
                                                padding:'0.3rem 1rem',
                                                borderRadius:'1rem'}}
                            >{
                                skill.desc
                              }
                            </Typography>
                          
                          ))} */}
                        </Grid2>
                        <Box marginX={{xs:0,md:2}} marginY={2}>
                          <Button variant='contained' color='secondary' href={`/emploies/postuler/${offre.id_emploie}`}>Postuler</Button>
                        </Box>
                   </Grid2>
                </Grid2>
            ))
        }
        </Stack>
    </>
    )
}