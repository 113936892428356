
import { Box, Button,Grid2,Stack, Typography, useTheme } from '@mui/material';
import { tokens, typographyFont } from '../../../theme';


export default function Freelance()  {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
    const Programming = `${process.env.PUBLIC_URL}/illustration/yellow/Programming-pana.png`;

    return(
        <>
        <Grid2 container  sx={{padding:'1rem 1rem',bgcolor:'white'}}>
          <Grid2 size={12}  sx={{background: colors.green[500],borderRadius:'1rem',padding:{xs:2,md:8}}}>
            <Stack sx={{justifyContent:'space-between'}} direction={{ xs: 'column', sm: 'row' }}spacing={{ xs: 1, sm: 30, md: 2 }}  >
            
                <Typography  data-aos="fade-right"  variant='h1' sx={{color:'white',...typographyFont.h1,fontWeight:800,lineHeight:1.1}}>
                  Nous pouvons <br/>vous aider  à créer <br /> des différents <br /><span style={{color:colors.yellow[500]}}>
                  comptes freelances</span> <br />pour vous!
                </Typography>
            <Box  data-aos="zoom-in" component='img'  sx={{width:{xs:300,md:500},height:500}} src={Programming} alt="mode gratuit"/>
            
            </Stack>
          <Button data-aos="fade-left" variant='contained' color="inherit" sx={{padding:'0.5rem 2rem',marginTop:'-2rem'}} >Voir plus</Button>
      </Grid2>
    </Grid2>
        </>
    )
} 