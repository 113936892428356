import { QueryOptions, Reservation, ReservationInput } from '../types';
import { ReservationPaginator } from '../types/paginator';
import { ReservationQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';

export const ReservationClient = {
    ...crudFactory<Reservation, QueryOptions, ReservationInput>(API_ENDPOINTS.RESERVATION),
    alls: ({ id_reservation, ...params }: Partial<ReservationQueryOptions>) => {
      return HttpClient.get<ReservationPaginator>(API_ENDPOINTS.RESERVATION, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ id_reservation: id_reservation !== undefined ? id_reservation.toString() : undefined  }),
      });
    },
  };
  