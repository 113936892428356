import { useState } from 'react'
import { useCreateVenteMutation, useUpdateVenteMutation } from '../../../data/vente'
import { Vente } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import { Box, Typography,Grid2 as Grid, Button, FormControlLabel, Checkbox } from '@mui/material'
import { typographyFont } from '../../../theme'
import Input from '../../Input'
import ButtonLoading from '../../ui/button/button-loading'
import { TypeVenteInput } from '../type vente/select'
import { PlateformInput } from '../plateform/select'
import logos from '../../../logo 4x4.png';
import Uploader from '../../ui/input/uploader'
import TextEditor from '../../ui/input/text-editor'

export const VenteForm = ({defaultValues}:{defaultValues:Vente}) => {

    const { mutate: createType, isLoading: loading } = useCreateVenteMutation()
    const { mutate: updateType, isLoading } = useUpdateVenteMutation()
    const update = Boolean(defaultValues?.id_vente)
    
    const { closeModal } = useModalAction()
    const defaultLogo = update ? JSON.parse(defaultValues.image) : {thumbnail:logos}

    const [typeVente, setTypeVente] = useState<any>(defaultValues.type_vente);
    const [prix, setPrix] = useState(defaultValues.prix);
    const [delais, setDelais] = useState(defaultValues.delais);
    const [plateform, setPlateform] = useState<any>(defaultValues.plateforme);
    const [image,setImage] = useState<any>(defaultLogo)
    const [description, setDescription] = useState(defaultValues.description);  
    const [isDisponible, setIsDisponible] = useState<boolean>(update ? defaultValues.is_disponible : false); 
      const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
       
        if(update){
          updateType({
            id: String(defaultValues?.id_vente),
            prix: prix,
            delais: delais,
            type_id: typeVente.id_type,
            plateforme_id: plateform.id_plateforme,
            image: JSON.stringify(image),
            description: description,
            is_disponible: isDisponible
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
            prix: prix,
            delais: delais,
            type_id: typeVente.id_type,
            plateforme_id: plateform.id_plateforme,
            image: JSON.stringify(image),
            description: description,
            is_disponible: isDisponible
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
  
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
          <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire  Vente</Typography>
           <Grid container> 
           <Grid size={{ xs:12 ,sm:5,md:4}} sx={{textAlign:'center'}}>
               <Box component="img" src={image.thumbnail} alt="logo" width={{lg:200,md:200,sm:180,xs:150}}/>
          </Grid>
          <Grid size={{ xs:12 ,sm:7,md:8}}>
                <Uploader multiple={false}
                            setSelectedFile={setImage}
                            value={image}
                            // acceptFile={acceptFile}
                            // helperText={helperText} 
                      />
                      <Box display='flex' gap={2}>
                        <PlateformInput  setSelected={setPlateform} initialValue={plateform}/>
                        <TypeVenteInput setSelected={setTypeVente} initialValue={typeVente}/>
                      </Box>
                <Box display='flex' gap={2}>
                <Input name='prix'
                      placeholder='Prix'
                      type='text' required 
                      value={prix !== undefined ? prix.toString() : ''}
                      onChange={(e) => setPrix(Number(e.target.value))}
                />
                 <Input name='delais'
                      placeholder='Delais'
                      type='text' required 
                      value={delais !== undefined ? delais.toString() : ''}
                      onChange={(e) => setDelais(Number(e.target.value))}
                />
                </Box>
                <FormControlLabel
                        control={
                            <Checkbox
                                color='secondary'
                                name='isDisponible'
                                checked={isDisponible} 
                                onChange={(e) => setIsDisponible(e.target.checked)} 
                            />
                        }
                        label="Disponible"
                    />
                <TextEditor editorContent={description} setEditorContent={setDescription}/>
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}>
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid> 
                 </Grid>
            </Grid>
        </Box>
    )
  }