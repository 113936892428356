import React from 'react';
import { useModalAction, useModalState } from './ui/modal/modal.context';
import ConfirmCard from './confirm-card';


export default function ValidateAction(){

  const { closeModal } = useModalAction();
  const { data } = useModalState();
  const { actionMutation ,id,actionData,  actionMessage, actionTitle,acceptBtnColor,cancelBtnColor, acceptBtnText,titleColor,icon } = data;


  const { mutate: userAction, isLoading: loading } = actionMutation()
  
  const handleConfirm = () =>{
    const value = actionData ? actionData : {id:id}
    userAction({...value},{
          onSuccess: () =>{
            closeModal()
          }
        })
  }

   return(
      <React.Fragment>
        <ConfirmCard 
            title={actionTitle}
            description={actionMessage}
            acceptBtnText={acceptBtnText}
            acceptBtnColor={acceptBtnColor}
            cancelBtnColor={cancelBtnColor}
            titleColor={titleColor}
            icon={icon}
            cancelBtnText="Annuler" 
            onCancel={closeModal}
            onAccept={ handleConfirm } 
            acceptBtnLoading={loading} />
       </React.Fragment>)
}
