import { Box, Container, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { SEO } from '../../components/Seo';
import Grid from '@mui/material/Grid2';
import RegisterForm from '../../components/auth/RegisterForm';
import SocialButtons from '../../components/auth/SocialBouton';
import { tokens } from '../../theme';

const Register = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
    const image = `${process.env.PUBLIC_URL}/illustration/yellow/Sign up-bro.png`;
    const image2 = `${process.env.PUBLIC_URL}/illustration/green/Sign up-amico.png`;
    return (
        <Box component='div' sx={{ backgroundColor:  colors.primary[600], minHeight: '100vh' }}>
            <SEO title='Register' />
            <Container component="main" maxWidth="xl">
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <Grid size={{xs:12, md:5}} >
                        <Paper elevation={2} sx={{ boxShadow:'2px 2px 5px 2px black', padding: { xs: 1, sm: 2,xl:4 },margin:{sm:1},marginTop:{xs:3,xl:15,md:0.5}, backgroundColor: colors.grey[800], borderRadius: 5,width: { xl: '70vh' },height:{xl:'75vh'} }}>
                            <Box display="flex" alignItems="center" justifyContent='center' >
                                <Box component="img" src={logo} alt="Logo" sx={{ width: 'auto', height: '50px', marginRight: 2 }} />
                                <Typography fontSize={{xl:70}} variant="h2" align="left" gutterBottom sx={{ fontFamily: 'Inika', color: 'white' }}>
                                    Register
                                </Typography>
                            </Box>
                                <RegisterForm />
                                <SocialButtons />
                            <Box component="form">
                                <Typography fontSize={{xl:20}} variant="body2" align="center" sx={{ marginTop: 2, color: 'white' }}>
                                    Vous avez déjà un compte ?
                                    <Link to="/login" style={{ textDecoration: 'none' }}>
                                        <span style={{ color: '#1F6F42' }}> Se connecter</span>
                                    </Link>
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid size={{xs:12, md:7}} display="flex" justifyContent="center" sx={{ position: 'relative' }}>
                        <Box component="img" src={image} alt="Illustration" sx={{
                                position: 'relative',
                                width: { sm: '60%',xl:'90%' },
                                zIndex: 1,
                                left: { sm: '40%',xl:'40%' },
                                bottom: { sm: '20%' },
                                objectFit: 'contain',
                                display: { xs: 'none', sm: 'block' }, 
                            }} 
                        />
                        <Box component="img" src={image2} alt="Illustration" sx={{
                                position: 'relative',
                                width: { sm: '40%',xl:'40%' },
                                zIndex: 2,
                                right: { sm: '45%',xl:'60%' },
                                paddingTop: { sm: '35%',xl:'60%' },
                                objectFit: 'contain',
                                display: { xs: 'none', sm: 'block' }, 
                            }} 
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Register;