import { Box } from '@mui/material';
import { SEO } from '../../../components/Seo';
import Typography from '@mui/material/Typography';
import { typographyFont } from '../../../theme';
import Footer from '../../../components/footer/Footer';
import  Header  from '../../../components/header/Header';
import { useParams } from 'react-router-dom';

export default function DetailForamtionPage() {
    const { slug } = useParams<{ slug: string }>();
    console.log('detail formation:' ,slug);
    const trading = `${process.env.PUBLIC_URL}/illustration/microtache/websecret.jpeg`;
    return( 
    <>
        <SEO title='Detail formations'/>
        <Header description="Découvrez notre plateforme complète qui vous accompagne dans chaque étape votre parcours freelance : création de comptes, configuration de portefeuilles " /> 
        <Box> <hr />
            <Typography sx={{...typographyFont.h2,fontWeight:800,textAlign:'center',padding:'2rem'}}
                      color='' variant="h1">Detail Formation {slug}</Typography>
                      <Box> 
                        <Box component='img' width={{sx:300,md:400}} src={trading}/>
                      </Box>
            
        </Box>
        <Footer/>
    </>
    )
}