
import { Box } from '@mui/material';
import ListeFormations from '../../../components/clients/formations/liste';
import  Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { SEO }  from '../../../components/Seo';
export default function FormationsPage() {
    return(
        <>
            <SEO title='Formations disponibles'/>
                <Header 
                    description=" Découvrez notre plateforme complète qui vous accompagne dans chaque étapevotre parcours freelance : création de comptes, configuration de portefeuilles "
                        />       
                    <Box>
                        <ListeFormations/>
                    </Box>
                <Footer />
        </>
    )
}
