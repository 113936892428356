import React from 'react';
import { SEO } from '../../../components/Seo';
import PageContainer from '../../../components/layout/container';
import { Box } from '@mui/material';
import { HeaderTitle } from '../../../components/header/title';
import VenteList from '../../../components/admin/vente/liste';

export default function VenteAdminPage (){
  return(
    <React.Fragment>
       <SEO title='Liste des ventes'/>
        <PageContainer>
            <Box sx={{px:4,pt:4,borderRadius:2}}>
                <HeaderTitle title="Ventes" addModalView="ADD_VENTE" />
            </Box>
            <VenteList />
        </PageContainer>
    </React.Fragment>
  )
}