import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { tokens } from '../../../theme'
import usePathName from '../../../utils/router'
import Tooltips from '../../ui/tooltip'
import { getIcon } from '../../../utils/get-icon'
import * as sidebarIcons from '@mui/icons-material'


interface ItemProps {
    title:string,
     to:string,
     icon:string,
     selected: string,
     setSelected:Dispatch<SetStateAction<string | undefined>>,
     open?:boolean
}
 const Item = ({title, to, icon, selected,setSelected,open}:ItemProps) =>{
    const theme = useTheme()
   const colors = tokens(theme.palette.mode)

   const pathName = usePathName()

   useEffect(()=>{
    if(pathName===to){
         setSelected(to)
    }
   },[selected])

    const navigate = useNavigate()

      const gotToNewPage=(to:string)=>{
        navigate(to);
      }

    return (
      <React.Fragment>
         <Tooltips variant="light" title={open==true?"":title} placement="right-end" arrow={true}>
            <ListItem style={{cursor:'pointer',color:colors.grey[100],backgroundColor:`${selected === to ?colors.green[500]:colors.primary[800]}` }} disablePadding sx={{ display: 'block' }} onClick={()=>{ gotToNewPage(to); setSelected(to)}} >
                <ListItemButton  sx={{minHeight: 48, justifyContent: open ?'center'  : 'initial',px: 2.5}}  >
                <ListItemIcon sx={{ minWidth: 0, mr:5,ml:1, justifyContent: 'center'}} >
                      { getIcon({
                        iconList: sidebarIcons,
                        iconName: icon,
                        className: 'w-5 h-5 me-4'
                      })}
                </ListItemIcon>
               <ListItemText >{title}</ListItemText>
            </ListItemButton>

            </ListItem>
        </Tooltips>
        </React.Fragment>)
}

export default Item
