import { AlertComponent } from './snack-bar';
import { useSnackbarAction, useSnackbarState } from './snackbar-context';



const ManagedSnackbar = () => {
    const { isOpen,message, duration,  severityType, vertical, horizontal } = useSnackbarState();
    const { closeSnackbar } = useSnackbarAction()
    
    return (
        <AlertComponent message={message}
                         open={isOpen} 
                         autoHideDuration={duration&&duration} 
                         severity={severityType&&severityType} 
                         onClose={closeSnackbar} 
                         vertical={vertical&&vertical}
                         horizontal={horizontal&&horizontal}  />
    );
  };
  
  export default ManagedSnackbar;