import React from 'react';
import { SEO } from '../components/Seo';
import { NotFound } from '../components/not-found/not-found';


export default function NoMatchPage() {
  return (
    <React.Fragment>
      <SEO  title='Page introuvable'/>
      <NotFound  text={"Page not found"}/>
  </React.Fragment>
  );
}
