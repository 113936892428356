import { Box } from '@mui/material';
import { SEO } from '../../../components/Seo';
// import Typography from '@mui/material/Typography';
// import { typographyFont } from '../../../theme';
import Footer from '../../../components/footer/Footer';
import  Header  from '../../../components/header/Header';

export default function DetailVentePage() {
 
    return( 
    <>
        <SEO title='Detail ventes'/>
        <Header description="Découvrez notre plateforme complète qui vous accompagne dans chaque étapevotre parcours freelance : création de comptes, configuration de portefeuilles " /> 
        <Box bgcolor='white'>
            {/* <Typography sx={{...typographyFont.h2,fontWeight:800,textAlign:'center',padding:'2rem'}} color='primary' variant="h1">NOS COMPTE DISPONIBLE</Typography> */}
            
        </Box>
        <Footer/>
    </>
    )
}