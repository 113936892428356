import { Box, Button, Grid2, Stack, Typography, colors } from '@mui/material';
import List from '@mui/material/List';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import SecurityIcon from '@mui/icons-material/Security';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { typographyFont } from '../../../theme';



export default function VenteLoginFreelance() {
     const Business = `${process.env.PUBLIC_URL}/illustration/green/Business merger-pana.png`;
     const banknote = `${process.env.PUBLIC_URL}/illustration/yellow/Banknote-bro.png`;
    
   
    return(
       <>
       <Grid2 container  sx={{justifyContent:'center',color:'black',bgcolor:'white'}}
              spacing={{ xs: 1, sm: 1, md: 3 }} 
              paddingX={{xs:2,md:2}} 
              
              paddingY={{xs:2,md:4}}
              direction={{ xs: 'column', sm: 'row' }}>
            <Grid2 size={{xs:12,md:8}}  padding={{xs:1,sm:2,md:3}}   sx={{background:'#bdbdbd', borderRadius:'1rem'}}>
               <Typography data-aos="fade-right" variant='h3'sx={{padding:'2rem',fontWeight:800,...typographyFont.h1,lineHeight:'1.1'}}>
                    Accélérez votre <br /> carrière <br />
                    freelance avec <br /> des comptes <br />
                    prêts à l'emploi.
                </Typography>
                <Typography data-aos="fade-left" sx={{...typographyFont.h4,fontWeight:700,padding:'2rem'}} >
                  Pourquoi Acheter un Compte Freelance chez Nous ?
                </Typography>

                <Grid2>
                <List sx={{ width: '100%', maxWidth: 460 }}>
                   <Grid2 sx={{display:'flex',margin:'1rem 1rem'}}>
                     < AccessTimeIcon color='secondary' sx={{fontSize:'2.5rem',marginx:2}}/>
                    <Box data-aos="fade-right" paddingInline={1}>
                      <Typography  sx={{...typographyFont.h5}}>Gain de Temps</Typography>
                        Fini les démarches longues et fastidieuses. Nos comptes sont prêts à l'emploi,
                        vous êtes opérationnel en quelques minutes.
                        Gain de Temps
                    </Box>
                   </Grid2>

                   <Grid2 data-aos="fade-right" sx={{display:'flex',margin:'1rem 1rem'}}>
                     <VerifiedIcon  color='secondary' sx={{fontSize:'2.5rem',marginx:1}}/>
                    <Box paddingInline={1}>
                      <Typography  sx={{...typographyFont.h5}}>Profils optimisés</Typography>
                      Fini les démarches longues et fastidieuses. Nos comptes sont prêts à l'emploi,
                      vous êtes opérationnel en quelques minutes.
                      Gain de Temps
                   </Box>
                   </Grid2>

                   <Grid2 data-aos="fade-up" sx={{display:'flex',margin:'1rem 1rem'}}>
                     < SecurityIcon  color='secondary' sx={{fontSize:'2.5rem',marginx:1}}/>
                     <Box  paddingInline={1}>
                        <Typography sx={{...typographyFont.h5}}>Securité Assurée</Typography>
                        Fini les démarches longues et fastidieuses. Nos comptes sont prêts à l'emploi,
                         vous êtes opérationnel en quelques minutes.
                     </Box>
                   </Grid2>

                   <Grid2 data-aos="fade-up" sx={{display:'flex',margin:'1rem 1rem'}}>
                     < ContactSupportIcon  color='secondary' sx={{fontSize:'2.5rem',marginx:1}}/>
                     <Box paddingInline={1} >
                        <Typography sx={{...typographyFont.h5}}>Support 24/7</Typography>
                        Besoin d'aide ? Notre équipe est là pour vous accompagner à chaque étape.
                     </Box>
                   </Grid2>
                 </List>
                 <Button data-aos="fade-left" variant='contained' color="secondary" sx={{marginTop:{xs:1,sm:2,md:25},marginX:3}}>Plus d'info</Button>
                </Grid2>
            </Grid2>
             {/* content Two black */}

            <Grid2  padding={{xs:1,sm:1,md:0}} size={{xs:12,md:4}}  sx={{background:colors.grey[900],color:'white',borderRadius:'1rem'}}>
                <Grid2 >
                   <Box data-aos="fade-left" component='img'  sx={{width:{xs:300 ,sm:"100%",md:450,xl:500},marginLeft:{xs:0,md:-30}}} src={Business} alt="" />
                </Grid2>

                <Grid2  sx={{margin:'1rem 2rem'}}>
                  <Typography sx={{...typographyFont.h3,fontWeight:800}} data-aos="fade-down-right"> Revenu par semaine</Typography>
                     <Typography data-aos="fade-up">Plus de 100 Euro ou Dollard selon la durée de votre heure de travail.</Typography>
                </Grid2>

                <Grid2 sx={{borderRadius:'1rem'}}>
                    <Box data-aos="zoom-out" component='img'  sx={{width:{xs:300 ,sm:"100%",md:450,xl:500} ,marginLeft:{xs:0,md:-30}}} src={banknote} alt="" />
                </Grid2>

                <Grid2 data-aos="fade-up" sx={{paddingX:3 }}>
                    <Stack direction="row">
                      <StarHalfIcon sx={{color:'#ffc107',fontSize:'2.2rem'}}/> 
                      <Typography component="span" style={{fontSize:'1.5rem'}}>4.5/5</Typography> <br />
                   </Stack>
                   <Typography paddingInline={2} textAlign="center" component="span" >Clients rate  on Gasy M’ketrika</Typography>
                </Grid2>

                <Grid2  data-aos="fade-right"  data-aos-duration="3500"  sx={{padding:3}}>
                    <Stack  direction="row">
                      <StarHalfIcon sx={{color:'#ffc107',fontSize:'2.2rem'}}/>
                      <Typography component="span" style={{fontSize:'1.5rem'}}>Clients Satisfaits</Typography><br />
                    </Stack>
                    <Typography paddingInline={2}  textAlign="center" component="span" >Plus de 95% de nos clients</Typography>
                </Grid2>
            </Grid2>
        </Grid2>
       </>
    )
}