import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { tokens } from '../../../theme';
import { useTheme } from '@mui/material';
import TruncatedTextCard from '../Typography/description';


interface HeaderProps{
  value:any;
  template:any
  name:string
  align?:'left'|'right'|'inherit'|'center'
}

function Row(props: { row: any,headers:HeaderProps[],collapse?: boolean,collapseTemplate?: any }) {
  const { row,headers,collapse, collapseTemplate} = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        { collapse&& (<TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>)}

        { headers.map((header:HeaderProps)=>(
          
            <TableCell key={header.value} sx={{maxWidth:400, }} align={header.value==="description"? "left" :"center"} scope="row">
                
              { header?.template ?  
                  (<Box>{ header?.template(row) }</Box>) 
                   :
                  ( <TruncatedTextCard text={ row[header.value]}  link={ row['slug'] }/>)
              }
            </TableCell>
            ))}
      </TableRow>
     {collapse && ( <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={collapse ? headers.length+1 : headers.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 1 }}>
             {
                collapseTemplate(row)
             }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>)}
    </React.Fragment>
  );
}


interface TableProps{
    headers:any,
    collapse?:boolean,
    collapseTemplate?:any
    data:any
}

export default function CollapsibleTable({headers,data,collapse,collapseTemplate}:TableProps) {
   
   const length =collapse? headers.length : headers.length-1
   const theme = useTheme()
   const colors = tokens(theme.palette.mode)
 
   return (
    <TableContainer component={Paper} sx={{bgcolor:colors.primary[500]}}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {collapse&&(<TableCell />)}
            {headers.map((header:any,id:number)=>
                (<TableCell key={id}  align="center">{header.name}</TableCell>)
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          { data.length>=1? data.map((row:any,id:number) => (
            <Row key={id} row={row} headers={headers} collapse={collapse} collapseTemplate={collapseTemplate}/>
          )) :
          ( <TableRow>
           {headers.slice(0,headers.length).map((header:any,index:number)=>
                (<TableCell key={index} align="center" >
                    { index == Math.ceil((length) /2)  && (<span>Auccune donnée trouvée</span>)}
                </TableCell>)
            )}
            </TableRow>)
        }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
