import { Box } from '@mui/material'
import { SEO } from '../../../components/Seo'
// import Header from '../../../components/header/Header'
import NoMatch from '../../notFound';
import { useParams } from 'react-router-dom';
// import ServiceDevPage from '../../../components/clients/services/developpement-web/liste';
import DetailCreerCompteFreelance from '../../../components/clients/services/creeCompteClients/detail';
import DetailCreePortfeuillClient from '../../../components/clients/services/creePrtefeuilleClient/detail';


const DetailServicePage:React.FC = () =>{
 
    const { slug } = useParams<{ slug: string }>();
    console.log("slug:", slug);
    const renderServiceComponent = () => {
   
    if (slug === 'creation-compte-freelance') {
        return  <DetailCreerCompteFreelance />;
    } else if (slug === 'creation-portefeuille-electronique') {
        return <DetailCreePortfeuillClient />;
    } else {
        return <NoMatch/>;
    }
};

   return(
       <>
            <SEO title='Nos service' />
                <Box>
                  {renderServiceComponent()}
                </Box>
        </>
    )
}

export default DetailServicePage