import React from 'react'
import { useBlockUserMutation, useDeleteUser, useUsersQuery } from '../../../data/user'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { userType } from '../../../types/enum'

export default function AdminList(){

    const { users } = useUsersQuery({type:userType.ADMIN})

    console.log(users)
    
    const headers =[
      {name:"Nom",value:"name"},
      {name:"Prenom", value:"lastname"},
      {name:"Email",value:"email"},
      {name:"Action",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_user,
                  deleteMutation:useDeleteUser,
                  blockMutation: useBlockUserMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.name}</b> de la liste des admins ?`,
                  ...record

                }}
                deleteModalView='DELETE'
                // editModalView="ADD_FORMATION"
                blockLink='USER_MODAL'
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={users} headers={headers}/>
      </React.Fragment>
    )
  }
  