import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { TypePlateformClient } from '../api/type plateform';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { useModalAction } from '../components/ui/modal/modal.context';
import { GetParams, TypePlateform } from '../types';
import { TypePlateformQueryOptions } from '../types/queryOptions';
import { TypePlateformPaginator } from '../types/paginator';

export const useCreateTypePlateformMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(TypePlateformClient.create, {
      onSuccess: (data: any) => {
        
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEPLATEFORMS]);
      },
    });
  };
  
  export const useDeleteTypePlateformMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(TypePlateformClient.delete, {
      onSuccess: (data:any) => {
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEPLATEFORMS]);
      },
    });
  };
  
  export const useUpdateTypePlateformMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(TypePlateformClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEPLATEFORMS]);
      },
    });
  };
  
  export const useTypePlateformQuery = ({ slug, language }: GetParams) => {
    return useQuery<TypePlateform, Error>({
     queryKey: [API_ENDPOINTS.TYPEPLATEFORMS, { slug, language }],
     queryFn: () => TypePlateformClient.get({ id:slug, language })
    });
  };
  
  export const useTypePlateformsQuery = (options?: Partial<TypePlateformQueryOptions>) => {
    const { data, isLoading, error } = useQuery<TypePlateformPaginator, Error>({
     queryKey:[API_ENDPOINTS.TYPEPLATEFORMS, options],
     queryFn: ({ queryKey, pageParam }) =>
        TypePlateformClient.alls(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      typePlateforms: data ?? [],
      loading: isLoading,
      error,
    };
  };