import { Box } from '@mui/material';

interface LogoProps {
    logo:string,
    width?:string|number;
    height?:string|number|any
}

export default function Logo({logo,width=35,height=35}:LogoProps){

    return(
        <>
          <Box component="img"
               src={JSON.parse(logo)?.original}
               width={width} height={height}
               borderRadius={2} />
        </>
      )
}