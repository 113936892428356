import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { EmploieClient } from '../api/emploie';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { useModalAction } from '../components/ui/modal/modal.context';
import { Emploie, GetParams } from '../types';
import { EmploieQueryOptions } from '../types/queryOptions';
import { EmploiePaginator } from '../types/paginator';
import { getFormErrors } from '../api/http-clients';

export const useCreateEmploieMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(EmploieClient.create, {
      onSuccess: (data: any) => {
        
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.EMPLOIE]);
      },
    });
  };
  
  export const useDeleteEmploieMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(EmploieClient.delete, {
      onSuccess: (data:any) => {
        console.log(data)
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.EMPLOIE]);
      },
    });
  };
  
  export const useUpdateEmploieMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(EmploieClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      onError:(error:any)=>{
        openSnackbar(getFormErrors(error),'error');
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.EMPLOIE]);
      },
    });
  };
  
  export const useEmploieQuery = ({ slug, language }: GetParams) => {
    return useQuery<Emploie, Error>({
     queryKey: [API_ENDPOINTS.EMPLOIE, { slug, language }],
     queryFn: () => EmploieClient.get({ id:slug, language })
    });
  };
  
  export const useEmploiesQuery = (options?: Partial<EmploieQueryOptions>) => {
    const { data, isLoading, error } = useQuery<EmploiePaginator, Error>({
     queryKey:[API_ENDPOINTS.EMPLOIE, options],
     queryFn: ({ queryKey, pageParam }) =>
        EmploieClient.alls(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      emploies: data?.data ?? [],
      loading: isLoading,
      error,
    };
  };
  