import {  useRoutes } from 'react-router-dom';
import NoMatch from '../views/notFound';
import Accueil from '../views/home';
import Register from '../views/Auth/Register';
import ForgetPassword from '../views/Auth/ForgetPassword';
import Relogin from '../views/Auth/Relogin';
import SuccessCreateCompte from '../views/Auth/SuccessCreateCompte';
import VerificationPassword from '../views/Auth/VerificationPassword';
import ResetPassword from '../views/Auth/ResetPassword';
import VerificationPassword2 from '../views/Auth/VerificationPassword2';
import Login from '../views/Auth/Login';
import FormationListPage from '../views/admin/formation/Liste';
import TypePaiementPage from '../views/admin/type paiement/Liste';
import TypePlateformPage from '../views/admin/type plateform/Liste';
import TypeServicePage from '../views/admin/type service/Liste';
import TypeVentePage from '../views/admin/type vente/Liste';
import PlateformPage from '../views/admin/plateform/Liste';
import VenteAdminPage from '../views/admin/vente/Liste';
import PaiementPage from '../views/admin/paiement/Liste';
import Home from '../views/homePage';
import FormationByTypePage from '../views/clients/formations/types';
import FormationsPage from '../views/clients/formations';
import EmploiePage from '../views/clients/emploies';
import ServicePage from '../views/clients/services';
import DetailEmploiePage from '../views/clients/emploies/detail';
import VentePage from '../views/clients/ventes/liste';
import DetailVentePage from '../views/clients/ventes/detail';
import ReservationVentePage from '../views/clients/ventes/reservation';
import PostulationPage from '../views/clients/emploies/postulation';
import DetailForamtionPage from '../views/clients/formations/detail';
import PaimentPage from '../views/clients/paiement';
import TypePaimentPage from '../views/clients/paiement/type';
import ClientPage from '../views/admin/client/Liste';
import FormationPage from '../views/admin/formation/Liste';
import ServiceAdminPage from '../views/admin/service/Liste';
// import EmploieAdminPage from '../views/admin/emploi/Liste';
import TypeFormationPage from '../views/admin/type formation/Liste';
import ReservationPage from '../views/admin/reservation/Liste';
import ProfilePage from '../views/Auth/Profile';
import { DashboardPage } from '../views/admin/dashboard/dashboard';
import CandidatPage from '../views/admin/candidat/Liste';
import DetailTypePaiement from '../components/admin/type paiement/detail';
import DetailTypeFormation from '../components/admin/type formation/detail';
import DetailTypePlateform from '../components/admin/type platefom/detail';
import DetailFormation from '../components/admin/formation/detail';
import DetailTypeVente from '../components/admin/type vente/detail';
import DetailVente from '../components/admin/vente/detail';
import DetailTypeService from '../components/admin/type service/detail';
import DetailPlateform from '../components/admin/plateform/detail';
import DetailService from '../components/admin/service/detail';
import DetailEmploie from '../components/admin/emploie/detail';
import EmploieAdminPage from '../views/admin/emploi/Liste';
import DetailPaiement from '../components/admin/paiement/detail';
import AdminListPage from '../views/admin/admin/Liste';
import DetailServicePage from '../views/clients/services/detail';
import ProfileClient from '../views/Auth/profile-client';
import ReservationFormationPage from '../views/clients/formations/reservation';


const AppRoutes = () => {
    const routes = useRoutes([
      {path:'/accueil' ,element:<Home />},
      {path:'/' ,element:<Accueil />},

       // ::::::::::::::::::::::::::::::::::::::::::::::::::::::
      // ::::::::::::::::::::ADMIN ROUTE:::::::::::::::::::::::
     // ::::::::::::::::::::::::::::::::::::::::::::::::::::::

     
      {path: "/admin/type-paiements", element:<TypePaiementPage />},
      {path: "/admin/type-paiement/:slug", element:<DetailTypePaiement />},
      {path:"/admin/type-plateformes", element:<TypePlateformPage />},
      {path:"/admin/type-plateforme/:slug", element:<DetailTypePlateform />},
      {path:"/admin/type-services", element:<TypeServicePage />},
      {path:"/admin/type-service/:slug", element:<DetailTypeService />},
      {path:"/admin/type-ventes", element:<TypeVentePage />},
      {path:"/admin/type-vente/:slug", element:<DetailTypeVente />},
      {path:"/admin/plateformes", element:<PlateformPage />},
      {path:"/admin/plateforme/:slug", element:<DetailPlateform />},
      {path:"/admin/services", element:<ServiceAdminPage />},
      {path:"/admin/services/:slug", element:<DetailService />},
      {path:"/admin/ventes", element:<VenteAdminPage />},
      {path:"/admin/ventes/:slug", element:<DetailVente />},
      {path:"/admin/paiements", element:<PaiementPage />},
      {path:"/admin/paiements/:slug", element:<DetailPaiement />},
      {path:"/admin/clients", element:<ClientPage />},
      {path:"/admin/admins", element:<AdminListPage />},
      {path:"/admin/formations", element:<FormationPage />},
      {path:"/admin/formations/:slug", element:<DetailFormation />},
      {path:"/admin/emploies", element:<EmploieAdminPage />},
      {path:"/admin/emploies/:slug", element:<DetailEmploie />},
      {path:"/admin/type-formations", element:<TypeFormationPage />},
      {path:"/admin/type-formations/:slug", element:<DetailTypeFormation />},
      {path:"/admin/reservation", element:<ReservationPage />},
      {path:"/profil-user", element:<ProfilePage />},
      {path:"/admin/dashboard", element:<DashboardPage />},
      {path:"/admin/candidats", element:<CandidatPage />},

       // ::::::::::::::::::::::::::::::::::::::::::::::::::::::
      // ::::::::::::::::::::CLIENTS ROUTE:::::::::::::::::::::::
     // ::::::::::::::::::::::::::::::::::::::::::::::::::::::

      {path:'/formations' ,element:<FormationsPage/>},
      {path:'/formations/:slug' ,element:<FormationByTypePage/>},
      {path:'/formations/:slug/inscription' ,element:<ReservationFormationPage/>},
      {path:'/formations/detail/:slug' ,element:<DetailForamtionPage/>},
      {path:'/emploies' ,element:<EmploiePage/>},
      {path:'/emploies/:slug' ,element:<DetailEmploiePage/>},
      {path:'/emploies/postuler/:slug' ,element:<PostulationPage/>},
      {path:'/service/:slug' ,element:<ServicePage />},
      {path:'/service/detail/:slug' ,element:< DetailServicePage/>},
      {path:'/ventes' ,element:<VentePage />},
      {path:'/ventes/:slug' ,element:<DetailVentePage/>},
      {path:'/ventes/reservation/:slug' ,element:<ReservationVentePage/>},
      {path:'/paiement' ,element:<PaimentPage/>},
      {path:'/paiement/:slug' ,element:<TypePaimentPage/>},
      {path:'/profile' ,element:<ProfileClient/>},


       // ::::::::::::::::::::::::::::::::::::::::::::::::::::::
      // ::::::::::::::::::::AUTH ROUTE:::::::::::::::::::::::
     // ::::::::::::::::::::::::::::::::::::::::::::::::::::::

     
      {path: "/login", element: <Login />},
      {path: "/register", element: <Register />},
      {path: "/forgot-password", element:<ForgetPassword />},
      {path: "/verification-password", element:<VerificationPassword />},
      {path: "/verification-password2", element:<VerificationPassword2 />},
      {path: "/reset-password", element:<ResetPassword />},
      {path: "/relogin", element:<Relogin />},
      {path: "/success-create-account", element:<SuccessCreateCompte />},
      {path: "/liste-formation", element:<FormationListPage />},
      
      {path: "*", element: <NoMatch /> },
    ])
    
    return routes;
}

export default AppRoutes;