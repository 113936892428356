import { crudFactory } from './crud-factory';
import { API_ENDPOINTS } from './api-endpoints';
import { QueryOptions, TypePaiement, TypePaiementInput } from '../types';
import { HttpClient } from './http-clients';
import { TypePaiementPaginator } from '../types/paginator';
import { TypePaiementQueryOptions } from '../types/queryOptions';


export const TypePaiementClient = {
  ...crudFactory<TypePaiement, QueryOptions, TypePaiementInput>(API_ENDPOINTS.TYPEPAIEMENTS),
  alls: ({ nom_type, ...params }: Partial<TypePaiementQueryOptions>) => {
    return HttpClient.get<TypePaiementPaginator>(API_ENDPOINTS.TYPEPAIEMENTS, {
      searchJoin: 'and',
      ...params,
      search: HttpClient.formatSearchParams({ nom_type }),
    });
  },
};
