import { useParams } from 'react-router-dom';
import { usePaiementQuery } from '../../../data/paiement';
import PageContainer from '../../layout/container';
import { SEO } from '../../Seo';
import { HeaderTitle } from '../../header/title';
import { Box, Chip, Container, Grid2 as Grid, Typography } from '@mui/material';

export default function DetailPaiement() {
    const { slug } = useParams();
    const { data } = usePaiementQuery({ slug });
    console.log(data)
    const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
    const getChipColor = (status: string) => {
        switch (status) {
            case 'pending':
                return 'warning';
            case 'seen':
                return 'info';
            case 'loading':
                return 'default';
            case 'accepted':
                return 'secondary';
            case 'refused':
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <PageContainer>
            <SEO title={data?.reference|| 'Détails'} />
            <HeaderTitle title='Détail du paiement' />
            <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent='center'>
                <Grid  size={{ lg:4 }}>
                <Box 
              component="img" 
              src={logo} 
              alt="Illustration" 
            />
                </Grid>
                <Grid size={{lg:8}}>
                    <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Reference</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.reference}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Montant</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >Ar {data?.montant}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Status</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                    <Chip 
                                    label={data?.status} 
                                    color={getChipColor(String(data?.status))} 
                                    variant="filled" 
                                />
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            </Container>
        </PageContainer>
    );
}