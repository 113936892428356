import { QueryOptions, TypePlateform, TypePlateformInput } from '../types';
import { TypePlateformPaginator } from '../types/paginator';
import { TypePlateformQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';


export const TypePlateformClient = {
    ...crudFactory<TypePlateform, QueryOptions, TypePlateformInput>(API_ENDPOINTS.TYPEPLATEFORMS),
    alls: ({ nom_type, ...params }: Partial<TypePlateformQueryOptions>) => {
      return HttpClient.get<TypePlateformPaginator>(API_ENDPOINTS.TYPEPLATEFORMS, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ nom_type }),
      });
    },
  };