import { ReactNode, useState,useEffect} from 'react'
import { Box, useTheme } from '@mui/material'
import { tokens } from '../../theme'

export default function PageContainer ({children,sx}:{children:ReactNode,sx?:any}) {
    const [screenHeight,setScreenHeight] = useState(0)
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
  
    
    useEffect(()=> {
        if(window){
            setScreenHeight(window.innerHeight)
        }},[])

    return (
       
        <Box sx={{px:{xs:1,md:4},py:4,mt:4,...sx}} bgcolor={colors.primary[600]} minHeight={screenHeight}>
            {children}
        </Box>
            

        
    )  
}