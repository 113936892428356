import { AddCircle, Delete, Download, Edit, PersonAdd, PersonRemove, CheckCircle, Unpublished, Visibility } from "@mui/icons-material";
import { Divider, IconButton, Tooltip, useTheme,Grid2 as Grid } from "@mui/material";
import { blue } from "@mui/material/colors";
import React from 'react';
import { MODAL_VIEWS, useModalAction } from '../modal/modal.context';
import { tokens } from '../../../theme';
import { useNavigate } from 'react-router-dom';

interface ActionProps{
    id:any,
    publishLink?:MODAL_VIEWS,
    deleteModalView?:MODAL_VIEWS,
    addLink?:any,
    blockLink?:MODAL_VIEWS,
    editLink?:any,
    editModalView?:MODAL_VIEWS,
    promLink?:MODAL_VIEWS,
    detailLink?:any,
    detailModal?:MODAL_VIEWS,
    addModalView?:MODAL_VIEWS
    refetch?:any
    downloadUrl?:string
}
export default function ActionsComponent ({id,addModalView,refetch,publishLink,deleteModalView,addLink,detailModal,blockLink,editLink,editModalView, promLink,detailLink}:ActionProps) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate()
    const { openModal } = useModalAction();

    const onAdd= ()=>{
      if(addModalView){
        const data= {
            action:'add',
            ...id
        }
        openModal(addModalView,data)
    } else{
        navigate(addLink)
    }
    }
    const onDelete= ()=>{
        const data= {
            action:'delete',
            ...id
        }
        openModal(deleteModalView,data)
    }

    const onEdit= ()=>{
        if(editModalView){
            const data= {
                action:'edit',
                ...id
            }
            openModal(editModalView,data)
        } else{
            navigate(editLink)
        }
    }
    const onPublish= ()=>{

        const actionMsg = id?.actionMessage[0] 
        const actionTitle = id?.actionTitle[0]
        const actionData = id?.actionData[0]
       
        delete id?.actionMessage
        delete id?.actionTitle
        delete id?.actionData
       
        const data= {
            actionMessage:actionMsg,
            actionTitle:actionTitle,
            icon:<CheckCircle color='secondary'/>,
            acceptBtnVariant:'contained',
            titleColor:colors.green[500],
            cancelBtnColor:'inherit',
            acceptBtnColor:'secondary',
            actionData:actionData,
            ...id
        }
        openModal(publishLink,data)
    }

    const unPublish= ()=>{

        const actionMsg = id?.actionMessage[1] 
        const actionTitle = id?.actionTitle[1]
        const actionData = id?.actionData[1]
 
        delete id?.actionData
        delete id?.actionMessage
        delete id?.actionTitle

        const data= {
            action:'unpublish',
            actionMessage:actionMsg,
            actionTitle:actionTitle,
            icon:<Unpublished color='error'/>,
            actionData:actionData,
            ...id
        }
        openModal(publishLink,data)
    }

    const onBlock= ()=>{
        const data= {
            action:'block',
            ...id
        }
        openModal(blockLink,data)
    }

    const onProm= ()=>{
        const data= {
            action:'promotion',
            ...id
        }
        openModal(promLink,data)
    }
    const onDownload = async () => {

      const { data } = await refetch();

      if (data) {
        const a = document.createElement('a');
        a.href = process.env.REACT_APP_PUBLIC_REST_API_URL+'/download/'+data;
        a.setAttribute('target', 'new_blank' );
        // console.log(downloadUrl)
        // a.setAttribute('download', String(downloadUrl) );
        a.click();
      }
    }

    const onDetail= ()=>{
        if(detailModal){
            const data= {
                action:'add',
                ...id
            }
            openModal(addModalView,data)
        }else{
         navigate(detailLink)
        }
    }

    return(
      <React.Fragment>
        <Grid direction="row" container sx={{display:'flex',justifyContent:'center',justifyItems:'center'}}>
            {publishLink&&(<Grid>
                <Tooltip title="Valider">
                <IconButton onClick={()=> onPublish()} sx={{":hover":{color: blue[400]}}}>
                    <CheckCircle />
                </IconButton>
             </Tooltip>
            </Grid>)}

            {publishLink&&(<Grid>
            <Tooltip title="Réfuser">
                <IconButton onClick={()=> unPublish()}  sx={{":hover":{color: colors.redAccent[400]}}}>
                    <Unpublished />
                </IconButton>
            </Tooltip>
            </Grid>)}
            {blockLink&&(<Grid>
            <Tooltip title="Bloquer">
                <IconButton onClick={()=> onBlock()}  sx={{":hover":{color: colors.redAccent[400]}}}>
                    <PersonRemove />
                </IconButton>
            </Tooltip>
            </Grid>)}

           {promLink&&( <Grid>
            <Tooltip title="Promouvoir">
                <IconButton onClick={()=> onProm()}  sx={{":hover":{color: colors.green[400]}}}>
                    <PersonAdd />
                </IconButton>
            </Tooltip>
            </Grid>)}
            <Divider orientation="vertical"/>
             {(addLink|| addModalView)&&(<Grid>
             <Tooltip title="Ajouter">
                <IconButton onClick={()=> onAdd()}  sx={{":hover":{color: colors.green[400]}}}>
                    <AddCircle />
                </IconButton>
            </Tooltip>
            </Grid>)}

            {detailLink||detailModal&&( <Grid>
            <Tooltip title="Detail">
                <IconButton onClick={()=> onDetail()}  sx={{":hover":{color: colors.green[400]}}}>
                    <Visibility />
                </IconButton>
            </Tooltip>
            </Grid>)}

            {(editLink||editModalView)&&(<Grid>
            <Tooltip title="Modifier">
                <IconButton onClick={()=> onEdit()} sx={{":hover":{color: blue[500]}}}>
                    <Edit />
                </IconButton>
            </Tooltip>
            </Grid>)}

            {(deleteModalView&&<Grid>
            <Tooltip title="Supprimer">
                <IconButton onClick={()=> onDelete()} sx={{":hover":{color: colors.redAccent[400]}}}>
                    <Delete />
                </IconButton>
            </Tooltip>
            </Grid>)}


          {(refetch  &&<Grid>
            <Tooltip title="Télécharger">
                <IconButton onClick={()=> onDownload()} sx={{":hover":{color: blue[400]}}}>
                    <Download />
                </IconButton>
            </Tooltip>
            </Grid>)}
        </Grid>
   </React.Fragment>)
}
