import React from 'react';
import { SEO } from '../../../components/Seo';
import { Box } from '@mui/material';
import { HeaderTitle } from '../../../components/header/title';
import TypePlateformList from '../../../components/admin/type platefom/liste';
import PageContainer from '../../../components/layout/container';

export default function TypePlateformPage (){
  return(
    <React.Fragment>
       <SEO title='Liste des types de plateforms'/>
        <PageContainer>
            <Box sx={{px:4,pt:4,borderRadius:2}}>
                <HeaderTitle title="Type de plateforms" addModalView="ADD_TYPE_PLATEFORM" />
            </Box>
            <TypePlateformList />
        </PageContainer>
    </React.Fragment>
  )
}