
import React from 'react'
import { Helmet } from 'react-helmet'

interface ISeo {
  title:string;
  description?:string;
  image?:string;
  url?:string;

}
export const SEO = ({title}:ISeo) =>{

  return(
  <React.Fragment>
    <Helmet>
        <title>{ title } | Gasy M'ketrika Online</title>
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <link rel="icon" type="image/png" href="logo 4x4.png" sizes="16x16" />
    </Helmet>
  </React.Fragment>
  )
}
