import React from 'react'
import { useDeleteEmploieMutation, useEmploiesQuery } from '../../../data/emploie'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { Box } from '@mui/material'
import { Emploie } from '../../../types'

export default function EmploieList(){

    const { emploies } = useEmploiesQuery({})
  
    const headers =[
        {name:"Image",value:'image',
            template: function Render(record: Emploie){
            
              return(
                <>
                  <Box component="img"
                       src={JSON.parse(record?.image)?.thumbnail}
                       width={35} height={35} />
                </>
              )
            }
          },
      {name:"Titre",value:"titre_emploie"},
      {name:"Reference",value:"reference"},
      { name: "Disponible", value: "is_disponible",
        template: function Render(record: any){
            
          return record.is_disponible == 1 ? 'Oui' : 'Non';
        }

      },
      {name:"Description",value:"description"},
      {name:"Action",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_emploie,
                  deleteMutation:useDeleteEmploieMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.titre_emploie}</b> de la liste des emploies ?`,
                  ...record
                }}
                deleteModalView='DELETE'
                editModalView="ADD_EMPLOIE"
                detailLink={`/admin/emploies/${record?.slug}`} 
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={emploies} headers={headers}/>
      </React.Fragment>
    )
  }
  