import React from 'react';
import { TextField } from '@mui/material';

interface CustomTextFieldProps {
    placeholder: string;
    type?: string;
    required?: boolean;
    value : string;
    name?: string;
    disabled?:boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<CustomTextFieldProps> = ({disabled,placeholder, type = 'text', required = false,value,name,onChange }) => {
    return (
        <TextField 
          name={name} 
          label={placeholder}
          margin="normal" 
          required={required} 
          fullWidth 
          placeholder={placeholder} 
          type={type} 
          value={value} 
          onChange={onChange} 
          variant="outlined"
          color="secondary"
          disabled={disabled}
        />
    );
};

export default Input;