import React, { useEffect } from 'react';
import { CircularProgress, Typography,Box } from '@mui/material';
import { useModalAction } from '../ui/modal/modal.context';
import { useLogoutMutation } from '../../data/user';

function SignOut() {
  const { mutate: logout } = useLogoutMutation();
  const { closeModal } = useModalAction();

  useEffect(() => {
    logout();
    setTimeout(()=>{
      closeModal()
    },3000)
  }, []);

  return (
    <React.Fragment>
        <Box textAlign="center" sx={{cursor:"pointer"}} >
          <CircularProgress color="secondary" />
          <Typography variant="h6" color="secondary" fontWeight={500}>Déconnexion en cours...</Typography>
        </Box>
     </React.Fragment>
      );
}

export default SignOut;

