import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { ServiceClient } from '../api/service';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { useModalAction } from '../components/ui/modal/modal.context';
import { GetParams, Service } from '../types';
import { ServiceQueryOptions } from '../types/queryOptions';
import { ServicePaginator } from '../types/paginator';

export const useCreateServiceMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(ServiceClient.create, {
      onSuccess: (data: any) => {
        
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.SERVICES]);
      },
    });
  };
  
  export const useDeleteServiceMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(ServiceClient.delete, {
      onSuccess: (data:any) => {
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.SERVICES]);
      },
    });
  };
  
  export const useUpdateServiceMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(ServiceClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.SERVICES]);
      },
    });
  };
  
  export const useServiceQuery = ({ slug, language }: GetParams) => {
    return useQuery<Service, Error>({
     queryKey: [API_ENDPOINTS.SERVICES, { slug, language }],
     queryFn: () => ServiceClient.get({ id:slug, language })
    });
  };
  
  export const useServicesQuery = (options?: Partial<ServiceQueryOptions>) => {
    const { data, isLoading, error } = useQuery<ServicePaginator, Error>({
     queryKey:[API_ENDPOINTS.SERVICES, options],
     queryFn: ({ queryKey, pageParam }) =>
        ServiceClient.alls(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      services: data ?? [],
      loading: isLoading,
      error,
    };
  };