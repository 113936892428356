import React from 'react';
import { useDeleteTypePaiementMutation, useTypePaiementsQuery } from '../../../data/type paiement';
import ActionsComponent from '../../ui/table/action';
import CollapsibleTable from '../../ui/table/collapsible';
import Logo from '../../ui/image/logo';
import { TypePaiement } from '../../../types';


export default function TypePaiementList(){

  const { typePaiements } = useTypePaiementsQuery({})
  const headers =[
    {name:"Logo",value:'logo',
      template: function Render(record: TypePaiement){
        
        return(
          <>
       {    record?.logo &&( <Logo logo={record?.logo} />) }
          </>
        )
      }
    },
    {name:"Type de paiement",value:"nom_type"},
    {name:"Numero à créditer",value:"numero"},
    {name:"Code de transfert",value:"code_transfert"},
    {name:"Action",value:"action",
      template:function Render(record:any){
      return(
          <ActionsComponent
              id={{
                id:record?.id_type,
                deleteMutation:useDeleteTypePaiementMutation,
                deleteMessage: `Voulez-vous supprimer <b>${record?.nom_type}</b> de la liste des types de paiement ?`,
                ...record
              }}
              deleteModalView='DELETE'
              editModalView="ADD_TYPES"
              detailLink={`/admin/type-paiement/${record?.id_type}`} 
             />
      )
   }
  }
  ]


  return(
    <React.Fragment>
          <CollapsibleTable data={typePaiements} headers={headers}/>
    </React.Fragment>
  )
}
