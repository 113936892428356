import { useTypeServicesQuery } from '../../../data/type service';
import { ListeProps } from '../../../types/mui';
import AutoComplete from '../../ui/input/autocomplete';

interface TypeServiceInputProps extends ListeProps {
    initialValue?: { id_type: number; nom_type: string } | null;
  }

export function TypeServiceInput({setSelected,initialValue}:TypeServiceInputProps){

    const { typeServices } = useTypeServicesQuery({})

    return(
        <>
        <AutoComplete
              text="nom_type"
              items={typeServices}
              value={initialValue}
              label="Type de service"
              name="typeService"
              sx={{mt:2}}
              setSelected={setSelected}
              />
        </>
    )
}