import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Card, styled } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
    textAlign: 'start',
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    margin:5
}));

export default function ChartLine() {
  return (
    <StyledCard>
        <LineChart
      xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
      series={[
        {
          data: [2, 3, 5.5, 8.5, 1.5, 5, 1, 4, 3, 8],
          showMark: ({ index }) => index % 2 === 0,
        },
      ]}
      width={340}
      height={300}
    />
    </StyledCard>
  );
}
