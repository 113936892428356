import { Box, Button, Container, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { SEO } from '../../components/Seo';
import Grid from '@mui/material/Grid2';
import { tokens } from '../../theme';

const SuccessCreateCompte = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const image = `${process.env.PUBLIC_URL}/illustration/yellow/Enthusiastic-rafiki.png`;
    const image1 = `${process.env.PUBLIC_URL}/illustration/yellow/Enthusiastic-bro.png`;
    const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
  
    return (
        <Box component='div' sx={{ backgroundColor: colors.primary[600], minHeight: '100vh' }}>
            <SEO title='Success Create account' />
            <Container component="main" maxWidth="lg">
                <Grid container spacing={2} paddingTop={{xl : 5 }} justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <Grid size={{xs:12, md:7 }} display="flex" justifyContent="center" flexDirection='column'>
                        <Typography component='h2' textAlign={{md:'center',xl:'left',xs:'center'}} color='#F2C700' fontFamily='Inter' sx={{
                                width: '100%',
                                opacity: 1, 
                                fontSize: { xs: '40px', sm: '72px',xl:'10vh' },
                                fontWeight: 700,
                            }}
                        >
                            Congrulation !
                        </Typography>
                        <Grid display='flex' flexDirection='row'>
            <Box
              component="img" 
              src={image} 
              alt="Illustration"
              sx={{
                width: { sm: '50%', md: '60%',xl:'100%' }, 
                height: 'auto',
                maxHeight: { sm: '150px', md: '400px',xl:'600px' }, 
                display: { xs: 'none', sm: 'block' },
                margin: '0 auto',
                right:{xl:'30%'},
                position:'relative'
              }}
            />
            <Box 
              component="img" 
              src={image1} 
              alt="Illustration"
              sx={{
                position: 'relative',
                width: { sm: '50%', md: '50%',xl:'70%' }, 
                height: 'auto',
                right:{md:'10%', xl:'55%'},
                maxHeight: { sm: '150px', md: '350px',xl:'500px' }, 
                display: { xs: 'none', sm: 'block' },
                margin: '0 auto',
              }}
            />
            </Grid>
                        <Typography fontFamily='Inter' width='100%' fontSize={{ xs: '16px', sm: '25px', xl:'40px' }} textAlign={{md:'center',xl:'left', xs:'center'}}>
                            Votre compte a été créé avec succès !
                        </Typography>
                    </Grid>
                    <Grid size={{xs:12, md:5 }}>
                        <Paper elevation={3} sx={{boxShadow:'2px 2px 5px 2px black', padding: { xs: 3, sm: 4 }, backgroundColor:  colors.grey[800], borderRadius: 5, marginTop: 5, marginBottom: 5, marginLeft:{xl:5},width: { xl: '70vh' } }}>
                            <Box display="flex" alignItems="center" justifyContent='center' flexDirection='column' sx={{ marginBottom: 2 }}>
                                <Box component="img" src={logo} alt="Logo" sx={{ width: 'auto', height: { xs: '20vh', sm: '25vh' }, marginBottom: 2 }} />
                                <Typography fontFamily='Inter' fontSize={{ xs: '20px', sm: '25px',xl:'45px' }} textAlign="center">
                                    Bienvenue sur Gasy M’ketrika
                                </Typography>
                                <Typography fontFamily='Inter' fontSize={{ xs: '20px', sm: '25px',xl:'45px' }} textAlign="center">
                                    Online <span style={{ color: '#F2C700' }}>Freelance</span> !
                                </Typography>
                                <Typography fontFamily='Inter' fontSize={{ xs: '16px', sm: '18px', xl:'30px' }} marginTop={2} textAlign="center">
                                    Cliquez sur le bouton continuer pour  pour accéder
                                </Typography>
                                <Typography fontFamily='Inter' fontSize={{ xs: '16px', sm: '18px',xl:'30px' }} marginBottom={4} textAlign="center">
                                     à votre page d'accueil client !
                                </Typography>
                            </Box>
                            <Box component='div' display='flex' justifyContent='end'>
                                <Button href='/accueil' variant="contained" color='secondary' sx={{ borderRadius: '5px',marginTop:5,fontSize:{xl:20} }}
                                >
                                    Continuer
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default SuccessCreateCompte;