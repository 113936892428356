// import { Header } from '../contentHome/Header';
import { Box, Button, colors, Typography } from '@mui/material';
import { typographyFont } from '../../../../theme';

import { Link } from 'react-router-dom';
import { Input } from '../input/input';
// import { Header } from '../contentHome/Header';
 export default function DetailCreePortfeuillClient () {
    // nom, prenom ,mail, adress , telphone , type de compte creer
    return(
        <>
               {/* <Header description=" Découvrez notre plateforme complète qui vous accompagne dans chaque étapevotre parcours freelance : création de comptes, configuration de portefeuilles "
             button=""/> */ }
     
        <Box position='absolute' left='50%' top='10%'  sx={{width:'100%',transform:'translateX(-50%)'}}>
            <Typography variant='h1' padding={2} sx={{textAlign:'center',color:colors.grey[50],...typographyFont.h2,fontWeight:900}}>CREER VOTRE PORTFEUILLE</Typography>
            <Box sx={{ background:'#F5F5F517',border:'solid 1px green ',borderRadius:'0.7rem',margin:{xs:0,md:'0rem 15rem'}}}>
               <Input />

               <Box textAlign='center' sx={{padding:{xs:1,md:'1rem 10rem',xl:'1.5rem 15rem'}}} >
                <Button sx={{width:'100%',padding:'1rem'}}  variant='contained' color='secondary'>Creer mon compte</Button>
               </Box>

               <Typography padding={2} fontSize={{xl:15}} variant="body2" align="center" sx={{ marginTop: 2, color: 'white' }}>
                Vous n’avez pas de compte ? 
                <Link to="/register" style={{ textDecoration: 'none',color:'#227f50' }}>
                  <span> Créer un compte</span>
                </Link>
              </Typography>
            </Box>
           
        </Box>
        </>
    )
}
