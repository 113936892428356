import { Box, Button, Container, IconButton, ListItemText, MenuItem, Stack, Toolbar, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { ColorModeContext, tokens } from '../../theme';
import MenuPopup from '../ui/menu';
import { useNavigate } from 'react-router-dom';
import MoreIcon from '@mui/icons-material/MoreVert';
import { getPermission, isAuthenticated ,getAuthCredentials} from '../../utils/auth-utils';
import { DarkMode, History, LightMode, Logout, Person, Settings } from '@mui/icons-material';
import { useModalAction } from '../ui/modal/modal.context';
import { useTypeServicesQuery } from '../../data/type service';


const Navbar = () => {

    const { typeServices } = useTypeServicesQuery({})

  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate()

  const service =  Array.isArray(typeServices) && typeServices.map((item:any)=>{

    return {
        text :item?.nom_type,
        to:'/services/'+item?.slug,
      }
  })

  const pages = [
    {text:'Accueil',to:"/accueil"},
    {text:'Formations',to:"/formations"},
    {text:'Services',items:service},
    {text:'Ventes',to:"/ventes"},
    {text:'Emploies',to:"/emploies"},];
  const { openModal } = useModalAction()
  const {token,permissions } = getAuthCredentials()
  const isConnected = isAuthenticated({token,permissions })

  const rightNav = [
                    // {text: "Message",icon:msg,component:(<MessageComponent/>)},
                    // {text: "Notification",icon:notif,component:(<NotifComponent/>)},
                    {text: "Account",icon:<Person />,
                                    items:[{text :'Mon compte',to:'/profil-user',icon:<Person />},
                                          {text:'Paramètres',to:'/setting',icon:<Settings/>},
                                          {text:'Historiques',to:'/historiques',icon:<History/>}]}]

  const { isAdmin } = getPermission()
  

  return (
    <React.Fragment>
       <Container maxWidth="xl">
           <Toolbar disableGutters >
              <Stack direction="row" width="100%"  alignItems="center" justifyContent="space-between">
                    <Typography  variant="h4" noWrap component="a" href={isAdmin?"/admin/dashboard" :"/accueil"} sx={{ mr: {xl:2,sm:2},
                        fontFamily: 'monospace' , fontWeight: 800, color: colors.green[500] ,
                        textDecoration: 'none',marginRight:15 , }}>
                        Gasy M<span style={{color:colors.yellow[500] }}>'</span>ketrika Online
                    </Typography>

                   {!isAdmin&&( <Box sx={{ flexGrow: 1, display: { xs: 'none' , sm: 'flex' } }}>
                        {pages.map((page) =>  page.items ?
                            ( <div key={page.text}>
                               
                                <MenuPopup text={page.text} items={page.items}/>
                             </div>
                           ) 
                           :
                        (<Button key={page.text} onClick={()=>{navigate(String(page?.to))}} sx={{ my: 2, color: colors.grey[100] , display: 'block' }}>
                                {page.text} 
                        </Button>
                        )
                        )}
                    </Box>)}

                   {!isAdmin && ( <Box justifySelf="end" justifyContent="end" justifyItems="end" sx={{display: { xs: 'flex' , md: 'none' } }}>
                       <MenuPopup icon={<MoreIcon/>} items={pages}/>
                    </Box>)}
                    <Box display={isConnected? "flex" : "none"}  borderRadius="3px">
                                {rightNav.map( (item: any,i:number) => item.component ?
                                    (<MenuPopup key={i} icon={item.icon} >
                                            <>{item.component}</>
                                    </MenuPopup>
                                    ) :
                                    ( <MenuPopup key={i} icon={item.icon} items={item.items}>
                                        <Box onClick={colorMode.toggleColorMode} key="darkMode">
                                            {theme.palette.mode === 'dark' ?
                                                (<MenuItem >
                                                    <IconButton sx={{ml:-2}}>
                                                        <LightMode />
                                                    </IconButton>
                                                    <ListItemText>Mode éclairé</ListItemText>
                                                </MenuItem>
                                                ) : 
                                                (<MenuItem>
                                                    <IconButton sx={{ml:-2}}>
                                                        <DarkMode />
                                                    </IconButton>
                                                    <ListItemText>Mode sombre</ListItemText>
                                                </MenuItem>
                                                ) 
                                            } 
                                        </Box>
                                        <Box>
                                        <MenuItem onClick={()=> openModal('LOGOUT',{})} key="dcnx">
                                                <IconButton sx={{ml:-2}}>
                                                    <Logout />
                                                </IconButton>
                                                <ListItemText>Déconnexion</ListItemText>
                                            </MenuItem>
                                        </Box>
                                    </MenuPopup>
                            ))}
                    </Box>
                    <Button sx={{display:!isConnected? "block" : "none"}}  color="secondary" variant="contained" onClick={()=> navigate('/login')}>Se connecter</Button>
                         
              </Stack>
            
           </Toolbar>
       </Container>
    </React.Fragment>
  )
}

export default Navbar
