import PaimentComponent from '../../../components/clients/paiement/paiement';
import { SEO } from '../../../components/Seo';

export default function PaimentPage() {
    return(
        <>
        <SEO  title='Paiement'/>
          
          <PaimentComponent/>
        </>
    )
}