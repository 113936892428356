
import { Box, Grid2,Typography } from '@mui/material';
import { typographyFont } from '../../../theme';
import CardSlider from '../../ui/slide/slider';


function WalletCard (wallet:any){

  return(
    <>
      <Grid2  size={12}  key={wallet.id} sx={{height:200,paddingInline:4, cursor:'pointer',background:'#4CAF50', textAlign:'center',borderRadius:'1rem', color:"white"}}>
            <h1 style={{padding:'1rem'}}>{wallet.titre}</h1>
              <Typography component="p" >
              {wallet.desc}
            </Typography>
        </Grid2>
    </>
  )
}
export default function PortefeuilleElectroniques() {
    const ewallet = `${process.env.PUBLIC_URL}/illustration/black/E-Wallet-pana.png`;

                
    const wallets = [
      { id: 1, titre: 'AirTM', desc:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint, eum!' },
      { id: 2, titre: 'Binance', desc:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint, eum!' },
      { id: 3, titre: 'PayPal', desc:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint, eum!' },
     
  ];

    return(
      <>
        <Box width='100%' sx={{padding:2}} >
          <Grid2 bgcolor="#bdbdbd" justifyContent='center' padding={3}  sx={{textAlign:'center',borderRadius:5}}  container >
          <Grid2 container justifyContent='space-around' alignItems='center'spacing={{xs:2,md:5}} >
            <Grid2 size={{md:4,xs:12}}>
              <Box data-aos="fade-up-left" component='img' width={{xs:'100%',md:'100%'}}  src={ewallet}/>
            </Grid2>
            <Grid2 textAlign={{xs:'center',md:'start'}} sx={{color:'black'}} padding={1} size={{xs:12,md:6}}>

              <Typography data-aos="fade-up-right" variant='h1'sx={{...typographyFont.h1,fontWeight:800}}>
                   Votre argent ,<br/>a portée de clics.
              </Typography>

              <Typography data-aos="fade-up" component="p" style={{fontSize:'1rem',marginBlock:4}}>
                Nous somme à vos côté pour  vous accompagner à créer votre porte feuille éléctronique afin de 
                gérez votre argent en toute liberté, où que vous soyez.
              </Typography>
            </Grid2>
          </Grid2>

            <Grid2 data-aos="fade-right" container justifyContent='space-around' color="white" spacing={2} direction="row">

                <CardSlider  items={wallets} template={WalletCard} >
                    <Grid2 size={12} sx={{ color:"white",background:'#4CAF50',cursor:'pointer', textAlign:'center',borderRadius:'1rem'}}>
                        <h1 style={{padding:'3.5rem'}}>Voir plus</h1>
                    </Grid2>
                </CardSlider>
              </Grid2>
          
          
          </Grid2>
        </Box>
      </>
    )
}