
import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Stack,
} from '@mui/material';
import Input from '../../Input';
import { TypePaiementInput } from '../../admin/type paiement/select';
import Logo from '../../ui/image/logo';
import ButtonLoading from '../../ui/button/button-loading';
import { TypePaiement } from '../../../types';


interface ReservationProps{
  handleReserver:any,
  typePaiement:TypePaiement|null,
  setTypePaiement:React.Dispatch<React.SetStateAction<TypePaiement|null>>,
  form:any,
  setForm:React.Dispatch<React.SetStateAction<any>>,
  loading:boolean,
}


const ReservationForm: React.FC<ReservationProps> = ({handleReserver,loading,
                                                        typePaiement,setTypePaiement,
                                                        form,setForm
                                                        }) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
      console.log(form)
    };
  return (
    <Paper elevation={3} sx={{ padding: { xs: 3, sm: 4 },marginTop:{xs : 2,sm:5}, backgroundColor: '#F5F5F517', borderRadius: '15px',width: { xl: '70vh' },}}>
          <Box display="flex" alignItems="center" justifyContent='center' sx={{ marginBottom: 2 }}>
            <Box component="img" src="/logo 4X4.png" alt="Logo" sx={{ width: 'auto', height: {xs:50, md:50, xl:80}, marginRight: 2, marginBottom: 2 }} />
            <Typography variant="h3"  align="left"  >
              Réservation
            </Typography>
          </Box>
        <Box component='form' onSubmit={handleReserver} >
          <Input name='nom'
                  placeholder='Votre nom et prénom'
                  type='text' required 
                  value={form.nom}
                  onChange={handleChange}
            />
            
          <Input name='email'
                  placeholder='Votre email'
                  type='text' required 
                  value={form.email}
                  onChange={handleChange}
            />
          
              <Input name='numero'
                  placeholder='Votre numéro'
                  type='text' required 
                  value={form.numero}
                  onChange={handleChange}
            />
          <Input name='adresse'
                  placeholder='Votre adresse'
                  type='text' required 
                  value={form.adresse}
                  onChange={handleChange}
            />
              
          
          <Stack direction={{xs:"column",sm:"row"}} padding={1}>
              <Box width={typePaiement&&typePaiement.nom_type!=="Paypal"?"50%":"100%"} >
                  <TypePaiementInput setSelected={setTypePaiement} />
              </Box>
              {typePaiement && typePaiement.nom_type!=="Paypal"&&(<Box width="50%" sx={{padding:2}}>
                
                <Logo logo={String(typePaiement?.logo)}/>
                <Typography sx={{fontWeight:600}}>
                  { typePaiement?.code_transfert }
                </Typography>

              </Box>)}
          </Stack>
          
            <Input name='reference'
                  placeholder='Réference du transfert'
                  type='text' required 
                  value={form.reference}
                  onChange={handleChange}
            />
            <ButtonLoading type="submit" loading={loading} variant='contained' color='secondary' label="Reserver" />

        </Box>
     </Paper>
  );
};

export default ReservationForm;