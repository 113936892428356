import { Box, Button, Grid2, Typography, useTheme } from '@mui/material';
import { tokens, typographyFont } from '../../../theme';


export default function Subscribe() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return(
        <Box width='100%' paddingY={{xs:1,md:6}} paddingX={2}>
        <Grid2 container  direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 1, md: 2 }} sx={{background:'#bdbdbd',borderRadius:'1rem',marginBottom:3}}>
            <Grid2 data-aos="fade-up" padding={6} fontSize={15} size={{xs:12,sm:6,md:5}}>
               <Typography color='primary' sx={{...typographyFont.h3,fontWeight:800}} width={{xs:"100%",md:"80%"}}>Passer à l'action, transformez vos rêves en realité</Typography>
               <Typography color='primary' component="p" style={{fontSize:'1.2rem'}}>Nos expert va vous prendre en main pour réaliser vos rêves. </Typography>
            </Grid2>
            <Grid2  justifyContent='center' 
                    size={{xs:12,sm:6,md:7}}
                    sx={{display:'flex',alignItems:'center',padding:'2rem'}} >
              <input  type='text' placeholder='Entrer votre numero téléphone' style={{outlineColor :colors.green[500],borderRadius:'.3rem',border:'none',background:'',color:'black',width:'60%',padding:'1rem',fontSize:'1.1rem'}}/> 
             <Button variant='contained'sx={{color:'beige',background:'#1F6F42',borderTopLeftRadius:'none',padding:'1rem'}}>S'inscrire</Button>
            </Grid2>
        </Grid2>
        </Box>
    )
}