
import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {  useFormationQuery } from '../../../data/formation';
import Logo from '../../ui/image/logo';
import { toMoney } from '../../../utils/transform';
import { SEO } from '../../Seo';
import { NotFound } from '../../not-found/not-found';
import { TypePaiement } from '../../../types';
import { useCreatePaiementMutation } from '../../../data/paiement';
import { useCreateReservationMutation } from '../../../data/reservation';
import { useNavigate } from 'react-router-dom';
import ReservationForm from '../reservation/formulaire';

 interface IProps {
  slug:string
}

const ReservationFormation: React.FC<IProps> = ({slug}) => {

const navigate= useNavigate()
const { data,isLoading } = useFormationQuery({slug:slug})
const { mutate: createPaiement ,isLoading:loading} = useCreatePaiementMutation()
const { mutate: createReservation } = useCreateReservationMutation()

const [form, setForm] = useState({
  nom: '',
  email: '',
  reference:""
});
const [typePaiement,setTypePaiement] = useState<TypePaiement | null>(null)

function handleReserver(event:React.FormEvent<HTMLFormElement>){
  event.preventDefault(); 
  createPaiement({
    reference: String(form.reference),
    montant: Number(data?.prix),
    type_paiement: String(typePaiement?.id_type),
    status: ''
  },{
    onSuccess: (paiement:any)=>{
      createReservation({
        formation_id:String(data?.id_formation),
        paiement_id:paiement?.data.id_paiement
  },{
    onSuccess: ()=>{
      
          navigate('/formations')
         }
  })
  }
    })
    }

 if(!data && !isLoading){
  return (
      <>
        <SEO title='404: Contenue introuvable' />
        <NotFound text="Contenu introuvable" />
    </> 
    )
 }

  return (
   data && (<Box component='div' >
      <Container component="main" maxWidth="lg" >
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%',padding:{xl:6},}}>
          <Grid size={{xs:12, md:6}} display="flex" justifyContent="center">
            <Stack direction="column">
                <Logo logo={String(data?.image)} width="100%" height={300} />
                <Box sx={{padding:3}} component="div">
                    <Typography variant="h3" sx={{fontWeight:600}}>
                    {data?.nom_formation}
                    </Typography>
                    <Typography component="p" dangerouslySetInnerHTML={{ __html: data ? data?.description : "" }}  />
                    <Typography component="p" sx={{paddingTop:3}}>
                        Prix :
                        <Typography component="span" sx={{fontWeight:600,paddingX:0.5}}>
                        {toMoney(data?.prix)}
                        </Typography>
                    </Typography>
                    <Typography component="p" sx={{paddingY:1}}>
                        Delais de formation :
                        <Typography component="span" sx={{fontWeight:600,paddingX:0.5}}>
                        {data?.delais} jour(s)
                        </Typography>
                    </Typography>
                    <Typography component="p" sx={{paddingY:0.5}}>
                        Plateforme:  
                        <Typography component="span"  color='inherit' sx={{fontWeight:600,paddingX:1}}>
                        {data?.plateforme.nom_plateforme}
                        </Typography>
                    </Typography>
                </Box>
            </Stack>
          </Grid>
          <Grid size={{xs:12, md:6 }} paddingY={3} >
            <ReservationForm form={form} setForm={setForm}
                            typePaiement={typePaiement}
                            setTypePaiement={setTypePaiement}
                            handleReserver={handleReserver} 
                            loading={loading}/>
          </Grid>
        </Grid>
      </Container>
    </Box>)
  );
};

export default ReservationFormation;