import { crudFactory } from './crud-factory';
import { Formation, FormationInput, QueryOptions } from '../types';
import { HttpClient } from './http-clients';
import { API_ENDPOINTS } from './api-endpoints';
import { FormationQueryOptions } from '../types/queryOptions';
import { FormationPaginator } from '../types/paginator';

export const FormationClient = {
    ...crudFactory<Formation, QueryOptions, FormationInput>(API_ENDPOINTS.FORMATIONS),
    alls: ({ nom_formation, ...params }: Partial<FormationQueryOptions>) => {
      return HttpClient.get<FormationPaginator>(API_ENDPOINTS.FORMATIONS, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ nom_formation }),
      });
    },
  };
  
