import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { PlateformClient } from '../api/plateform';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { useModalAction } from '../components/ui/modal/modal.context';
import { GetParams, Plateforme } from '../types';
import { PlateformQueryOptions } from '../types/queryOptions';
import { PlateformPaginator } from '../types/paginator';
import { getFormErrors } from '../api/http-clients';

export const useCreatePlateformMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(PlateformClient.create, {
      onSuccess: (data: any) => {
        // Router.push(Routes.typeagence.list, undefined, {
        //   locale: Config.defaultLanguage,
        // });
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.PLATEFORMS]);
      },
    });
  };
  
  export const useDeletePlateformMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(PlateformClient.delete, {
      onSuccess: (data:any) => {
        openSnackbar( data?.message);
      },
      onError:(error:any)=>{
        openSnackbar(getFormErrors(error),'error');
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.PLATEFORMS]);
      },
    });
  };
  
  export const useUpdatePlateformMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(PlateformClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.PLATEFORMS]);
      },
    });
  };
  
  export const usePlateformQuery = ({ slug, language }: GetParams) => {
    return useQuery<Plateforme, Error>({
     queryKey: [API_ENDPOINTS.PLATEFORMS, { slug, language }],
     queryFn: () => PlateformClient.get({ id:slug, language })
    });
  };
  
  export const usePlateformsQuery = (options?: Partial<PlateformQueryOptions>) => {
    const { data, isLoading, error } = useQuery<PlateformPaginator, Error>({
     queryKey:[API_ENDPOINTS.PLATEFORMS, options],
     queryFn: ({ queryKey, pageParam }) =>
        PlateformClient.alls(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      plateforms: data ?? [],
      loading: isLoading,
      error,
    };
  };