import React, { ReactNode, useState } from 'react';
import { Box, CardContent, IconButton, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { tokens } from '../../../theme';


interface IProps{
    items: any;
    template: any,
    children?:ReactNode
}

const CardSlider: React.FC<IProps> = ({items,template,children}) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  // Function to handle the scroll behavior
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isSm = useMediaQuery(theme.breakpoints.down('md'))

  const [rightIcon,setRightIcon] = useState<boolean>(true)
  const [leftIcon,setleftIcon] = useState<boolean>(false)

  const scrollWidth = isXs  ? 95 : (isSm ? 45 : 33)
  const  [index,setIndex] =  useState(1);

  const scroll = (scrollOffset: number) => {
    
    setIndex(scrollOffset < 0 ? index-1 : index+1)

    if (scrollRef.current) {
      
     const offset = scrollOffset *  scrollRef.current.clientWidth/100

      scrollRef.current.scrollLeft += offset ;
     
    //  alert(index +" "+(items.length-1))

      if(index === 1){
        setRightIcon(true)
        setleftIcon(false)
      }else if( index >items.length-1 ){
        setRightIcon(false)
        setleftIcon(true)
      }else{
        setRightIcon(true)
        setleftIcon(true)
      }
    }
  };

 
 

  return (
    <Box  data-aos-duration="2000" sx={{ position: 'relative', margin: '0 auto',width:"100%" , maxWidth: {xs:'80vw',sm:"100vw"},}}>
      {/* Left Scroll Button */}
      <IconButton
        onClick={() => scroll(-scrollWidth)} // Scroll left by 300px
        sx={{
          display:leftIcon?'block':'none',
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
          color:colors.green[500],
          ":hover":{color:"white",bgcolor:colors.green[500]}
        }}
      >
        <ArrowBackIosIcon  fontSize="large" />
      </IconButton>

      {/* Scrollable Container */}
      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          bgcolor:'transparent',
          elevation:0,
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar
        }}
      >
        {items.map((item:any, index:number) => (
          <CardContent
            key={index}
            sx={{
              flex: '0 0 auto',
              width: {xs:"95%",sm:"45%",md:"33%",xl:"20%"},
              margin: '0 4px',
            }}
          >
           { 
             template(item) 
             
          }
          </CardContent>
        ))}
        <CardContent
            sx={{
              flex: '0 0 auto',
              width: {xs:"95%",sm:"45%",md:"33%"},
              margin: '0 4px',
            }}
          >
          {
            children
          }
          </CardContent>
      </Box>

      {/* Right Scroll Button */}
      <IconButton
        onClick={() => scroll(scrollWidth)} // Scroll right by 300px
        sx={{
          display:rightIcon?'block':'none',
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
          color:colors.green[500],
          ":hover":{color:"white",bgcolor:colors.green[500]}
        }}
      >
        <ArrowForwardIosIcon fontSize="large" />
      </IconButton>
    </Box>
  );
};

export default CardSlider;
