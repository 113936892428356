import {  Button, Grid2, Typography, useTheme } from '@mui/material';
import { tokens, typographyFont } from '../../../../theme';
import Logo from '../../../ui/image/logo';
import { Formation } from '../../../../types';
import TruncatedTextCard from '../../../ui/Typography/description';
import { toMoney } from '../../../../utils/transform';

export default function FormationCard({item}:{item:Formation}){

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return(
        <>
            <Grid2 alignItems='center' sx={{display:'flex',justifyContent:'space-around'}} padding={2} container spacing={3}>
                <Grid2 paddingTop={{xs:0,md:4}} size={{xs: 12,md:4}}>
                    <Logo height={310} width="100%" logo={String(item.image)} />
                </Grid2>
                <Grid2 padding={{xs:0,md:1}} size={{xs:12,md:8}}>
                    <h1>{item.nom_formation}</h1>
                    <Typography component="span" marginBottom={1}>
                        <Typography component="a" href={`/plateformes/${item.plateforme.slug}`} sx={{...typographyFont.h6,fontWeight:600,color:colors.grey[400]}}>
                            {item.plateforme.nom_plateforme}
                        </Typography> 
                        <Typography component="a" href={`/type-plateformes/${item.plateforme.type_plateforme.slug}`}  sx={{...typographyFont.h6,fontWeight:600,color:colors.grey[400]}}>
                         - {item.plateforme.type_plateforme.nom_type}
                        </Typography>
                    </Typography>

                    <TruncatedTextCard height={300} text={item.description} link={item.slug}/>

                    <Typography component="p" style={{paddingBlock:10,gap:5}}>
                        <Typography sx={{pb:1}}>Durée: {item.delais} jours</Typography>
                        <Typography sx={{mb:1}}>Prix:{ toMoney(item.prix)} Ar</Typography>
                    </Typography>
                    <Button variant='contained'
                             color="inherit" 
                             href={`${item.slug}/inscription`}
                             sx={{padding:'0.5rem 1rem'}}
                    >S'inscrire</Button>
                
                </Grid2>
            </Grid2>
        </>
    )

}