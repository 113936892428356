import { useState } from 'react'
import { useCreateTypeVenteMutation, useUpdateTypeVenteMutation } from '../../../data/type vente'
import { TypeVente } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import { Box, Button, Typography, Grid2 as Grid } from '@mui/material'
import { typographyFont } from '../../../theme'
import Input from '../../Input'
import ButtonLoading from '../../ui/button/button-loading'


export const TypeVenteForm = ({defaultValues}:{defaultValues:TypeVente}) => {

    const { mutate: createType, isLoading: loading } = useCreateTypeVenteMutation()
    const { mutate: updateType, isLoading } = useUpdateTypeVenteMutation()
    const update = Boolean(defaultValues?.nom_type)
    
    const { closeModal } = useModalAction()
  
    const [typeVente, setTypeVente] = useState(defaultValues.nom_type);
      const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
        console.log('Type Vente:', typeVente);
        if(update){
          updateType({
            id: String(defaultValues?.id_type),
            nom_type: typeVente,
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
            nom_type: typeVente,
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
  
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
          <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire Type de Vente</Typography>
           <Grid container> 
                <Input name='typeVente'
                      placeholder='Type Vente'
                      type='text' required 
                      value={typeVente}
                      onChange={(e) => setTypeVente(e.target.value)}
                />
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid> 
            </Grid>
        </Box>
    )
  }