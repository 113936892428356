import { Backdrop, Box, DialogContent, Fade, Modal, useTheme } from "@mui/material";
import React from "react";
import { tokens } from '../../../theme';

interface IModalProps{
    open:boolean;
    onClose: any;
    children: React.ReactNode
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius:2,
    //  minWidth: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
  };

export default function ModalComponent ({open,onClose,children}: IModalProps){
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

    return(
        <Modal open={open} onClose={onClose} closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: {timeout: 500,},}}>
            
            <DialogContent>
                  <Fade in={open}>
                    <Box sx={style}>
                        <Box sx={{p:4,bgcolor:colors.primary[600],borderRadius:2}}>
                          { children }
                        </Box>
                      </Box>
                  </Fade>
            </DialogContent>

        </Modal>
    )
}
