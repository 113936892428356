import { Box, Grid2 as Grid, Paper, Typography } from '@mui/material';
import { useEmploieQuery } from '../../../data/emploie';
import ButtonLoading from '../../ui/button/button-loading';
import { useCreateCandidatMutation } from '../../../data/candidat';
import { useState } from 'react';
import Uploader from '../../ui/input/uploader';
import Input from '../../Input';
import { useMeQuery } from '../../../data/user';
import { useNavigate } from 'react-router-dom';

export default function PostulationForm({slug}:{slug:string}) {
     
   const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
   const { data } = useEmploieQuery({slug:slug})
   const { data:me } = useMeQuery()

   const { mutate: postulerPost ,isLoading:loading } = useCreateCandidatMutation()

   const navigate = useNavigate()
   const [Cv,setCv] = useState("")
   
   const handlePostuler = ( event:React.FormEvent<HTMLFormElement>) =>{
      event.preventDefault(); 
      postulerPost({
        emploie_id: String(data?.id_emploie),
        cv_file: JSON.stringify(Cv),
        user_id: String(me?.id),
        status: ''
      },{
        onSuccess:()=>{
           navigate('/emploies')
        }
      })
   }

    return(
      <> {  
     data && ( <Box component="form" onSubmit={handlePostuler}>
        <Grid container spacing={2} justifyContent="stretch"  sx={{ height: '100%',padding:{md:5,xl:7} }}>
            <Grid size={{xs:12, md:7}}  alignItems="stretch"   padding={4}>
              <Box 
                component="img" 
                src={JSON.parse(String(data?.image)).original} 
                alt="Illustration"
                sx={{
                  width:'100%',
                  borderRadius:2,
                  zIndex: 1,     
                  // objectFit: 'contain',
                  height:300,
                  display: { xs: 'none', sm: 'block' }, 
                }}
              />
           <Box sx={{paddingY:4,width:'100%'}}>

              <Typography dangerouslySetInnerHTML={{ __html: data?.description }} />
            </Box>
        </Grid>
            <Grid size={{xs:12, md:5 }}>
              <Paper elevation={3} sx={{ padding: { xs: 3, sm: 4 },marginTop:{xs : 2},borderRadius: '15px',width: { xl: '70vh' },}}>
                <Box display="flex" alignItems="center" justifyContent='center' sx={{ marginBottom: 2 }}>
                  <Box component="img" src={logo} alt="Logo" sx={{ width: 'auto', height: {xs:50, md:50, xl:80}, marginRight: 2, marginBottom: 2 }} />
                  <Typography variant="h2"  align="left">
                    Canditature
                  </Typography>
                </Box>
              <Input name='reference'
                        placeholder='Reference du poste'
                        type='text' required 
                        value={String(data?.reference)}
                        disabled={true}
                  />
                
                  <Input name='email'
                        placeholder='Votre email'
                        type='text' required 
                        value={String(me?.email)}
                      
                  />
                <Uploader multiple={true}
                      setSelectedFile={setCv}
                      value={Cv}
                      acceptFile="document"
                     helperText={"Entrez votre CV et votre lettre de motivation"} 
                />
                <ButtonLoading type="submit" loading={loading} variant='contained' color='secondary' label="Postuler" />
              
              </Paper>
            </Grid>
          </Grid>   
      
      </Box>
    )}
      </>)
}