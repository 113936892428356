import React from 'react';
import { useDeletePaiementMutation, usePaiementsQuery } from '../../../data/paiement';
import ActionsComponent from '../../ui/table/action';
import CollapsibleTable from '../../ui/table/collapsible';
import { Paiement } from '../../../types';
import { Chip, Typography } from '@mui/material';

const LOADING = 'loading'; 
const SEEN = 'seen';
const ACCEPTED = 'accepted';
const REFUSED = 'refused';

type ChipColor = 'error' | 'warning' | 'info' | 'default' | 'secondary' | 'primary' | 'secondary';

interface StatusInfo {
    label: string;
    color: ChipColor;
}

export default function PaiementList() {
    const { paiements } = usePaiementsQuery({});

    const getVerificationStatus = (status: string): StatusInfo => {
        switch (status) {
            case LOADING:  
                return { label: "En attente", color: "default" };
            case SEEN:
                return { label: "Vu", color: "info" };
            case ACCEPTED:
                return { label: "Accepté", color: "secondary" };
            case REFUSED:
                return { label: "Refusé", color: "error" };
            default:
                return { label: "Inconnu", color: "default" };
        }
    };

    const headers = [
        { name: "Réference", value: 'reference' },
        {
            name: "Type de paiement", value: "type_paiement",
            template: (record: Paiement) => (
                <Typography>{record?.type_paiement?.nom_type}</Typography>
            )
        },
        {
            name: "Montant", value: "montant",
            template: (record: Paiement) => (
                <Typography>Ar {record.montant}</Typography>
            )
        },
        {
            name: "Verification", value: "is_verified",
            template: (record: Paiement) => (
                <Chip size="small" color={record.is_verified ? "secondary" : "error"} variant="filled" label={record.is_verified ? "Vérifié" : "Non vérifié"} />
            )
        },
        {
            name: "Status", value: "status",
            template: (record: Paiement) => {
                const { label, color } = getVerificationStatus(record.status);
                return (
                    <Chip size="small" color={color} variant="filled" label={label} />
                );
            }
        },
        {
            name: "Action", value: "action",
            template: (record: Paiement) => (
                <ActionsComponent
                    id={{
                        id: record?.id_paiement,
                        deleteMutation: useDeletePaiementMutation,
                        description: `Voulez-vous supprimer <b>${record?.id_paiement}</b> de la liste des paiements ?`,
                        ...record
                    }}
                    deleteModalView='DELETE'
                    detailLink={`/admin/paiement/${record?.id_paiement}`}
                />
            )
        }
    ];

    return (
        <React.Fragment>
            <CollapsibleTable data={paiements} headers={headers} />
        </React.Fragment>
    );
}