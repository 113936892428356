import React from 'react';
import PageContainer from '../../../components/layout/container';
import { Box } from '@mui/material';
import { SEO } from '../../../components/Seo';
import { HeaderTitle } from '../../../components/header/title';
import FormationList from '../../../components/admin/formation/liste';

export default function FormationPage (){
  return(
    <React.Fragment>
       <SEO title='Liste des formations'/>
        <PageContainer>
            <Box sx={{px:4,pt:4,borderRadius:2}}>
                <HeaderTitle title="Formations" addModalView="ADD_FORMATION" />
            </Box>
            <FormationList />
        </PageContainer>
    </React.Fragment>
  )
}