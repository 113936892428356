import React from 'react'
import { useCandidatsQuery, useDeleteCandidatMutation } from '../../../data/candidat'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { Candidat } from '../../../types'
import { Typography } from '@mui/material'
import Links from '../../ui/Typography/link'
// import Logo from '../../ui/image/logo'

export default function CandidatList(){

    const { candidats } = useCandidatsQuery({})
   console.log(candidats)
    const headers =[
      {name:"Réf emploie",value:"emploie",
        template:(record:Candidat)=>{
          return(
              <Links text={ record?.emploie?.reference }
                     to={`/admin/emploies/${ record?.emploie?.slug}`}/>)
        }
      },{name:"Nom du candidat",value:"name",
        template:(record:Candidat)=>{
          return(
              <Typography>{ record?.candidat?.name } {record?.candidat?.lastname}</Typography>)
        }
      },
      {
        name:"CV",value:"cv_file",
        template: function Render(record: Candidat){
        
          return(
            <>
         {    JSON.parse(String(record?.cv_file)).file_name }
            </>
          )
        }
      },
      {name:"Action",value:"action",
        template:function Render(record:Candidat){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_candidat,
                   deleteMutation:useDeleteCandidatMutation,
                  deleteMessage: `Voulez-vous supprimer la candidature de  <b>${ record?.candidat?.name } ${record?.candidat?.lastname}</b> de la liste des candidats au poste <b>${record?.emploie?.titre_emploie}</b>?`,
                  ...record

                }}
                deleteModalView='DELETE'
                detailModal="PDF_VIEWER"
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={candidats} headers={headers}/>
      </React.Fragment>
    )
  }