import React from 'react'
import { useDeleteTypeVenteMutation, useTypeVentesQuery } from '../../../data/type vente'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'

export default function TypeVenteList(){

    const { typeVentes } = useTypeVentesQuery({})
    const headers =[
      {name:"Type de Ventes",value:"nom_type"},
      {name:"Action",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_type,
                  deleteMutation:useDeleteTypeVenteMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.nom_type}</b> de la liste des types de vente ?`,
                  ...record
                }}
                deleteModalView='DELETE'
                editModalView="ADD_TYPE_VENTE"
                detailLink={`/admin/type-vente/${record?.id_type}`}
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={typeVentes} headers={headers}/>
      </React.Fragment>
    )
  }
  