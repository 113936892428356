import React, { useState} from 'react'
import { Box,Typography,List, useTheme, Avatar, Divider } from '@mui/material'
import { siteSettings } from '../../settings/site.settings'
import Item from './navigation/sidebar-item'
import { tokens } from '../../theme'
import { useMeQuery } from '../../data/user'


function SideBar ({isOpen}:{isOpen:boolean}) {

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [selected,setSelected] = useState("")
    const { data } = useMeQuery();

   return (
    <React.Fragment>
        <List sx={{height:"100%",bgcolor:colors.primary[800]}}>
                  <Box textAlign="center" >
                        { isOpen && (
                         <Typography  variant="h4" sx={{ m: "10px 0 0 0 "}} color={colors.grey[100]}>
                           ADMIN
                        </Typography>)}
                    </Box>
                    {/* USER */}
                       {isOpen && ( <Box mb="25px" sx={{bgcolor:colors.primary[800]}}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                               <Avatar alt="profil-user" sx={{ width: 100, height: 100 }}  style={{ cursor:"pointer",borderRadius:"50px"}} src="/logo 4x4.png"/>
                            </Box>
                            <Box textAlign="center">
                                <Typography variant="h5" color={colors.grey[100]} fontWeight="bold" sx={{ m: "10px 0 0 0 "}}></Typography>
                                <Typography variant="h6" color={colors.green[100]}>{data?.name}  {data?.lastname}</Typography>
                            </Box>
                        </Box>)}
                    {/* MENU ITEMS */}
                    <Box sx={{bgcolor:colors.primary[800]}}>
                      {
                      //  isAdmin ?
                      siteSettings.sidebarLinks.admin.map((item:any,index: number)=> item.divider ?
                      (<Divider key={index}/> ):(
                                  <Item key={index}
                                    open={isOpen}
                                    title={item.text}
                                    to={"/admin"+item.to}
                                    icon={item.icon}
                                    selected={selected}
                                    setSelected={()=>setSelected("/admin"+item.to)}
                                  />
                                )
                      )
                    }
                </Box>
        </List>
    </React.Fragment>)
}

export { SideBar}
