import { SEO } from '../../../components/Seo';
import Footer from '../../../components/footer/Footer';
import ReservationFormation from '../../../components/clients/formations/reservation';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuthCredentials, isAuthenticated } from '../../../utils/auth-utils';
import { useEffect } from 'react';
 
export default function ReservationFormationPage() {
    const { slug } = useParams<{ slug: string }>();
    const { token, permissions } = getAuthCredentials();
    const navigate = useNavigate();
    
    useEffect(() => {
    if (!isAuthenticated({ token, permissions })) {
         navigate('/login');
      }
    })

    return( 
    <>
     <SEO title='Reservation formation'/>
     <ReservationFormation slug={String(slug)} />
     <Footer/>
    </>
    )
}