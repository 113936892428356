export const API_ENDPOINTS = {
    REGISTER_ADMIN: 'register/super-admin',
    USERS: 'users',
    RESERVATION:"reservations",
    SERVICE:"services",
    TYPESERVICE:"type-services",
    VENTE:"ventes",
    FORMATION:"formations",
    TYPEPAIEMENTS:"type-paiements",
    TYPEFORMATIONS:"type-formations",
    TYPEPLATEFORMS:"type-plateformes",
    TYPESERVICES:"type-services",
    TYPEVENTES:"type-ventes",
    PLATEFORMS:"plateformes",
    FORMATIONS:"formations",
    SERVICES:"services",
    VENTES:"ventes",
    PAIEMENTS:"paiements",
    EMPLOIE:"emploies",
    CANDIDAT:"candidats",
    ATTACHMENTS:"attachments",
    REGISTER: 'register',
    SETTINGS: 'settings',
    LOGOUT: 'logout',
    ME: 'me',
    TOKEN: 'token',
    LOGIN:'login',BLOCK_USER: 'users/block-user',
    UNBLOCK_USER: 'users/unblock-user',
    CHANGE_PASSWORD: 'change-password',
    FORGET_PASSWORD: 'forget-password',
    VERIFY_FORGET_PASSWORD_TOKEN: 'verify-forget-password-token',
    RESET_PASSWORD: 'reset-password',
    ACTIVATE:'active-account',
    MAKE_ADMIN: 'users/make-admin',
    ADD_WALLET_POINTS: 'add-points',
    SEND_VERIFICATION_EMAIL: '/email/verification-notification',
    UPDATE_EMAIL: '/update-email',

}