import React from 'react';
import { Position } from '../../../types/mui';

interface State {
    isOpen: boolean;
    message?:string;
    duration:number,
    severityType?:string,
    vertical?:Position
    horizontal?:Position
    onClose?:any
  }
  type Action =
    | { type: 'open'; message:string ;duration?:number; severityType?:string; vertical?:Position; horizontal?:Position; }
    | { type: 'close' };
  
  const initialState: State = {
    isOpen: false,
    duration:0,
    message: undefined,
    severityType:undefined,
    vertical:'top',
    horizontal:"right",
    onClose: ()=> {}
  };

  function snackbarReducer(state: State, action: Action): State {
    
    switch (action.type) {
      case 'open':
        return {
          ...state,
          ...action,
          isOpen: true,
        };
      case 'close':
        return {
          ...state,
          isOpen: false,
        };
      default:
        throw new Error('Unknown Snackbar Action!');
    }
  }

  const SnackbarStateContext = React.createContext<State>(initialState);
  SnackbarStateContext.displayName = 'SnackbarStateContext';

  const SnackbarActionContext = React.createContext<React.Dispatch<Action> | undefined>(undefined);
  SnackbarActionContext.displayName = 'SnackbarActionContext';

  export const SnackbarProvider : React.FC<{ children?: React.ReactNode }> = ({
    children,
  }) => {
    const [state, dispatch] = React.useReducer(snackbarReducer, initialState);
    return (
      <SnackbarStateContext.Provider value={state}>
         <SnackbarActionContext.Provider value={dispatch} >
            { children }
         </SnackbarActionContext.Provider>
      </SnackbarStateContext.Provider>
    );
  };
  

  
export function useSnackbarState() {
    const context = React.useContext(SnackbarStateContext);
    if (context === undefined) {
      throw new Error(`useSnackbarState must be used within a SnackbarProvider`);
    }
    return context;
  }
  
  export function useSnackbarAction() {
    const dispatch = React.useContext(SnackbarActionContext);
    if (dispatch === undefined) {
      throw new Error(`useSnackbarAction must be used within a SnackbarProvider`);
    }
    return {
      openSnackbar( message:string, severityType?:string, duration?:number, vertical?:Position, horizontal?:Position) {
        
        dispatch({ type: 'open',message, duration, severityType, vertical, horizontal  });
      },
      closeSnackbar() {
        dispatch({ type: 'close' });
      },
    };
  }
