import { QueryOptions, TypeVente, TypeVenteInput } from '../types';
import { TypeVentePaginator } from '../types/paginator';
import { TypeVenteQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';

export const TypeVenteClient = {
    ...crudFactory<TypeVente, QueryOptions, TypeVenteInput>(API_ENDPOINTS.TYPEVENTES),
    alls: ({ nom_type, ...params }: Partial<TypeVenteQueryOptions>) => {
      return HttpClient.get<TypeVentePaginator>(API_ENDPOINTS.TYPEVENTES, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ nom_type }),
      });
    },
  };