import { Box, Container, Paper, Typography, useTheme } from '@mui/material';
import React,{ useState } from 'react';
import Input from '../../components/Input';
import { SEO } from '../../components/Seo';
import Grid from '@mui/material/Grid2';
import { useForgetPasswordMutation } from '../../data/user';
import ButtonLoading from '../../components/ui/button/button-loading';
import { tokens } from '../../theme';
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
    const navigate= useNavigate();
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
    const image = `${process.env.PUBLIC_URL}/illustration/green/Forgot password-bro.png`;
    const image1 = `${process.env.PUBLIC_URL}/illustration/yellow/Forgot password-rafiki-yellow.png`;
    const [email, setEmail] = useState('');
    const { mutate: forgotPassword,isLoading }= useForgetPasswordMutation();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>{
      e.preventDefault();
      forgotPassword({
        email:email
      },
      {
        onSuccess: () =>{
          navigate('/verification-password');
        }
      }
    )
    }
    return (
        <Box component='div' sx={{ backgroundColor: colors.primary[600],minHeight:'100vh' }}>
            <SEO title='Forget password'/>
            <Container component='main' maxWidth='lg'>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%', paddingTop: 10 }}>
                    <Grid size={{xs:12, md:7}} display="flex" justifyContent="center" sx={{ position: 'relative' }}>
                        <Box
                          component='img' 
                          alt='Illustration' 
                          src={image1}
                          sx={{
                            width: { sm: '100%' },
                            height: 'auto',
                            maxHeight: { sm: '500px' },
                            left: { sm: '30%' },
                            objectFit: 'contain',
                            display: { xs: 'none', sm: 'block' },
                          }}
                        />
                        <Box
                          component='img' 
                          alt='Illustration' 
                          src={image}
                          bottom={80}
                          right={150}
                          sx={{
                            position: 'relative',
                            width: { sm: '60%' },
                            height: 'auto',
                            maxHeight: { sm: '400px' },
                            objectFit: 'contain',
                            display: { xs: 'none', sm: 'block' },
                          }}
                        />
                    </Grid>
                    <Grid size={{xs:12, md:5}}>
                        <Paper elevation={3} sx={{ padding: { xs: 3, sm: 4 }, backgroundColor: colors.grey[800], borderRadius: '15px' }}>
                            <Box display="flex" alignItems="center" justifyContent='center' sx={{ marginBottom: 2 }}>
                                <Box component="img" src={logo} alt="Logo" sx={{ width: 'auto', height: '50px', marginRight: 2, marginBottom: 2 }} />
                                <Typography 
                                  variant="h4"
                                  align="left"
                                  gutterBottom
                                  sx={{ fontFamily: 'Inika', color: 'white' }}>
                                    Forgot Password
                                </Typography>
                            </Box>
                            <Box component='form'>
                            <Input
                              name='email'
                              placeholder="Email / Numéro téléphone"
                              type='text'
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                             />
                              <ButtonLoading
                                  variant="contained"
                                  label='Envoyer Code'
                                  loading={isLoading}
                                  handleClick={handleSubmit}
                                  color='secondary'
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default ForgetPassword;