import { Disponible, QueryOptions, Vente, VenteInput } from '../types';
import { VentePaginator } from '../types/paginator';
import { VenteQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';

export const VenteClient = {
    ...crudFactory<Vente, QueryOptions, VenteInput>(API_ENDPOINTS.VENTES),
    alls: ({ id_vente, ...params }: Partial<VenteQueryOptions>) => {
      return HttpClient.get<VentePaginator>(API_ENDPOINTS.VENTES, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ id_vente }),
      });
    },
    updateDisponible:({ id, ...input }: Partial<Disponible> & { id: string }) =>{
      return HttpClient.put<Vente>(`/ventes/${id}`, input);
    },
  };