import { useParams } from 'react-router-dom';
import { usePlateformQuery } from '../../../data/plateform';
import PageContainer from '../../layout/container';
import { SEO } from '../../Seo';
import { HeaderTitle } from '../../header/title';
import { Container, Grid2 as Grid, Typography } from '@mui/material';
import Logo from '../../ui/image/logo';

export default function DetailPlateform() {
    const { slug } = useParams();
    const { data, isLoading, error } = usePlateformQuery({ slug });

    if (isLoading) return <Typography>Chargement...</Typography>;
    if (error) return <Typography color="error">Erreur : {error.message}</Typography>;
    console.log(data)
    return (
        <PageContainer>
            <SEO title={data?.nom_plateforme || 'Détails'} />
            <HeaderTitle title='Détail du Plateforme' />
            <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent='center'>
                <Grid  size={{ lg:4 }}>
                    <Logo logo={String(data?.logo)} width={300} height={300}/>
                </Grid>
                <Grid size={{lg:8}}>
                    <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Nom Plateforme</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.nom_plateforme}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Type de Plateforme</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.type_plateforme?.nom_type}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Lien</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.link}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Description</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                    <Typography 
                        component="div"
                        dangerouslySetInnerHTML={{ __html: String(data?.description) }} 
                   />
                    </Grid>
                </Grid>
            </Grid>
          
            </Grid>
            </Container>
        </PageContainer>
    );
}