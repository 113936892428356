import { StatusPaiement } from '../types/enum';
import { Status } from './enums';


export const getStatusColor = (status:string) =>{
    let color:any

    switch (status) {
        case Status.LOADING:
            color="info"
            break;
        case Status.ACCEPTED:
            color="secondary"
            break;
        case Status.SEEN:
            color="success"
            break;
        case Status.REFUSED:
            color="error"
            break;
        // case Status.UnPublish:
        //     color="warning"
        //     break;
        default:
            color="inherit"
            break;
    }

 return color;
}


export const getStatus= (status:string) =>{
    let value:any

    switch (status) {
        case Status.LOADING:
            value= StatusPaiement.LOADING
            break;
        case Status.ACCEPTED:
            value=StatusPaiement.ACCEPTED
            break;
        case Status.SEEN:
            value=StatusPaiement.SEEN
            break;
        case Status.REFUSED:
            value=StatusPaiement.REFUSED
            break;
        default:
            value=StatusPaiement.PENDING
            break;
    }

 return value;
}