import { Container, Grid2 as Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageContainer from '../../layout/container';
import { SEO } from '../../Seo';
import { HeaderTitle } from '../../header/title';
// import Logo from '../../ui/image/logo';
import { useFormationQuery } from '../../../data/formation';
import Logo from '../../ui/image/logo';

export default function DetailFormation() {
    const { slug } = useParams();
    const { data, error, isLoading } = useFormationQuery({ slug });

    if (isLoading) return <Typography>Chargement...</Typography>;
    if (error) return <Typography color="error">Erreur : {error.message}</Typography>;

    return (
        <PageContainer>
            <SEO title={data?.nom_formation || 'Détails'} />
            <HeaderTitle title='Détail du Formation' />
            <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent='center'>
                <Grid  size={{ lg:4 }}>
                <Logo logo={String(data?.image)} width={300} height={300}/>
                </Grid>
                <Grid size={{lg:8}}>
                    <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Nom de formation</Typography>
                        </Grid>
                        <Grid size={{ sm:9 }}>
                            <Typography >{data?.nom_formation}</Typography>
                        </Grid>
                     </Grid>
                     <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Type formation</Typography>
                        </Grid>
                        <Grid size={{ sm:9 }}>
                            <Typography >{data?.type_formation?.nom_type}</Typography>
                        </Grid>
                     </Grid>
                     <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'>Plateforme</Typography>
                        </Grid>
                        <Grid size={{ sm:9 }}>
                            <Typography >{data?.plateforme?.nom_plateforme}</Typography>
                        </Grid>
                     </Grid>
                     <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Prix</Typography>
                        </Grid>
                        <Grid size={{ sm:9 }}>
                            <Typography >Ar {data?.prix}</Typography>
                        </Grid>
                     </Grid>
                     <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Delais</Typography>
                        </Grid>
                        <Grid size={{ sm:9 }}>
                            <Typography >{data?.delais} Mois</Typography>
                        </Grid>
                     </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Description</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                    <Typography 
                                    component="div"
                                    dangerouslySetInnerHTML={{ __html: String(data?.description) }} 
                                />
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            </Container>
        </PageContainer>
    );
}