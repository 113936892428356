import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar ,{AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Theme, useTheme } from "@mui/material"
import DrawerMobile from "@mui/material/Drawer"
import { CSSObject } from '@emotion/react';
import Navbar from './navBar';
import { tokens } from '../../theme';
import { SideBar } from './sideBar';
import logo from '../../logo 4x4.png';
 import { getPermission } from '../../utils/auth-utils';


const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject  => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject  => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })( ({ theme, open,color }) => ({
    width: drawerWidth,
    backgrounColor:color,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

export default function AppBarDrawer ({children}:{children:React.ReactNode}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const { isAdmin } = getPermission()

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex',bgcolor:colors.primary[700]}} >
      <AppBar position="fixed" open={open} color="primary"  sx={{bgcolor:colors.primary[800]}}>
        <Toolbar >
       
           {isAdmin ? (
            
            <IconButton
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: {xs:"flex",md:'none'} }),
                }}  >
                <MenuIcon />
              </IconButton>
              
              
            ) : (
          
            <Box component="img" src={logo} className="" alt="logo" width={35} height={35}/>

          )}
          
         <Navbar/>
        </Toolbar>
      </AppBar>
      {isAdmin && ( <>
        <Drawer hideBackdrop variant="permanent" open={open}  sx={{":hover":'overflow-y-auto',overflowY:'none', color: colors.grey[100],bgcolor:colors.primary[800],display:{xs:"none",sm:"flex"}}}>
        <DrawerHeader sx={{bgcolor:colors.primary[800]}}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />  <SideBar isOpen={open}/>
      </Drawer>
     
        <DrawerMobile variant="temporary"  open={open}  sx={{bgcolor:colors.primary[100],display:{sm:"none",xs:"flex"}}} onClick={handleDrawer}>
        <Divider />
        
        <DrawerHeader/>
          <SideBar isOpen={open}/>
      </DrawerMobile></>)}
      <Box component="main"  sx={{ flexGrow: 1, pt: 3,width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          {children}
      </Box>
    </Box>
  );
}
