import {  Card, CardContent, CardMedia, Grid2, Typography } from '@mui/material';
import PaymentForm from './paiementForm';

// import {  } from "../../../../public/illustration/vente/amazon.png";
export default function PaimentComponent(){
    const amazon = `${process.env.PUBLIC_URL}/illustration/vente/amazon.png`;
    return(
        <>
        <Grid2 padding={{xs:1,md:'8rem 2rem'}} alignItems='center' container spacing={5} justifyContent='center' >
            <Grid2 size={{xs:12,md:4}}>
               <Card sx={{ md:'block',border:'solid 0.5px green',borderRadius:'0.5rem'}}>
                <CardMedia
                    sx={{ height: 240 ,borderRadius:'0.4rem',}}
                    image={amazon}
                    title="green iguana"
                />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Outlier
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Lizards are a widespread group of squamate reptiles, with over 6,000
                        species, ranging across all continents except Antarctica
                        </Typography>
                    </CardContent>
                  
                </Card>
            </Grid2>
            <Grid2 size={{xs:12,md:4}} width={{xs:'100%',md:600}}  sx={{background:'#F5F5F517',borderRadius:'0.5rem',padding:'2rem', }}>
                <PaymentForm/>
            </Grid2>
          </Grid2>
        </>
    )
}