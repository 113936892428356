import { useParams } from 'react-router-dom';
import { useEmploieQuery } from '../../../data/emploie';
import PageContainer from '../../layout/container';
import { SEO } from '../../Seo';
import { HeaderTitle } from '../../header/title';
import { Container, Grid2 as Grid, Typography } from '@mui/material';
import Logo from '../../ui/image/logo';

export default function DetailEmploie() {
    const { slug } = useParams();
    const { data, isLoading, error } = useEmploieQuery({ slug });
    
    if (isLoading) return <Typography>Chargement...</Typography>;
    if (error) return <Typography color="error">Erreur : {error.message}</Typography>;

    return (
        <PageContainer>
            <SEO title={data?.titre_emploie || 'Détails'} />
            <HeaderTitle title="Détail de l'emploie" />
            <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent='center'>
                <Grid  size={{ lg:4 }}>
                    <Logo logo={String(data?.image)} width={300} height={300}/>
                </Grid>
                <Grid size={{lg:8}}>
                    <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Titre emploie</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.titre_emploie}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Reference</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.reference}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Description</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                    <Typography 
                       component="div"
                       dangerouslySetInnerHTML={{ __html: String(data?.description) }} 
                    />
                    </Grid>
                </Grid>
            </Grid>
          
            </Grid>
            </Container>
        </PageContainer>
    );
}