import React from 'react'
import { useDeleteServiceMutation, useServicesQuery } from '../../../data/service'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { Service } from '../../../types'
import Logo from '../../ui/image/logo'
import { Typography } from '@mui/material'

export default function ServiceList(){

    const { services } = useServicesQuery({})
   
    const headers =[
      {name:"Image",value:'image',
        template: function Render(record: Service){
        
          return(
            <>
         {    record?.image &&( <Logo logo={record?.image} />) }
            </>
          )
        }
      },
      {name:"Nom de Service",value:"nom_service"},
      {name:"Type de Service",value:"type_service",
        template: (record:Service)=>{
          return(
            <>
              { record?.type_service?.nom_type}
            </>
          )
        }
      },
      {name:"Plateforme",value:"plateforme",
        template: (record:Service)=>{
          return(
            <>
              { record?.plateforme?.nom_plateforme}
            </>
          )
        }
      },
      {name:"Prix",value:"prix",
        template: (record: Service) => (
          <Typography>Ar {record.prix}</Typography>
      )
      },
      {name:"Delais",value:"delais",
        template: (record: Service) => (
          <Typography>{record.delais} Jours</Typography>
      )
      },
      {name:"Description",value:"description"},
      {name:"Action",value:"action",
        template:function Render(record:Service){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_service,
                  deleteMutation:useDeleteServiceMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.nom_service}</b> de la liste des services ?`,
                  ...record
                }}
                deleteModalView='DELETE'
                editModalView="ADD_SERVICE"
                detailLink={`/admin/services/${record?.slug}`} 
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={services} headers={headers}/>
      </React.Fragment>
    )
  }
  