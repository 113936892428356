import { MODAL_VIEWS, useModalAction, useModalState } from './modal.context';
import ModalComponent from './modal';
import { Box } from '@mui/material';
import SignOut from '../../auth/logout';
import ConfirmDelete from '../../deleteModal';
import ConfirmUserAction from '../../users/confirm-action';
import { TypePaiementForm } from '../../admin/type paiement/form';
import { TypePlateformForm } from '../../admin/type platefom/form';
import { TypeServiceForm } from '../../admin/type service/form';
import { TypeVenteForm } from '../../admin/type vente/form';
import { PlateformForm } from '../../admin/plateform/form';
import { ServiceForm } from '../../admin/service/form';
import { VenteForm } from '../../admin/vente/form';
import { TypeFormationForm } from '../../admin/type formation/form';
import { FormationForm } from '../../admin/formation/form';
import { EmploieForm } from '../../admin/emploie/form';
import ValidateAction from '../../validate-action';


function renderModal(view: MODAL_VIEWS | undefined , data: any) {
 
  // console.log(data);
  
  switch (view) {
    case "ADD_TYPES" :
      return <TypePaiementForm defaultValues={data} />
    case "ADD_TYPE_PLATEFORM" :
      return <TypePlateformForm defaultValues={data} />
    case "ADD_TYPE_SERVICE" :
      return <TypeServiceForm defaultValues={data} />
    case "ADD_TYPE_VENTE" :
      return <TypeVenteForm defaultValues={data} />
    case "ADD_PLATEFORM" :
      return <PlateformForm defaultValues={data} />
    case "ADD_SERVICE" :
        return <ServiceForm defaultValues={data} />
    case "ADD_VENTE" :
        return <VenteForm defaultValues={data} />
    case "ADD_TYPE_FORMATION" :
        return <TypeFormationForm defaultValues={data} />
    case "ADD_FORMATION" :
        return <FormationForm defaultValues={data} />
    case "ADD_EMPLOIE" :
          return <EmploieForm defaultValues={data} />
     case "LOGOUT":
       return <SignOut />
    case "DELETE":
        return <ConfirmDelete />
    case "USER_MODAL" :
        return <ConfirmUserAction  />
    case "VALIDATE_MODAL":
        return <ValidateAction />
    default:
      return null;
  }
}

const ManagedModal = () => {
  const { isOpen, view , data } = useModalState();
  const { closeModal } = useModalAction();

  return (
      <ModalComponent open={isOpen} onClose={closeModal}>
          <Box>
            {renderModal(view, data)}
          </Box>
      </ModalComponent>
  );
};

export default ManagedModal;
