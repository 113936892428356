import React from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { Card, styled } from '@mui/material';

const props = {
    width: 500,
    height: 300,
    xAxis: [{ data: ['Outlier', 'Remotasks', 'Amazon','Plateform X'], scaleType: 'band' as const }],
  };

const StyledCard = styled(Card)(({ theme }) => ({
    textAlign: 'start',
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    margin:5
}));

export default function GraphBar() {
  return (
    <StyledCard>
      <BarChart
      {...props}
      series={[
        {
          data: [2, 5, 7,11],
          label: 'Plateformes',
        },
      ]}
    />
    </StyledCard>
  )
}
