import { Delete } from "@mui/icons-material";
import { Box, Button, Grid2, Typography } from "@mui/material";
import React from 'react';
import { Color, Variant } from '../types/mui';
import ButtonLoading from './ui/button/button-loading';

type ConfirmationCardProps = {
    onCancel: () => void;
    onAccept: () => void;
    title?: string;
    icon?: any;
    titleColor?:string
    description?: string;
    cancelBtnVariant?: Variant
    acceptBtnVariant?: Variant
    cancelBtnText?: string;
    acceptBtnText?: string;
    cancelBtnColor?: Color;
    acceptBtnColor?:Color;
    cancelBtnLoading?: boolean;
    acceptBtnLoading?: boolean;
  };


export default function ConfirmCard({
    onCancel,
    onAccept,
    icon,
    title = 'button-accept',
    description = 'accept-item-confirm',
    cancelBtnText = 'Annuler',
    acceptBtnText = 'Accepter',
    cancelBtnVariant='contained',
    titleColor='red',
    acceptBtnVariant='outlined',
    cancelBtnColor='secondary',
    acceptBtnColor='error',
    acceptBtnLoading,
  }:ConfirmationCardProps){

    return(
      <React.Fragment>
        <Box maxWidth={300}>
            <Grid2 container  sx={{display:'flex',alignItems:'center',alignContent:'center'}} spacing={2} >
                <Grid2  size={12} sx={{alignItems:"center" }}>
                    <Box textAlign='center' >
                {icon ? (
                    icon
                    ) : (
                    <Delete fontSize="large" color="error" />
                    )
                }

                    <Typography variant="h3" marginBottom={4} sx={{color:titleColor}} >{title}</Typography>
                    <div className="subtitle1" dangerouslySetInnerHTML={{ __html: description }}></div>
                    </Box>
                </Grid2>
               <Grid2 size={{xs:12,sm:6}}  sx={{my:{xs:0,sm:2}}} >
                    <Button color={cancelBtnColor}
                            variant={cancelBtnVariant} 
                            onClick={onCancel} 
                            fullWidth >
                            {cancelBtnText}
                    </Button>
              </Grid2>
              <Grid2  size={{xs:12,sm:6}} sx={{my:{xs:0,sm:1}}}>
                    <ButtonLoading 
                        variant={acceptBtnVariant}
                        color={acceptBtnColor}
                        loading={Boolean(acceptBtnLoading)} 
                        label={acceptBtnText} 
                        handleClick={onAccept} />
              </Grid2>
        </Grid2>
        </Box>
  </React.Fragment> )
}
