import { CheckCircle, PersonOff } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useModalAction, useModalState } from "../ui/modal/modal.context";
import React from "react";
import { tokens } from "../../theme";
import { Color, Variant } from "../../types/mui";
import { useActiveUserMutation, useBlockUserMutation, useDeleteUser } from '../../data/user';
import ConfirmCard from '../confirm-card';


export default function ConfirmUserAction(){
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const { mutate: activeUser, isLoading: loading } = useActiveUserMutation();
  const { mutate: deleteUser,isLoading: deleteLoading } = useDeleteUser()
  const { mutate: blockUser,isLoading } = useBlockUserMutation()

  const { closeModal } = useModalAction();
  const { data } = useModalState();

  console.log(data)

  // async function handleConfirm() {

  //   // switch(data.action){
  //   //   case 'activate':
  //   //   return  alert('azerty')
  //   // }
  //   // if(!loading){
  //   //     closeModal()
  //   // }
  // }

  let title:string='Supprimer',description:string='',titleColor:string=colors.redAccent[400],acceptBtnVariant:Variant='outlined',cancelBtnVariant:Variant='contained';
  let cancelBtnColor:Color='secondary', acceptBtnColor:Color='error',icon:any
  let handleConfirm: any
  const cancelBtnText:string="Annuler"

    switch(data.action){
      case 'delete':
        title='Supprimer'
        description = `Voulez-vous supprimer <b>${data?.name} ${data?.lastname}</b> de la liste des utilisateurs ?`
        handleConfirm = () =>  deleteUser(data?.user_id)
        break;
      case 'bloquer':
        title='Bloquer'
        description = `Voulez-vous signaler <b>${data?.name} ${data?.lastname}</b> pour  réctifier ?`
        icon= <React.Fragment><PersonOff color="error" fontSize="large"/></React.Fragment>
        acceptBtnColor="error"
        cancelBtnColor="secondary"
        titleColor=colors.redAccent[500]
        acceptBtnVariant='outlined'
        cancelBtnVariant="contained"
        handleConfirm =() => blockUser(data?.user_id)
        break;
     case 'accept':
        title='Valider'
        description = `Voulez-vous valider le compte <b>${data?.name} ${data?.lastname}</b> ?`
        icon=<CheckCircle color="secondary" fontSize="large"/>
        acceptBtnColor="secondary"
        cancelBtnColor="secondary"
        titleColor=colors.green[500]
        acceptBtnVariant='contained'
        cancelBtnVariant="outlined"
        handleConfirm = () => activeUser(data?.num_user)
        break;
    }

    return(
        <ConfirmCard icon={icon} titleColor={titleColor}title={title} description={description} acceptBtnText={title}cancelBtnText={cancelBtnText}
   
            onCancel={closeModal}
            onAccept={handleConfirm}
            acceptBtnLoading={loading || isLoading ||deleteLoading}
            acceptBtnColor={acceptBtnColor}
            cancelBtnColor={cancelBtnColor}
            cancelBtnVariant={cancelBtnVariant}
            acceptBtnVariant={acceptBtnVariant}
            />
    )
}
