import { Skeleton } from '@mui/material';
import PageContainer from '../../layout/container';


export default function ServiceSkeleton(){

    return(
        <>
            <PageContainer>
                <Skeleton variant='rectangular' height="30%" width="100%"></Skeleton>
            </PageContainer>
        </>
    )
}