import { Box } from '@mui/material';
import Header from '../../../components/header/Header';
import { SEO } from '../../../components/Seo';
import CreerCompteFreelance from '../../../components/clients/services/creeCompteClients/creerCompteFreelance';
import CreePortfeuillClient from '../../../components/clients/services/creePrtefeuilleClient/creePortfeuillClient';
import { useParams } from 'react-router-dom';
import ServiceDevPage from '../../../components/clients/services/developpement-web/liste';
import { useTypeServiceQuery } from '../../../data/type service';
import { NotFound } from '../../../components/not-found/not-found';
import React from 'react';
import ServiceSkeleton from '../../../components/ui/skeleton/service';
import Footer from '../../../components/footer/Footer';


const ServiceList = ({slug}:{slug:string})=>{
    
    let CardList ;
    switch (slug) {
        case "creation-de-compte-freelance":
            CardList = <CreerCompteFreelance />
            break;
        case "creation-porte-feuille-electronique":
            CardList = <CreePortfeuillClient />;
            break;
        case"developpement-informatique":
            CardList=<ServiceDevPage /> 
            break;
        default:
            CardList= <>
                        <SEO title='404: service introuvable' />
                        <NotFound text="Service introuvable" />
                    </> 
    }

    return CardList
}
const ServicePage = () => {
    
        const { slug } = useParams<{ slug: string }>();
       
        const { data,isLoading } = useTypeServiceQuery({slug:slug})
        
        return (
            <React.Fragment>
            { isLoading ? (
                <>
                    <ServiceSkeleton />
                </>
                ) :
            (<>
                <SEO title={`Services ${data?.nom_type}`} />         
                {data?.id_type && (<Header description=' Découvrez notre plateforme complète qui vous accompagne dans chaque étape de 
                        votre parcours freelance : création de comptes, configuration de portefeuilles 
                        électroniques,' />     ) }      
                <Box>
                    <ServiceList slug={String(slug)} /> 
                </Box>
               { data?.id_type && <Footer/>}
            </>) 
            } 
        </React.Fragment>);
      }


export default ServicePage;