import { Add, Search } from '@mui/icons-material'
import { Typography,  useTheme, TextField, InputAdornment, Button ,Grid2 as Grid, IconButton } from '@mui/material'
import { MODAL_VIEWS, useModalAction } from '../ui/modal/modal.context';
import { tokens, typographyFont } from '../../theme';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface TitleProps{
    title:string,
    subtitle?:string,
    search?:boolean,
    addLink?:string,
    addModalView?:MODAL_VIEWS;
    newModal?:MODAL_VIEWS,
    newLink?:string;
}
const HeaderTitle = ({title,subtitle,search,addLink,addModalView,newModal,newLink}:TitleProps) => {
 const theme = useTheme()
 const colors = tokens(theme.palette.mode)
 const navigate = useNavigate()
 const { openModal } = useModalAction()

 const handleAdd= () =>{

    if(addLink){
      navigate(addLink)
    }else{
        let data:any ={}
        if(newModal){
            data={modalView : newModal}
        }
        if(newLink){
            data={newLink : newLink}
        }

        openModal(addModalView,data)
    }
 }
    return (
      <React.Fragment>
            <Grid container spacing={2}>
                <Grid size={addLink || addModalView ? (search ? 6 : 10) : 12}>
                  <Typography
                    color={colors.green[500]}
                    fontWeight="bold"
                    sx={{mb:"25px",...typographyFont.h3}}>
                        {title}
                    </Typography>
                    <Typography variant="h5" color={colors.green[500]}>
                        {subtitle}
                    </Typography>
                </Grid>
                 {search&&(<Grid size={5}>
                   <TextField color='secondary' fullWidth size='small'  placeholder='Recherche'
                             InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                                ),
                            }}/>
                  </Grid>)}
                {(addLink || addModalView)&&( 
                    <Grid size={1} paddingY="auto" >
                        <Button sx={{display:{sm:'block',xs:'none'}}} variant='contained'  color='secondary' onClick={handleAdd}>Ajouter</Button>
                        <IconButton   sx={{bgcolor:colors.green[500], display:{xs:'block',sm:'none'}}} onClick={handleAdd} > <Add fontSize='large'/> </IconButton>
                 </Grid>)}
            </Grid>
      </React.Fragment>
    )
}
export  { HeaderTitle }
