import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { FormationClient } from '../api/formation';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { useModalAction } from '../components/ui/modal/modal.context';
import { Formation, GetParams } from '../types';
import { FormationQueryOptions } from '../types/queryOptions';
import { FormationPaginator } from '../types/paginator';

export const useCreateFormationMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(FormationClient.create, {
      onSuccess: (data: any) => {
        
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.FORMATIONS]);
      },
    });
  };
  
  export const useDeleteFormationMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(FormationClient.delete, {
      onSuccess: (data:any) => {
        
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.FORMATIONS]);
      },
    });
  };
  
  export const useUpdateFormationMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(FormationClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.FORMATIONS]);
      },
    });
  };
  
  export const useFormationQuery = ({ slug, language }: GetParams) => {
    return useQuery<Formation, Error>({
     queryKey: [API_ENDPOINTS.FORMATIONS, { slug, language }],
     queryFn: () => FormationClient.get({ id:slug, language })
    });
  };
  
  export const useFormationsQuery = (options?: Partial<FormationQueryOptions>) => {
    const { data, isLoading, error } = useQuery<FormationPaginator, Error>({
     queryKey:[API_ENDPOINTS.FORMATIONS, options],
     queryFn: ({ queryKey, pageParam }) =>
        FormationClient.alls(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      formations: data ?? [],
      loading: isLoading,
      error,
    };
  };
  