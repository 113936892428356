import { SortOrder } from './enum';
import { User } from './users';
export const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],
  ['link', 'image', 'video', 'formula'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['clean']                                         // remove formatting button
];
export interface SocialInput {
    type?: string;
    link?: string;
  }

export interface Social {
    type?: string;
    link?: string;
  }

export interface AttachmentInput {
    thumbnail: string;
    original: string;
    id?: string;
  }

export interface Attachment {
    thumbnail: string;
    original: string;
    id?: string;
  }

export interface TypePaiement{
    id_type:number;
    nom_type:string;
    numero: string;
    code_transfert:string;
    logo: string;
    created_at?:string;
    updated_at?:string;

  }

export interface TypePaiementInput{
    nom_type:string;
    numero: string;
    code_transfert:string;
    logo: string | Attachment
  }
  
export interface Paiement{
    id_paiement:string;
    reference:string;
    montant:number;
    type_paiement:TypePaiement;
    type_id:string
    is_verified:boolean
    status: string
  
  }
  
export interface PaiementInput{
    montant:number|undefined;
    type_paiement:string;
    reference:string;
    status: string
  }


  
export interface PaginatorInfo<T> {
    currentPage: number | undefined;
    perPage: number | undefined;
    current_page: number;
    data: T[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: any[];
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
  }
export interface QueryOptions {
    language: string;
    limit?: number;
    page?: number;
    orderBy?: string;
    sortedBy?: SortOrder;
  }
export interface GetParams {
    id?: string;
    slug?: string;
    language?: string;
    numappartement?:string;
  }
  
export type QueryOptionsType = {
    page?: number;
    name?: string;
    coperative_id?: number;
    type?:string;
    limit?: number;
    orderBy?: string;
    sortedBy?: SortOrder;
  };

export interface TypeService{
  id_type:number;
  nom_type:string;
  description:string;
  }
  
export interface TypeServiceInput{
  nom_type:string;
  description: string;
  }

export interface TypePlateform{
  id_type:number;
  nom_type:string;
  slug:string;
  description:string;
}

export interface TypePlateformInput{
  nom_type:string;
  description: string;
}

export interface TypeVente{
  id_type:number;
  nom_type:string;
}

export interface TypeVenteInput{
  nom_type:string;
}

export interface Plateforme{
  id_plateforme:number;
  nom_plateforme:string;
  slug:string;
  logo: string;
  description:string;
  link:string;
  type_plateforme:TypePlateform;
  type_id : string
}

export interface PlateformInput{
  nom_plateforme:string;
  logo: string | Attachment
  description:string;
  link:string;
  type_id:string
}

export interface Formation{
  id_formation:number;
  nom_formation:string;
  slug:string;
  prix:number;
  delais:number;
  description:string;
  image: string;
  is_disponible:boolean;
  type_formation:TypeFormation;
  type_id:string;
  plateforme:Plateforme;
  plateforme_id:string;
}

export interface FormationInput{
  prix:number;
  nom_formation:string;
  delais:number;
  description:string;
  is_disponible:boolean;
  image: string | Attachment;
  type_id:string;
  plateforme_id:string;
}

export interface Service{
  id_service:number;
  nom_service:string;
  prix:number;
  slug:string;
  delais:number;
  description:string;
  image:string;
  type_service:TypeService
  type_id:string;
  plateforme:Plateforme;
  is_disponible?:boolean
}

export interface ServiceInput{
  nom_service:string;
  prix:number;
  delais:number;
  description:string;
  image:string | Attachment;
  type_id:number;
  plateforme_id?:number
}

export interface Disponible{
  is_disponible:boolean
}
export interface Vente{
  id_vente:number;
  prix:number;
  delais:number;
  image: string;
  description:string;
  is_disponible:boolean;
  type_vente:TypeVente;
  type_id:string;
  plateforme:Plateforme;
  plateforme_id:string
}

export interface VenteInput{
  prix:number;
  delais:number;
  image: string | Attachment;
  is_disponible:boolean;
  type_id:string;
  plateforme_id:string;
  description:string;
}

export interface TypeFormation{
  id_type:number;
  nom_type:string;
  slug:string;
  description:string;
  image:string
}

export interface TypeFormationInput{
  nom_type:string;
  description: string;
  image: string | Attachment
}

export  interface Reservation{
  id_reservation:number;
  service:Service;
  service_id:string;
  vente_id:string;
  vente:Vente;
  formation_id:string;
  formation:Formation;
  paiement:Paiement;
  paiement_id:string;
  client:User;
  user_id:string
}

export interface ReservationInput{
  service_id?:string;
  vente_id?:string;
  paiement_id:string;
  formation_id?:string;
}

export interface Candidat{
  cv_file: string|Attachment;
  status:string;
  emploie_id:string;
  emploie:Emploie
  user_id:string;
  client:User
}

export interface CandidatInput{
  cv_file: string|Attachment;
  emploie_id:string;
  user_id:string
}
export interface Emploie{
  id_emploie:number;
  titre_emploie:string;
  slug:string;
  reference:string;
  image : string;
  is_disponible: boolean;
  description: string
}

export interface EmploieInput{
  titre_emploie:string;
  reference:string;
  image : string;
  is_disponible: boolean;
  description: string
}

export interface Candidat{
  id_candidat: number;
  status: string;
  cv_file : string | Attachment;
  emploie : Emploie
  emploie_id : string;
  candidat : User;
  user_id : string
}

export interface CandidatInput{
  status: string;
  cv_file : string | Attachment;
  emploie_id : string;
  user_id : string
}