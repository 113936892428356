import * as React from 'react';
import {  Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { TransitionLeft } from '../transition/slide';


// interface State extends SnackbarOrigin {
//     open: boolean;
//   }

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  interface IProps{
    autoHideDuration?:number
    open:boolean
    message?:string,
    vertical?:any,
    horizontal?:any
    severity:any
    onClose:any
  }
  
export const AlertComponent = ({message,open,autoHideDuration=5000,severity="success",onClose,vertical='top',horizontal='right'}:IProps) =>{
    
    return (
         <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            autoHideDuration={autoHideDuration} 
             onClose={onClose}    
            TransitionComponent={TransitionLeft}
            key={vertical + horizontal}
          >
            <Alert  severity={severity} sx={{ width: '100%' }}>
               {message}
            </Alert>
          </Snackbar>
      );
}