import { Box } from '@mui/material';
import { HeaderTitle } from '../../../components/header/title';
import React from 'react';
import TypePaiementList from './../../../components/admin/type paiement/liste';
import PageContainer from '../../../components/layout/container';
import { SEO } from '../../../components/Seo';
  
  export default function TypePaiementPage (){
    return(
      <React.Fragment>
         <SEO title='Liste des types de paiements'/>
          <PageContainer>
              <Box sx={{px:4,pt:4,borderRadius:2}}>
                  <HeaderTitle title="Type de paiements" addModalView="ADD_TYPES" />
              </Box>
              <TypePaiementList />
          </PageContainer>
      </React.Fragment>
    )
  }