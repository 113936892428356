import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from "react";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <React.Fragment>
    <Tooltip {...props} classes={{ popper: className }} />
</React.Fragment>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <React.Fragment>
    <Tooltip {...props} classes={{ popper: className }} />
    </React.Fragment>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  interface MyTooltipProps{
   variant?:'light'|'html'|'normal',
   children:any,
   title:any,
   [key:string]:unknown
  }
const Tooltips = ({variant,children,title,...rest}: MyTooltipProps) =>{
    if (variant==='light'){
        return (
            <>
                <LightTooltip title={title} {...rest}>
                    {
                        children
                    }
                </LightTooltip>
            </>
        )
    }
    else if (variant==='html'){
        return (
            <>
                <HtmlTooltip title={title} {...rest}>
                    {
                        children
                    }
                </HtmlTooltip>
            </>
        )
    }else{
        return (
            <>
                <Tooltip title={title} {...rest}>
                    {
                        children
                    }
                </Tooltip>
            </>
        )
    }


}
export default Tooltips
