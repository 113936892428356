

import { Box, TextField } from '@mui/material';
import React from 'react';
interface Iinput   {
    placeholder: string;
    label: string;   
    value:string;
    id?:any;
}

const InputPaiment:React.FC<Iinput> = ({id, placeholder ,label  ,value}) => {
    return (
       
<Box padding={1}>
<TextField color="secondary" fullWidth id={id} placeholder={placeholder}  label={label} value={value} variant='outlined' required />
</Box>
       
    )
};

export default InputPaiment
