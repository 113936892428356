import {  Box, Stack, TextField, Typography } from '@mui/material';


  export const Input = () =>{
    
    const currencies = [
        {
          value: '',
          label: 'OUTLIER',
        },
        {
          value: '',
          label: 'AMAZON Mturk',
        },
        {
          value: '',
          label: 'PLATFORM X',
        },
    ]
    return(
        <>
        <Stack alignItems='center' sx={{padding:{xs:1,md:'1rem 5rem',xl:'1rem 10rem'}}} direction={{xs:'row'}}>

                    <Typography padding={1}>Nom</Typography>
                    <TextField
                    color='secondary'
                    sx={{color:'white',width:{xs:'100%',md:'100%'},height:{xs:'20%',}}}
                    id="outlined-multiline-flexible"
                    label="Votre nom"
                    type='text'
                    multiline
                    maxRows={4}
                    />
        
                    <Typography padding={1}>Prenom</Typography>
                    <TextField
                     color='secondary'
                    sx={{color:'white',width:{xs:'100%',md:'100%'}}}
                    id="outlined-multiline-flexible"
                    label="Votre nom"
                    type='text'
                    multiline
                    maxRows={4}
                    />
       
        </Stack>
            <Stack sx={{padding:{xs:1,md:'0rem 5rem',xl:'2rem 10rem'}}} direction={{xs:'row'}}>
            <Typography padding={1}>Email</Typography>
                        <TextField
                         color='secondary'
                        sx={{color:'white',width:{xs:'100%',md:'100%'}}}
                        id="outlined-multiline-flexible"
                        label="Votre Email"
                        type='email'
                        multiline
                        maxRows={4}
                        />
            <Typography padding={1}>Adress</Typography>
                        <TextField
                        sx={{color:'white',width:{xs:'100%',md:'100%'}}}
                        id="outlined-multiline-flexible"
                        label="Lot 52 bis Manjakaray"
                        type='text'
                        multiline
                        maxRows={4}
                        />
            </Stack>

          <Box sx={{padding:{xs:1,md:'0rem 5rem',xl:'2rem 10rem'}}}>
          <Typography padding={1}>Numero Telephone</Typography>
                    <TextField
                     color='secondary'
                    sx={{color:'white',width:{xs:'100%',md:'100%'}}}
                    id="outlined-multiline-flexible"
                    label="Votre Email"
                    type='text'
                    multiline
                    maxRows={4}
                    />
          </Box>
          <Box sx={{padding:{xs:1,md:'0rem 5rem',xl:'0rem 10rem'}}}>
          <Typography padding={1}>Type de compte</Typography>
          <TextField
           color='secondary'
          sx={{width:'100%'}}
          id="outlined-select-currency-native"
          select
          label=""
          defaultValue="OUTLIER"
          slotProps={{
            select: {
              native: true,
            },
          }}
          helperText="Please select your currency"
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
          </Box>
        </>
    )
}