import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { PaiementClient } from '../api/paiement';
import { useModalAction } from '../components/ui/modal/modal.context';
import { GetParams, Paiement } from '../types';
import { PaiementQueryOptions } from '../types/queryOptions';
import { PaiementPaginator } from '../types/paginator';

export const useCreatePaiementMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(PaiementClient.create, {
      onSuccess: (data: any) => {
        // Router.push(Routes.typeagence.list, undefined, {
        //   locale: Config.defaultLanguage,
        // });
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.PAIEMENTS]);
      },
    });
  };
  
  export const useDeletePaiementMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(PaiementClient.delete, {
      onSuccess: (data:any) => {
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.PAIEMENTS]);
      },
    });
  };
  
  export const useUpdatePaiementMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(PaiementClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.PAIEMENTS]);
      },
    });
  };
  
  export const usePaiementQuery = ({ slug, language }: GetParams) => {
    return useQuery<Paiement, Error>({
     queryKey: [API_ENDPOINTS.PAIEMENTS, { slug, language }],
     queryFn: () => PaiementClient.get({ id:slug, language })
    });
  };
  
  export const usePaiementsQuery = (options?: Partial<PaiementQueryOptions>) => {
    const { data, isLoading, error } = useQuery<PaiementPaginator, Error>({
     queryKey:[API_ENDPOINTS.PAIEMENTS, options],
     queryFn: ({ queryKey, pageParam }) =>
        PaiementClient.all(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      paiements: data ?? [],
      loading: isLoading,
      error,
    };
  };
  