import { useParams } from 'react-router-dom';
import { useVenteQuery } from '../../../data/vente';
import PageContainer from '../../layout/container';
import { SEO } from '../../Seo';
import { HeaderTitle } from '../../header/title';
import { Container, Grid2 as Grid, Typography } from '@mui/material';
import Logo from '../../ui/image/logo';

export default function DetailVente() {
    const { slug } = useParams();
    const { data, isLoading, error } = useVenteQuery({ slug });

    if (isLoading) return <Typography>Chargement...</Typography>;
    if (error) return <Typography color="error">Erreur : {error.message}</Typography>;

    console.log(data)

    return (
        <PageContainer>
            <SEO title="Détails vente" />
            <HeaderTitle title='Détail du Vente' />
            <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent='center'>
                <Grid  size={{ lg:4 }}>
                <Logo logo={String(data?.image)} width={300} height={300}/>
                </Grid>
                <Grid size={{lg:8}}>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Type Vente</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.type_vente.nom_type}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Plateforme</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.plateforme.nom_plateforme}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Prix</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >Ar {data?.prix}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Delais</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.delais} Jours</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Disponible</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                    <Typography 
                        component="div"
                        dangerouslySetInnerHTML={{ __html: String(data?.description) }} 
                     />
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            </Container>
        </PageContainer>
    );
}