import { useMutation, useQueryClient } from 'react-query';
import { uploadClient } from '../api/upload';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { getFormErrors } from '../api/http-clients';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';

export const useUploadMutation = () => {
  const queryClient = useQueryClient();
 const { openSnackbar } = useSnackbarAction()
  return useMutation(
    (input: any) => {
      return uploadClient.upload(input);
    },
    {
      onError: (data:any)=>{
        openSnackbar(getFormErrors(data),'error')
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(API_ENDPOINTS.SETTINGS);
      },
    }
  );
};
