import { Emploie, EmploieInput, QueryOptions } from '../types';
import { EmploiePaginator } from '../types/paginator';
import { EmploieQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';

export const EmploieClient = {
    ...crudFactory<Emploie, QueryOptions, EmploieInput>(API_ENDPOINTS.EMPLOIE),
    alls: ({ id_emploie, ...params }: Partial<EmploieQueryOptions>) => {
      return HttpClient.get<EmploiePaginator>(API_ENDPOINTS.EMPLOIE, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ id_emploie }),
      });
    },
  };
  