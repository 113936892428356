import '../../../App.css'
import { Box,Button,Grid2, Stack, Typography } from '@mui/material';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import { typographyFont } from '../../../theme';

export default function AnnonceCall() {
  const callamico = `${process.env.PUBLIC_URL}/illustration/green/Call center-amico.png`;
    const callcuate = `${process.env.PUBLIC_URL}/illustration/green/Call center-cuate.png`;
    return(
        <>
        <Box width='100%' sx={{padding:'3rem 1rem'}}>
            <Grid2  sx={{background:'#e0e0e0',borderRadius:'1rem',alignItems:{xs:"center"}}} container>
              <Stack  direction={{ xs: 'column-reverse', sm: 'row' }} spacing={{ xs: 1, sm: 8, md: 2 }}>
                <Stack data-aos="fade-right" direction={{ xs: 'column', sm: 'row' }} 
                       width={{xs:"100%",sm:"60%"}}
                       spacing={{ xs: 1, sm: 2, md: 2 }}
                       sx={{display:'flex',gap:'1rem',justifyContent:'end',position:'relative', alignItems:'center',top:{sm:'5rem'}}}>
                        <Box textAlign="center">
                          <PhoneCallbackIcon color='primary' sx={{fontSize:'3rem'}}/>
                          <Typography color='primary' component="p" sx={{...typographyFont.h3,fontWeight:600}}>
                            Appel entrant
                          </Typography>
                        </Box>
                  <Box component='img' sx={{background:'#424242',borderRadius:'1rem',width:{xs:250,sm:350},height:{xs:200,sm:250}}} src={callamico} alt="mode gratuit"/>
                </Stack>
                <Box width={{xs:"100%",md:"28%"}}>
                 <Typography data-aos="fade-up" variant='h1' 
                            color='primary'
                             sx={{...typographyFont.h2,fontWeight:800,padding:'1rem'}}>
                    Transformez chaque appel en opportunité de succès.
                  </Typography>
                </Box>
              </Stack>
               {/* 2em content */}
              <Stack gap={{sm:6,xs:2}}
                     direction={{ xs: 'column-reverse', sm: 'row',md:'row'}}
                    //  spacing={{ xs: 1, sm: 2, md: 30 }}
                     >
                <Box data-aos="fade-right"  sx={{padding:{sm:'6rem 2rem 1rem',xs:3}}}
                      width={{xs:"100%",sm:"35%"}}>
                  <Typography variant='h3'my={2} color='primary'> Choisissez votre stratégie gagnante.</Typography>
                  <Typography component="p" color='primary'>
                    On vend de la campagne  téléphonique efficaces, adaptées à vos objectifs : 
                    que ce soit pour attirer de nouveaux clients ou fidéliser les existants,
                    nos solutions vous garantissent des résultats mesurables. 
                  </Typography>
                  <Button data-aos="fade-up" sx={{marginY:2}} variant='contained' >Voir plus</Button> 
                </Box>
                <Stack data-aos="fade-up-left" direction={{ xs: 'column', sm: 'row' }} 
                       spacing={{ xs: 1, sm: 2, md: 2 }}
                       sx={{display:{xs:'flex'},alignItems:{xs:'center'}, 
                            padding:{md:'1rem',xs:2} ,borderRadius:'1rem',
                            marginTop:{xs:0,sm:-10},
                            marginLeft:{xs:0,sm:-9}
                          }}
                          width={{xs:"100%",sm:"60%"}}>
                  <Box  sx={{display:'flex'}}>
                      <PhoneForwardedIcon color='primary' sx={{fontSize:'3rem',marginTop:{xs:0,sm:3}}}/>
                      <Typography color='primary' component="p" sx={{...typographyFont.h3,fontWeight:600,paddingTop:{xs:0,md:3}}} >
                        ou sortant?
                      </Typography>
                  </Box>
                  <Box  component='img' 
                        sx={{background:'whiteSmoke',
                              opacity:0.95,
                              borderRadius:'1rem',
                              width:{xs:250,sm:350},height:{xs:200,sm:250},
                              marginTop:{xs:0,sm:-2},
                              zIndex:{xs:0,sm:3}
                            }}
                        src={callcuate}
                       alt="mode gratuit"/>
                </Stack>
              </Stack>

            </Grid2>
        </Box>
        </>
    )
}