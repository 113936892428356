import Cookie from 'js-cookie';
import SSRCookie from 'cookie';
import {
  AUTH_CRED, EMAIL_VERIFIED,
  PERMISSIONS,
  CLIENT,
  SUPER_ADMIN,
  ADMIN,
  TOKEN,
  COOKIE_ACCEPTED,
} from './constants';

export const allowedRoles = [SUPER_ADMIN, CLIENT];
export const adminAndOwnerOnly = [SUPER_ADMIN, ADMIN];
export const adminOwnerAndStaffOnly = [SUPER_ADMIN, ADMIN, CLIENT];
export const adminOnly = [SUPER_ADMIN];
export const ownerOnly = [ADMIN];
export const ownerAndStaffOnly = [ADMIN, CLIENT];

export function setAuthCredentials(token: string, permissions: any) {
  Cookie.set(AUTH_CRED, JSON.stringify({ token, permissions }));

}

export function setTokenAccepted(cookieAccepted: boolean) {
  Cookie.set(COOKIE_ACCEPTED, JSON.stringify({cookieAccepted}));

}

export function getTokenAccepted(): {
  cookieAccepted: boolean | undefined;
} {
  const cookieAccepted = Cookie.get(COOKIE_ACCEPTED);
  return cookieAccepted ? JSON.parse(cookieAccepted) : false;
}

export function setEmailVerified(emailVerified: boolean) {
  Cookie.set(EMAIL_VERIFIED, JSON.stringify({ emailVerified }));
}


export function getEmailVerified(): {
  emailVerified: boolean;
} {
  const emailVerified = Cookie.get(EMAIL_VERIFIED);
  return emailVerified ? JSON.parse(emailVerified) : false;
}

export function getAuthCredentials(context?: any): {
  token: string | null;
  permissions: string[] | null;
} {
  let authCred;
  if (context) {
    authCred = parseSSRCookie(context)[AUTH_CRED];
  } else {
    authCred = Cookie.get(AUTH_CRED);
  }
  if (authCred) {
    return JSON.parse(authCred);
  }
  return { token: null, permissions: null };
}

export function parseSSRCookie(context: any) {
  return SSRCookie.parse(context.req.headers.cookie ?? '');
}

export function hasAccess(
  _allowedRoles: string[],
  _userPermissions: string[] | undefined | null
) {
  if (_userPermissions) {
    return Boolean(
      _allowedRoles?.find((aRole) => _userPermissions.includes(aRole))
    );
  }
  return false;
}
export function isAuthenticated(_cookies: any) {
  return (
    !!_cookies[TOKEN] &&
    Array.isArray(_cookies[PERMISSIONS]) &&
    !!_cookies[PERMISSIONS].length
  );
}

export function  getPermission(){

  const { permissions } = getAuthCredentials();
 return {
  isAdmin : permissions?.includes(SUPER_ADMIN),
  isClient : permissions?.includes(CLIENT),
 }
}


