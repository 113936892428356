// import React, { ReactNode } from 'react';

import React from 'react';

type Props = {
    iconList: any;
    iconName: any;
    [key: string]: unknown;
  };
  export const getIcon = ({ iconList, iconName, ...rest }: Props) => {
    const TagName = iconList[iconName];
    return TagName ? (
      <React.Fragment>
       <TagName {...rest} />
     </React.Fragment>
    ) : (
      <p className="text-sm text-red-500">{iconName} is not a valid icon</p>
    );
  };
