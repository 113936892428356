import { Box, Grid2 } from '@mui/material';
import PageContainer from '../layout/container';
import TypographyResponsive from '../ui/Typography/responsive';


export function NotFound({text}:{text:string}){

return(
    <PageContainer sx={{ display:'flex',justifyContent:'center'}}>
          <Grid2 container direction="column" sx={{ marginY:5}}>
              <Grid2>
                  <Box component="img"
                    src="/illustration/yellow/404 error lost in space-pana.png"
                    width="100%" height={{xl:650,lg:400,md:500,sm:450,xs:400}}/>
              </Grid2>
                <Grid2 sx={{ marginX:'auto'}}>
                  <TypographyResponsive variant='h1'  sx={{fontWeight:"900"}} >404: {text}</TypographyResponsive>
                </Grid2>
          </Grid2>
    </PageContainer>
)
}