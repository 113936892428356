import { useTypePlateformsQuery } from '../../../data/type plateform'
import { ListeProps } from '../../../types/mui'
import AutoComplete from '../../ui/input/autocomplete'

interface TypeplateformInputProps extends ListeProps {
    initialValue?: { id_type: string; nom_type: string } | null;
  }
export function TypePlateformInput({setSelected,initialValue}:TypeplateformInputProps){

    const { typePlateforms } = useTypePlateformsQuery({})

    return(
        <>
        <AutoComplete
              text="nom_type"
              items={typePlateforms}
              value={initialValue}
              label="Type de plateforme"
              name="typePlateform"
              sx={{mt:2}}
              setSelected={setSelected}
              />
        </>
    )
}