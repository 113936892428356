import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'; // Import React Router link
import  PropTypes  from 'prop-types';

interface LinkProps {
    to:string;
    text:string;
}
const Links:React.FC<LinkProps> = ({text,to})=>{

    return(
        <>
          <Link color='secondary' component={RouterLink} to={to} underline="hover">
          {text}
          </Link>
        </>
    )
}

Links.propTypes ={
    text:PropTypes.string.isRequired,
    to:PropTypes.string.isRequired,
}
export default Links