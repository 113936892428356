import { Box,Grid2, Typography, useTheme } from '@mui/material';
import { tokens, typographyFont } from '../../../theme';
import TruncatedTextCard from '../../ui/Typography/description';
import Links from '../../ui/Typography/link';
import { useTypeFormationsQuery } from '../../../data/type formation';
import Logo from '../../ui/image/logo';
import { TypeFormation } from '../../../types';
import { useEffect } from 'react';
import AOS from 'aos'

 export default function ListeFormations () {
    useEffect(() => {
        AOS.init({
          duration: 1000, 
          once: false, 
        });
      }, []);
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const { typeFormations } = useTypeFormationsQuery()
   
   
    return ( 
        <>
            <Box width='100%' sx={{background:colors.primary[500],paddingBlock:3}}>
                <Typography  variant='h1'
                            sx={{textAlign:'center',...typographyFont.h2,fontWeight:900,}}>
                    Nos Formations
                </Typography>
                <Grid2  direction={{xs:'column',sm:'row',md:'column'}} sx={{display:'flex',justifyContent:'center'}} container  spacing={1}>
                    {/* trading */}
                    {Array.isArray(typeFormations)&& typeFormations.map((formation:TypeFormation, index:number) => ( 
                    <Grid2  key={formation.id_type}  direction={index%2!==0?"row-reverse":"row"}
                            bgcolor={index%2!==0 ? colors.primary[600] : ""} 
                            sx={{justifyContent:'space-around', width:'auto' }} 
                            padding={2} container >
                            
                        <Grid2 padding={{xs:0,md:1}} size={{xs: 12,md:4}}>
                            <Box data-aos="fade-right" >
                                <Logo height={240} width="100%" logo={String(formation?.image)}/>
                            </Box>
                        </Grid2>

                        <Grid2 padding={{xs:0,md:1}} color="primary" size={{xs:12,md:8}}>
                            <Typography data-aos="fade-right" component="h1" sx={{...typographyFont.h3}}> {formation.nom_type}</Typography>
                            <TruncatedTextCard text={formation.description} link={`/formations/detail/${formation.slug}`} />
                            
                        <Typography data-aos="fade-up" component="p" style={{paddingBlock:10,marginBlock:2,gap:3}}>
                            <Typography>Durée de la formation: entre 3jours  à 21jours </Typography>
                            <Typography>Prix: entre 10 000 Ariary  à 60 000 Ariary</Typography>
                        </Typography>
                        <Typography component="p">
                            <Links text="Liste des formations disponibles" to={formation.slug} />
                        </Typography>
                        </Grid2>   
                    </Grid2>
                        ))}
                    

                </Grid2>
            </Box>
        </>
    );
}

