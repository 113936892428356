
import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useUpdateDisponibleMutation, useVenteQuery } from '../../../data/vente';
import Logo from '../../ui/image/logo';
import { toMoney } from '../../../utils/transform';
import { SEO } from '../../Seo';
import { NotFound } from '../../not-found/not-found';
import { TypePaiement } from '../../../types';
import { useCreatePaiementMutation } from '../../../data/paiement';
import { useCreateReservationMutation } from '../../../data/reservation';
import { useNavigate } from 'react-router-dom';
import ReservationForm from '../reservation/formulaire';
 interface IProps {
  slug:string
}

const ReservationVente: React.FC<IProps> = ({slug}) => {

const navigate= useNavigate()
const { data,isLoading } = useVenteQuery({slug:slug})
const { mutate: createPaiement ,isLoading:loading} = useCreatePaiementMutation()
const { mutate: createReservation } = useCreateReservationMutation()
const { mutate: updateVente } = useUpdateDisponibleMutation()

const [form, setForm] = useState({
  nom: '',
  email: '',
  reference:""
});
const [typePaiement,setTypePaiement] = useState<TypePaiement | null>(null)

function handleReserver(event:React.FormEvent<HTMLFormElement>){
  event.preventDefault(); 
  createPaiement({
    reference: String(form.reference),
    montant: Number(data?.prix),
    type_paiement: String(typePaiement?.id_type),
    status: ''
  },{
    onSuccess: (paiement:any)=>{
      createReservation({
        vente_id:String(data?.id_vente),
        paiement_id:paiement?.data.id_paiement
  },{
    onSuccess: ()=>{
      updateVente({id:String(data?.id_vente),is_disponible:false},{
        onSuccess:()=>{
          navigate('/ventes')
        }
      })
      
  }
  })
  }
  })
}

 if(!data && !isLoading){
  return (
      <>
        <SEO title='404: Contenue introuvable' />
        <NotFound text="Contenu introuvable" />
    </> 
    )
 }

  return (
   data && (<Box component='div' >
      <Container component="main" maxWidth="lg" >
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%',padding:{xl:6},}}>
          <Grid size={{xs:12, md:6}} display="flex" justifyContent="center">
            <Stack direction="column">
                
                <Logo logo={String(data?.image)} width="100%" height={300} />
              
                <Box sx={{padding:3}} component="div">
                <Typography variant="h3" sx={{fontWeight:600}}>
                 {data?.plateforme.nom_plateforme}
                </Typography>
                <Typography component="p" dangerouslySetInnerHTML={{ __html: data ? data?.description : "" }}  />
                  <Typography component="p" sx={{paddingTop:3}}>
                      Prix :
                    <Typography component="span" sx={{fontWeight:600}}>
                      {toMoney(data?.prix)}
                    </Typography>
                  </Typography>
                  <Typography component="p" sx={{paddingY:1}}>
                      Delais de livraison :
                    <Typography component="span" sx={{fontWeight:600}}>
                      {data?.delais} jour(s)
                    </Typography>
                  </Typography>
                </Box>
            </Stack>
          </Grid>
          <Grid size={{xs:12, md:6 }} paddingY={3} >
            <ReservationForm form={form} setForm={setForm}
                            typePaiement={typePaiement}
                            setTypePaiement={setTypePaiement}
                            handleReserver={handleReserver} 
                            loading={loading}/>
          </Grid>
        </Grid>
      </Container>
    </Box>)
  );
};

export default ReservationVente;