import { Box, colors, Grid2, Link, List, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { typographyFont } from '../../theme';

export default function Footer() {
  const qr = `${process.env.PUBLIC_URL}/illustration/QR-code-whatsapp/qr-gasy.PNG`;
  const links = [
    { id: 1,titre:'Pour le client',desc:'Suivre une formation',url:'/formations' },
    { id: 2,desc:'Service dévéloppement web',url:'/service/developpement-application' },
    { id: 4,desc:'Création compte freelance',url:'/service/creation-compte-freelance' },
    { id: 5,desc:'Création portefeuille élécronique',url:'/service/creation-portefeuille-electronique' },
    { id: 6,desc:'Vente compte  freelance' ,url:'/ventes'},
    { id: 7,desc:'Vente campagne téléponique',url:'' },
    { id: 8,desc:'Récrutement',url:'/emploies' },
  ];

  const links2 = [
    { id: 1,titre:'',desc:'Aides & supports',url:'' },
    { id: 2,desc:'Témoignages',url:'' },
    { id: 4,desc:'Ressources',url:'' },
    { id: 5,desc:'Blog',url:'' },
    { id: 6,desc:'Comunautés',url:'' },
    
  ];
  const links3 = [
    { id: 1,titre:'',desc:'Apropos',url:'' },
    { id: 2,desc:'Carrières',url:'' },
    { id: 4,desc:'Nos impacts',url:'' },
    { id: 5,desc:'Partenaires' ,url:''},
    { id: 6,desc:' Contactez-nous',url:'' },
    { id: 7,desc:'Vente campagne téléponique',url:'' },
    { id: 8,desc:'Confiance & sécurité',url:'' },
  ];

    return(
        <>
          <Box width='100%'  sx={{background:'#424242',borderTopLeftRadius:'1rem',borderTopRightRadius:'1rem',}}>
            <Grid2 container justifyContent={'space-around'} direction={{ xs: 'column', sm: 'row' }}spacing={{ xs: 1, sm: 1, md: 2 }}>
              <Grid2 size={{xs:12,sm:4}} >
                <List sx={{display:'flex',flexDirection:'column',gap:'0.5rem', color:'white',padding:{md:'3rem',sm:4,xs:2}}}>
                  <h2>Pour le client</h2>

                  {links.map(link => (
                  <Link key={link.id} sx={{color:colors.grey[50],'&:hover': {color:colors.green[900]}}} href={link.url}>{link.desc}</Link>
                    ))}
                </List>
                </Grid2>

                <Grid2 size={{xs:12,sm:4}}>
                <List sx={{display:'flex',flexDirection:'column',gap:'0.5rem', color:'White',padding:{md:'3rem',sm:4,xs:2}}}>
                  <h2> Ressources</h2>

                  {links2.map(link => (
                  <Link key={link.id} sx={{color:colors.grey[50],'&:hover': {color:colors.green[900]}}} href={link.url}>{link.desc}</Link> 
                    
                    ))}

                </List>
                </Grid2>

                <Grid2 size={{xs:12,sm:4}}>
                  <List sx={{display:'flex',flexDirection:'column',gap:'0.5rem', color:'white',padding:{md:'3rem',sm:4,xs:2}}}>
                    <h2>Entreprise</h2>

                    {links3.map(link => (
                    <Link key={link.id} sx={{color:colors.grey[50],'&:hover': {color:colors.green[900]}}} href={link.url}>{link.desc}</Link>
                      ))} 
                  </List>
                </Grid2>
            </Grid2>
          
           <Box>
              <Box component="div" sx={{display:'flex', gap:'2rem' ,padding:{sm:' 1rem 3rem',xs:2}}}>
                <Typography variant='h2' style={{color:'white'}}>Suivez-nous</Typography>
                <Box className='spanIcon' sx={{ color:'white',display:{xs:'block',md:'flex'}, gap:'0.4rem', fontSize:{xs:2,md:3}}}>
                  <span>
                  <Link sx={{color:colors.grey[50],'&:hover': {color:colors.green[900]}}}
                  href="https://www.facebook.com/profile.php?id=61552974937274"><FacebookIcon sx={{fontSize:'2.5rem'}}/></Link>

                  <Link sx={{color:colors.grey[50],'&:hover': {color:colors.green[900]}}}
                  href="https://www.linkedin.com/"><LinkedInIcon sx={{fontSize:'2.5rem'}}/></Link>
                  
                  </span>
                  <Link sx={{color:colors.grey[50],'&:hover': {color:colors.green[900]}}}
                  href="https://www.youtube.com/@misedramickael"><YouTubeIcon sx={{fontSize:'2.5rem'}}/></Link>
                  
                  <Link sx={{color:colors.grey[50],'&:hover': {color:colors.green[900]}}}
                  href={qr}><WhatsAppIcon sx={{fontSize:'2.5rem'}}/></Link>
                </Box>
              </Box>
              <Typography component="span" sx={{...typographyFont.body2,margin:{sm:'3rem',xs:4},color:'white',paddingBottom:5}}>© 2018-2024 Gasy M’ketrika Online™</Typography>
           </Box>
         </Box>
     </>
    )
}