import { useState } from 'react'
import { useCreateTypePlateformMutation, useUpdateTypePlateformMutation } from '../../../data/type plateform'
import { TypePlateform } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import { Box, Button, Grid2 as Grid, Typography } from '@mui/material'
import { typographyFont } from '../../../theme'
import Input from '../../Input'
import ButtonLoading from '../../ui/button/button-loading'
import TextEditor from '../../ui/input/text-editor'

export const TypePlateformForm = ({defaultValues}:{defaultValues:TypePlateform}) => {

    const { mutate: createType, isLoading: loading } = useCreateTypePlateformMutation()
    const { mutate: updateType, isLoading } = useUpdateTypePlateformMutation()
    const update = Boolean(defaultValues?.nom_type)
    
    const { closeModal } = useModalAction()
  
    const [typePlateform, setTypePlateform] = useState(defaultValues.nom_type);
    const [description, setDescription] = useState(defaultValues.description);  
      const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
        
        if(update){
          updateType({
            id: String(defaultValues?.id_type),
            description: description,
            nom_type: typePlateform
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
            nom_type: typePlateform,
            description: description
  
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
  
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
          <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire Type de Plateform</Typography>
           <Grid container> 
                <Input name='typeformation'
                      placeholder='Type Plateform'
                      type='text' required 
                      value={typePlateform}
                      onChange={(e) => setTypePlateform(e.target.value)}
                />
                {/* <Input name='description'
                      placeholder='Description'
                      type='text' required 
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                /> */}
                <TextEditor editorContent={description} setEditorContent={setDescription}/>
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid> 
            </Grid>
        </Box>
    )
  }