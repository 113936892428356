import { Box } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { toolbarOptions } from '../../../types';

interface IProps{
    editorContent:string;
    setEditorContent:React.Dispatch<React.SetStateAction<string>>
}

export default function TextEditor({editorContent, setEditorContent}:IProps){
    
    const handleChange = (value:string) => {
        setEditorContent(value);
      };

    return(
        <Box sx={{mb:2,zIndex: 3,width:'100%'}} >
             <ReactQuill modules={{toolbar:toolbarOptions}}
               value={editorContent}
               onChange={handleChange} 
               className="custom-quill-editor"
               style={{overflowY:"auto",maxHeight:200}} />
        </Box>
    )
}