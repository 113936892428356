import React from 'react'
import { useDeletePlateformMutation, usePlateformsQuery } from '../../../data/plateform'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { Box, Typography } from '@mui/material'
import { Plateforme } from '../../../types'
import Links from '../../ui/Typography/link'

export default function PlateformList(){

    const { plateforms } = usePlateformsQuery({})
   
    const headers =[
      {name:"Logo",value:"logo",
        template: function Render(record: any){
          return(
            <>
              <Box component="img"
                  src={JSON.parse(record?.logo)?.thumbnail}
                  width={35} height={35} />
            </>
          )}
      },
      {name:"Nom de plateforme",value:"nom_plateforme"},
      {name:"Type de plateforme",value:"type_plateforme",
        template:(record:Plateforme)=>{
          return(
            <Typography>{record.type_plateforme.nom_type}</Typography>
          )
        }
      },
      {name:"Lien",value:"link",
        template:(record:Plateforme)=>{
          return(
            <Links to={record.link} text={record.link} />
          )
        }
      },
      {name:"Description", value:"description"},
      {name:"Actions",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_plateforme,
                  deleteMutation:useDeletePlateformMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.nom_plateforme}</b> de la liste des plateforms ?`,
                  ...record
                }}
                detailLink={`/admin/plateforme/${record?.id_plateforme}`} 
                deleteModalView='DELETE'
                editModalView="ADD_PLATEFORM"
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={plateforms} headers={headers}/>
      </React.Fragment>
    )
  }
  

  