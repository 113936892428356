import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Input from '../../components/Input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ButtonLoading from '../ui/button/button-loading';
import {  getAuthCredentials, getPermission, isAuthenticated, setAuthCredentials } from '../../utils/auth-utils';
import { useLogin } from '../../data/user';
import { getFormErrors } from '../../api/http-clients';
import { useSnackbarAction } from '../ui/snackbar/snackbar-context';

const LoginForm = () => {

  const { openSnackbar } = useSnackbarAction();
  
  const { token, permissions } = getAuthCredentials();
  const { isAdmin ,isClient } = getPermission()

  const { mutate: login, isLoading } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const from = location.state?.from?.pathname ? location.state?.from?.pathname : isAdmin ? '/admin/dashboard' : "/";
  

  const handleLogin = () => {
    login(
      { email, password },
      {
        onSuccess: (data) => {
          if (data?.token) {
            setAuthCredentials(data.token, data.permissions);
  
            if (data.permissions.includes('super_admin')) {
              navigate('/admin/dashboard');
            } else if (data.permissions.includes('client')) {
              navigate('/accueil');
            } else {
              openSnackbar("Vous n'avez pas de permission", 'warning');
            }
          } else {
            openSnackbar("Email ou mot de passe incorrecte!", 'error');
          }
        },
        onError: (error) => {
          openSnackbar(getFormErrors(error), 'error');
        },
      }
    );
  };
  
  useEffect(() => {
    if (isAuthenticated({ token, permissions })) {
      if (isAdmin||isClient) {
        navigate(from,{replace:true});
      } else {
        openSnackbar("Vous n'avez pas de permission", 'warning');
      }
    }
  }, [token, permissions, navigate]);
  return (
    <Box component="form" sx={{ zIndex: 3 }}>
      <Input
        name='email'
        placeholder="Email ou nom d'utilisateur"
        type='text'
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input 
        name='password'
        placeholder="Mot de passe"
        type='password'
        value={password}
        required
        onChange={(e) => setPassword(e.target.value)}
      />
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: 2 }}>
        <FormControlLabel
          control={<Checkbox value="remember" color="secondary" />}
          label="Se souvenir"
          sx={{
            '& .MuiFormControlLabel-label': {
              color: 'white',
              fontSize: { xl: 20 }
            },
          }}
        />
        <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
          <Typography fontSize={{ xl: 20 }} variant="body2" sx={{ color: '#00BFFF' }}>
            Mot de passe oublié ?
          </Typography>
        </Link>
      </Box>

      <ButtonLoading
        variant="contained"
        label=' Se connecter'
        loading={isLoading}
        handleClick={handleLogin}
        color='secondary'
      />
    </Box>
  );
};

export default LoginForm;