import { useState } from 'react'
import { useCreateTypeServiceMutation, useUpdateTypeServiceMutation } from '../../../data/type service'
import { TypeService } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import { Box, Button, Grid2 as Grid, Typography } from '@mui/material'
import { typographyFont } from '../../../theme'
import ButtonLoading from '../../ui/button/button-loading'
import Input from '../../Input'
import TextEditor from '../../ui/input/text-editor'

export const TypeServiceForm = ({defaultValues}:{defaultValues:TypeService}) => {

    const { mutate: createType, isLoading: loading } = useCreateTypeServiceMutation()
    const { mutate: updateType, isLoading } = useUpdateTypeServiceMutation()
    const update = Boolean(defaultValues?.nom_type)
    
    const { closeModal } = useModalAction()
     
    const [typeService, setTypeService] = useState(defaultValues.nom_type);
    const [description, setDescription] = useState(defaultValues.description);  
   
    // console.log(update)

    const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
        console.log('Type service:',typeService,'','description:',description)  
        if(update){
          updateType({
            id: String(defaultValues?.id_type),
            description:description,
            nom_type:typeService
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
            nom_type: typeService,
            description: description
  
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
  
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
          <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire Type de Service</Typography>
           <Grid container> 
                <Input name='typeservice'
                      placeholder='Type Service'
                      type='text' required 
                      value={typeService}
                      onChange={(e) => setTypeService(e.target.value)}
                />
                {/* <Input name='description'
                      placeholder='Description'
                      type='text' required 
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                /> */}
                <TextEditor editorContent={description} setEditorContent={setDescription}/>
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid> 
            </Grid>
        </Box>
    )
  }