import { Box, Button, Divider, Grid2, Stack, Typography, useTheme } from '@mui/material';
import { tokens, typographyFont } from '../../../theme';

export default function FindGoodjob() {

  
    const Hiring = `${process.env.PUBLIC_URL}/illustration/yellow/Hiring-rafik-yellowi.png`;
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)


    return(
        
        <>  
            <Stack sx={{color:'White',textAlign:'center',background:'white',paddingY:2}}>
            <Box  sx={{background: colors.green[500],margin:'1rem',borderRadius:'1rem',textAlign:'start'}}>
                <Grid2 container justifyContent='center' alignItems='center'  direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 1, md: 3 }}>
                    <Grid2 size={{xs:12,sm:8,md:6,lg:8}}>
                      <Box data-aos="fade-right" component='img' height={{xs:350,sm:900,md:1100,lg:900}} width={{xs:350,sm:500,md:800,lg:800}}  src={Hiring} alt="image-hearing" />
                    </Grid2>
                    <Grid2 data-aos="fade-up" size={{xs:12,sm:6,md:3}} padding={2}>
                        <Typography  sx={{...typographyFont.h1,fontWeight:900,lineHeight:'1.3'}}>
                            Trouver du  bon travail
                      </Typography>
                      <Typography data-aos="fade-up" component="p" sx={{...typographyFont.h5}}>
                            Restez connecté à l'actualité qui vous intéresse et ne ratez plus aucune opportunité.
                      </Typography>
                      <Grid2 sx={{marginTop:{xs:4,sm:4,md:20} }}>
                        <Divider orientation='horizontal'  color="white" />
                        <Box   sx={{display:'flex',gap:'2rem',padding:2}}>
                          <Typography data-aos="fade-up" component="span" sx={{...typographyFont.h6}}>
                            Trouvez des opportunités pour chaque étape de votre carrière freelance
                          </Typography>
                        <Typography data-aos="fade-left" component="span" sx={{...typographyFont.h6}}>
                            Aimeriez -vous travailler  avec nous dans nos locaux ?
                          </Typography>
                        </Box>
                      </Grid2>
                        <div style={{margin:'2rem',textAlign:'center',width:"80%"}}> 
                          <Button data-aos="fade-right" sx={{background:'white',color:'green',padding:'1rem'}} 
                                  variant='contained' 
                                  fullWidth
                                  href='/emploies'>
                              Trouver des opportunités
                          </Button>
                        </div>
                    </Grid2>
                </Grid2>
            </Box>
            </Stack>
        </>
    )
}