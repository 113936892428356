import { useTypePaiementsQuery } from '../../../data/type paiement'
import { ListeProps } from '../../../types/mui'
import AutoComplete from '../../ui/input/autocomplete'

interface TypePaimentInputProps extends ListeProps {
    initialValue?: { id_type: string; nom_type: string } | null;
  }

export function TypePaiementInput({setSelected, initialValue}:TypePaimentInputProps){

    const { typePaiements } = useTypePaiementsQuery({})

    return(
        <>
        <AutoComplete
              text="nom_type"
              items={typePaiements}
              value={initialValue}
              label="Type de paiement"
              name="typePaiement"
              sx={{mt:2}}
              setSelected={setSelected}
              />
        </>
    )
}