import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/routes';
import usePathName from './utils/router';
import ThemeRegistry from './ThemeRegistry';
import Providers from './utils/providers';
import AppBarDrawer from './components/layout/appBar';
// import DashboardLayoutBasic from './components/admin/Dashboard';
import { ModalProvider } from './components/ui/modal/modal.context';
import PrivateRoute from './utils/private-route';
// import PageContainer from './components/layout/container';
import ManagedModal from './components/ui/modal/managed-modal';
import { Cookies } from './components/ui/cookies/site.cookie';
import { SnackbarProvider } from './components/ui/snackbar/snackbar-context';
import ManagedSnackbar from './components/ui/snackbar/managed-snackbar';

const noNavBarPaths = [
    '/login', '/register', '/forgot-password', 
    '/reset-password', '/verification-password', 
    '/verification-password2', '/relogin', '/success-create-account'
];

// const adminPaths = [
//     '/type-paiements',
//     '/type-plateformes',
//     '/type-services',
//     '/type-formations',
//     '/type-ventes',
//     '/formations',
//     '/paiements',
//     '/plateformes',
//     '/services',
//     '/ventes'
    
// ];

// const showNavBarPaths = ['/'];

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const pathName = usePathName();
    
    const noNavBar = noNavBarPaths.includes(pathName)

    const isAdminPath = pathName.includes('admin');

    //list of pages who need login before enter in its 
    const pathWithConnexion = ['']
    const withConnexion = pathWithConnexion.includes(pathName)

    if(!noNavBar) {
      
            if (!withConnexion && !isAdminPath){
            
                return (
                        <AppBarDrawer > 
                          { children }
                  {/* /Cookie de la siteweb */}
                          <Cookies />
                        </AppBarDrawer> 
                        )     
            }
            
        return (
            <>
                <PrivateRoute >
                    <AppBarDrawer > 
                        { children }
                    </AppBarDrawer> 
                </PrivateRoute>
            </>
            )
            
        }

      return(
            <>
              { children }
            </>
         )
    }
 

function App(){


  return(
    <>
    <React.StrictMode>
        <BrowserRouter>
           <Providers>
              <ModalProvider>
                <SnackbarProvider>
                    <ThemeRegistry options={{ key: 'mui' }} >
                            <Layout>
                                {/* <PageContainer> */}
                                    <AppRoutes />
                                {/* </PageContainer> */}
                            </Layout>
                        <ManagedModal />
                        <ManagedSnackbar />
                    </ThemeRegistry>
                </SnackbarProvider>
              </ModalProvider>
           </Providers>
        </BrowserRouter>
    </React.StrictMode>
    </>
  )
}

export default App;