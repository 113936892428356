import { Grid2 } from '@mui/material';
import VenteCard from './card';
import { useVentesQuery } from '../../../data/vente';



export default function ListeVente(){
   

      const {ventes} = useVentesQuery({is_disponible:true})
     
    return(
    <>
         <Grid2  padding={{xs:4,md:'2rem 4rem',xl:5}} justifyContent='center' container spacing={2}>

         {Array.isArray(ventes) && ventes.map((card: any) => 
                    <Grid2   key={card.id} size={{xs:12,md:3,xl:2}}>
                        <VenteCard item={card} />
                    </Grid2>
                )}
        </Grid2>
    </>
    )
}