import { Routes } from '../config/routes';
import { adminAndOwnerOnly, adminOwnerAndStaffOnly } from '../utils/auth-utils';



export const siteSettings = {
  name: 'Etady',
  description: '',
  logo: {
    url: '/logo/e-tady-transparent.png',
    alt: 'Etady',
    to: '/',
    width: 128,
    height: 40,
  },
  defaultLanguage: 'en',
  author: {
    name: 'RedQ, Inc.',
    websiteUrl: 'https://redq.io',
    address: '',
  },
  headerLinks: [],
  authorizedLinks: [
    {
      to: Routes.profileUpdate,
      labelTransKey: 'authorized-nav-item-profile',
    },
    {
      to: Routes.logout,
      labelTransKey: 'authorized-nav-item-logout',
    },
  ],
  currencyCode: 'USD',
  sidebarLinks: {
    profile:[
      {
        text:"Votre profile",
        icon:"HomeIcon",
        to:"/my-profile"
      },
    //   {
    //     text:"Vos propriétés",
    //     icon:"HomeIcon",
    //     to:"/locations"
    // },
    //     {
    //       text:"Locations",
    //       icon:"HomeIcon",
    //       to:"/locations"
    //   },
    //     {
    //       text:"Réservations",
    //       icon:"RequestQuote",
    //       to:"/reservations"
    //   },
    //     {
    //       text:"Visites",
    //       icon:"Visibility",
    //       to:"/visites"
    //     },
    //     {
    //       text:"Vos avis",
    //       icon:"Reviews",
    //       to:"/reviews"
    //     },
        {
          text:"Vos favoris",
          icon:"Favorite",
          to:"/favorites"
        },
        {
          text:"Adresses",
          icon:"Room",
          to:"/address"
        },
        // {
        //   text:"Questions",
        //   icon:"ReceiptIcon",
        //   to:"/questions"
        // },
        // {
        //   text:"Remboursement",
        //   icon:"CurrencyExchange",
        //   to:"/refunds"
        // },
        {
          text:"Vos moyens de paiement",
          icon:"Money",
          to:"/cards"
        },
        {
          text:"Sécurité du compte",
          icon:"AdminPanelSettings",
          to:"/settings"
      },
      {
        text:"Vos abonnements",
        icon:"Subscriptions",
          to:"/subscriptions"
    },

    ],
    admin: [
      {
        text:"Dashboard",
        icon:"Home",
        to:"/dashboard"
      },
      // {
      //   text:"Calendrier",
      //   icon:"CalendarMonth",
      //   to:"/calendar"
      // },
      // {
      //   text:"Ressources Humaines",
      //   icon:"People",
      //   to:"/users"
      // },
      {
        text:"Réservation",
        icon:"AccountTree",
        to:"/reservation"
      },
      {
        text:"Candidat",
        icon:"DirectionsRun",
        to:"/candidats"
      },
      {divider:true},
      {
        text:"Paiments",
        icon:"Payment",
        to:"/paiements"
      },
      {
        text:"Clients ",
        icon:"Person",
        to:"/clients"
      },
      {
        text:"Admin ",
        icon:"ManageAccounts",
        to:"/admins"
      },
      {
        text:"Formations ",
        icon:"CastForEducation",
        to:"/formations"
      },
      {
        text:"Services ",
        icon:"ListAlt",
        to:"/services"
      },
      {
        text:"Ventes",
        icon:"Storefront",
        to:"/ventes"
      },
      {
        text:"Emploies ",
        icon:"Work",
        to:"/emploies"
      },
      {
        text:"Plateformes ",
        icon:"Language",
        to:"/plateformes"
      },
      {divider:true},
      {
        text:"Type de formation ",
        icon:"School",
        to:"/type-formations"
      },
      {
        text:"Type de service ",
        icon:"HistoryEdu",
        to:"/type-services"
      },
      {
        text:"Type de vente ",
        icon:"Shop2",
        to:"/type-ventes"
      },
      {
        text:"Type de paiement ",
        icon:"MonetizationOn",
        to:"/type-paiements"
      },
      {
        text:"Type de plateforme ",
        icon:"Cloud",
        to:"/type-plateformes"
      },
],
    agence: [
      {
        to: (agence: string) => `${Routes.dashboard}${agence}`,
        text:"Bilan ",
        icon:"BarChartIcon",
        permissions: adminOwnerAndStaffOnly,
      },
      // {
      //   to: (agence: string) => `/${agence}${Routes.attribute.list}`,
      //   label: 'sidebar-nav-item-attributes',
      //   icon: 'AttributeIcon',
      //   permissions: adminOwnerAndStaffOnly,
      // },
      {
        to: (agence: string) => `/${agence}${Routes.propriete.list}`,
        text:"Propriétés",
        icon:"AccountTree",
        permissions: adminOwnerAndStaffOnly,
      },
      {
        to: (agence: string) => `/${agence}${Routes.staff.list}/all`,
        text:"Ressources Humaines",
        icon:"PeopleIcon",
        permissions: adminAndOwnerOnly,
      },
      // {
      //   to: (agence: string) => `/${agence}${Routes.exchange.list}`,
      //   label: 'sidebar-nav-item-exchanges',
      //   icon: 'ProductsIcon',
      //   permissions: adminOwnerAndStaffOnly,
      // },
      {
        to: (agence: string) => `/${agence}${Routes.appartement.list}`,
        text:"Appartements ",
        icon:"LocationCity",
        permissions: adminAndOwnerOnly,
      },
      {
        to: (agence: string) => `/${agence}${Routes.chambres.list}`,
        text:"Chambres ",
        icon:"Bed",
        permissions: adminAndOwnerOnly,
      },
      {
        to: (agence: string) => `/${agence}${Routes.terrains.list}`,
        text:"Terrains ",
        icon:"Room",
        permissions: adminOwnerAndStaffOnly,
      },
      {
        to: (agence: string) => `/${agence}${Routes.refund.list}`,
        text:"Remboursement",
        icon:"CurrencyExchange",
        permissions: adminOwnerAndStaffOnly,
      },
      // {
      //   to: (agence: string) => `/${agence}${Routes.withdraw.list}`,
      //   label: 'sidebar-nav-item-withdraws',
      //   icon: 'AttributeIcon',
      //   permissions: adminAndOwnerOnly,
      // },
      {
        to: (agence: string) => `/${agence}${Routes.reviews.list}`,
        text:"Avis",
        icon:"Reviews",
        permissions: adminAndOwnerOnly,
      },
      {
        to: (agence: string) => `/${agence}${Routes.question.list}`,
        text:"Questions",
        icon:"ReceiptIcon",
        permissions: adminAndOwnerOnly,
      },
      // {
      //   to: (agence: string) => `/${agence}${Routes.storeNotice.list}`,
      //   label: 'sidebar-nav-item-store-notice',
      //   icon: 'StoreNoticeIcon',
      //   permissions: adminAndOwnerOnly,
      // },
    ]
  }
}
