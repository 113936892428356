import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useUpdateUserMutation } from '../../data/user'; 
import Input from '../Input';
import ButtonLoading from '../ui/button/button-loading';

const ProfileEditForm = ({ userData, onClose }: { userData: any; onClose: () => void }) => {
    const [name, setName] = useState(userData?.name || '');
    const [lastname, setLastname] = useState(userData?.lastname || '');
    const [email, setEmail] = useState(userData?.email || '');

    const { mutate:updateType, isLoading } = useUpdateUserMutation(); 

    const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const updatedData = {
            id: userData?.id, 
            input: {
                name,
                lastname,
                email,
            },
        };
        updateType(updatedData, {
            onSuccess: () => {
                onClose(); 
            },
            onError: (error) => {
                console.error('Error updating profile:', error);
            },
        });
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>Modifier votre profil</Typography>
            <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                placeholder='Nom'
            />
            <Input
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
                placeholder='Prenoms'
            />
            <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                type="email"
                placeholder='Email'
            />
            <ButtonLoading
                type='submit'
                variant="contained"
                label='Modifier'
                loading={isLoading}
                color='secondary'
            />
            <Button
              onClick={onClose}
              sx={{my:1}}  
              fullWidth 
              variant="outlined" 
              color="inherit">
                Annuler
            </Button>
        </Box>
    );
};

export default ProfileEditForm;
