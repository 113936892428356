import React from 'react';
import { SEO } from '../../../components/Seo';
import PageContainer from '../../../components/layout/container';
import { Box } from '@mui/material';
import AdminList from '../../../components/admin/admin/liste';
import { HeaderTitle } from '../../../components/header/title';

export default function AdminListPage (){
    return(
      <React.Fragment>
         <SEO title='Liste des admin'/>
          <PageContainer>
              <Box sx={{px:4,pt:4,borderRadius:2}}>
                  <HeaderTitle title="Administrateurs" />
              </Box>
              <AdminList />
          </PageContainer>
      </React.Fragment>
    )
  }