import * as React from 'react';
import { PieChart } from '@mui/x-charts';
import { Card, styled } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
    textAlign: 'start',
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    margin:5
}));
export default function GraphPie() {
    
  return (
   <StyledCard>
     <PieChart
      series={[
        {
          data: [
                { id: 0, value: 2, label: 'Outlier' },
                { id: 1, value: 5, label: 'Remotasks' },
                { id: 3, value: 7, label: 'Amazon' },
                { id: 4, value: 11, label: 'Plateform x' },
              ],
              innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -126,
            endAngle: 225,
            cx: 150,
            cy: 150,
            highlightScope: { fade: 'global', highlight: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        }
      ]}
      width={500}
      height={300}
    />
   </StyledCard>
  );
}
