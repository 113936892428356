import React from 'react'
import { useDeleteTypeServiceMutation, useTypeServicesQuery } from '../../../data/type service'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'

export default function TypeServiceList(){

    const { typeServices } = useTypeServicesQuery({})
    const headers =[
      {name:"Type de Service",value:"nom_type"},
      {name:"Description",value:"description"},
      {name:"Action",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_type,
                  deleteMutation:useDeleteTypeServiceMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.nom_type}</b> de la liste des types de service ?`,
                  ...record
                }}
                deleteModalView='DELETE'
                editModalView="ADD_TYPE_SERVICE"
                detailLink={`/admin/type-service/${record?.id_type}`}
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={typeServices} headers={headers}/>
      </React.Fragment>
    )
  }
  