import { Plateforme, PlateformInput, QueryOptions } from '../types';
import { PlateformPaginator } from '../types/paginator';
import { PlateformQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';

export const PlateformClient = {
    ...crudFactory<Plateforme, QueryOptions, PlateformInput>(API_ENDPOINTS.PLATEFORMS),
    alls: ({ id_plateforme, ...params }: Partial<PlateformQueryOptions>) => {
      return HttpClient.get<PlateformPaginator>(API_ENDPOINTS.PLATEFORMS, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ id_plateforme }),
      });
    },
  };
  