import { useState } from 'react'
import { useCreateServiceMutation, useUpdateServiceMutation } from '../../../data/service'
import { Plateforme, Service, TypeService } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import { Box, Button, Typography, Grid2 as Grid } from '@mui/material'
import { typographyFont } from '../../../theme'
import ButtonLoading from '../../ui/button/button-loading'
import Input from '../../Input'
import { TypeServiceInput } from '../type service/select'
import TextEditor from '../../ui/input/text-editor'
import { PlateformInput } from '../plateform/select'
import logos from '../../../logo 4x4.png';
import Uploader from '../../ui/input/uploader'

export const ServiceForm = ({defaultValues}:{defaultValues:Service}) => {

    const { mutate: createType, isLoading: loading } = useCreateServiceMutation()
    const { mutate: updateType, isLoading } = useUpdateServiceMutation()
    const update = Boolean(defaultValues?.id_service)
    
    const { closeModal } = useModalAction()
    const defaultImage = update ? JSON.parse(defaultValues.image) : {thumbnail:logos}
    
    const [image,setImage] = useState<any>(defaultImage)
    const [service, setService] = useState(defaultValues.nom_service);
    const [prix, setPrix] = useState(defaultValues.prix);
    const [delais, setDelais] = useState(defaultValues.delais);
    const [typeService, setTypeService] = useState<TypeService>(defaultValues.type_service);
    const [ plateforme,setPlateforme] = useState<Plateforme>(defaultValues.plateforme);
    const [description, setDescription] = useState(defaultValues.description);  
   
   
    const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
        
        if(update){
          updateType({
            id: String(defaultValues?.id_service),
            nom_service: service,
            prix: prix,
            delais: delais,
            description: description,
            type_id: typeService.id_type,
            plateforme_id:plateforme?.id_plateforme,
            image: JSON.stringify(image),
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
            nom_service: service,
            prix: prix,
            delais: delais,
            description: description,
            type_id: typeService.id_type,
            plateforme_id: plateforme?.id_plateforme,
            image: JSON.stringify(image),
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
          <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire Service</Typography>
         
           <Box> 
           <Grid container>
            <Grid size={{ xs:12 ,sm:5,md:4}} sx={{textAlign:'center'}}>
                <Box component="img" src={image.thumbnail} alt="logo" width={{lg:200,md:200,sm:180,xs:150}}/>
            </Grid>
            <Grid size={{ xs:12 ,sm:7,md:8}}>
              <Uploader multiple={false}
                          setSelectedFile={setImage}
                          value={image}
                  />
                <Input name='service'
                      placeholder='Nom de Service'
                      type='text' required 
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                />
              <Box display='flex' gap={2}>
              <TypeServiceInput setSelected={setTypeService} initialValue={typeService}/>
              <PlateformInput  setSelected={setPlateforme} initialValue={plateforme}/>
              </Box>
              <Box display='flex' gap={2}>
                      <Input name='prix'
                            placeholder='Prix'
                            type='text' required 
                            value={prix !== undefined ? prix.toString() : ''}
                            onChange={(e) => setPrix(Number(e.target.value))}
                      />

                      <Input name='delais'
                            placeholder='Delais'
                            type='text' required 
                            value={delais !== undefined ? delais.toString() : ''}
                            onChange={(e) => setDelais(Number(e.target.value))}
                      />
                  </Box>
                <TextEditor editorContent={description} setEditorContent={setDescription}/>
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid>
        
               </Grid>
               </Grid>
            </Box>
        </Box>
    )
  }