import React from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { SEO } from '../../components/Seo';
import { Link } from 'react-router-dom';
import LoginForm from '../../components/auth/LoginForm';
import SocialButtons from '../../components/auth/SocialBouton';
import { tokens } from '../../theme';

const Login: React.FC = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const image = `${process.env.PUBLIC_URL}/illustration/yellow/Online world-amico.png`;
  const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
  const image2 = `${process.env.PUBLIC_URL}/illustration/green/Login-rafiki.png`;

 

  return (
    <Box component='div' sx={{ backgroundColor: colors.primary[600], minHeight: '100vh' }}>
      <SEO title='Login' />
      <Container component="main" maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%',padding:{xl:10} }}>
          <Grid size={{xs:12, md:7}} display="flex" justifyContent="center" sx={{ position: 'relative' }}>
            <Box 
              component="img" 
              src={image} 
              alt="Illustration"
              sx={{
                position: 'relative',
                width: { sm: '80%',xl:'130%' },
                zIndex: 1,
                left: {sm:'30%'},
                bottom: { sm: '20%' },
                objectFit: 'contain',
                display: { xs: 'none', sm: 'block' }, 
              }}
            />
            <Box 
              component="img" 
              src={image2} 
              alt="Illustration" 
              sx={{
                position: 'relative',
                width: { sm: '90%',xl:'130%' },
                zIndex: 2,
                right: { sm: '25%',xl:'60%' },
                objectFit: 'contain',
                display: { xs: 'none', sm: 'block' }, 
            }} 
            />
          </Grid>
          <Grid size={{xs:12, md:5 }}>
            <Paper elevation={3} sx={{ boxShadow:'2px 2px 5px 2px black', padding: { xs: 3, sm: 4 },marginTop:{xs : 2}, backgroundColor: colors.grey[800], borderRadius: '15px',width: { xl: '70vh' },height:{xl:'75vh'}}}>
              <Box display="flex" alignItems="center" justifyContent='center' sx={{ marginBottom: 2 }}>
                <Box component="img" src={logo} alt="Logo" sx={{ width: 'auto', height: {xs:50, md:50, xl:80}, marginRight: 2, marginBottom: 2 }} />
                <Typography variant="h2" fontSize={{xl:70}} fontFamily='inika' color='white' align="left" gutterBottom >
                  Login
                </Typography>
              </Box>
              <LoginForm/>
              <Box marginBottom={{xl: 9}}>

              </Box>
              <SocialButtons />
              <Typography fontSize={{xl:20}} variant="body2" align="center" sx={{ marginTop: 2, color: 'white' }}>
                Vous n’avez pas de compte ? 
                <Link to="/register" style={{ textDecoration: 'none',color:'#227f50' }}>
                  <span> Créer un compte</span>
                </Link>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Login;