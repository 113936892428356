import React from 'react';
import { SEO } from '../../../components/Seo';
import { Box } from '@mui/material';
import { HeaderTitle } from '../../../components/header/title';
import TypeServiceList from '../../../components/admin/type service/liste';
import PageContainer from '../../../components/layout/container';

export default function TypeServicePage (){
  return(
    <React.Fragment>
       <SEO title='Liste des types de services'/>
        <PageContainer>
            <Box sx={{px:4,pt:4,borderRadius:2}}>
                <HeaderTitle title="Type de services" addModalView="ADD_TYPE_SERVICE" />
            </Box>
            <TypeServiceList />
        </PageContainer>
    </React.Fragment>
  )
}