import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@mui/material';
import Links from './link';

interface IProps {
    width?:number|string;
    height?:number|string;
    text:string,
    link:string;
}

const TruncatedTextCard:React.FC<IProps> = ({width="100%",height=100,text,link} ) => {

  const [isOverflowing, setIsOverflowing] = useState<boolean>(false); // State to track if text overflows
  const textRef = useRef<HTMLDivElement | null>(null); // Reference to the text container

  useEffect(() => {

    const checkOverflow = ()=> {
      const textElement = textRef.current;
      if (textElement) {
        setIsOverflowing(textElement.scrollHeight > textElement.clientHeight); // Check if overflow
      }
    };

    checkOverflow(); // Check on mount
    window.addEventListener('resize', checkOverflow); // Recheck on window resize

    return () => {
      window.removeEventListener('resize', checkOverflow); // Cleanup on unmount
    };
  }, []);

  return (
    <>
      <Typography component="span"
        ref={textRef}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 4,  // Adjust the number of lines visible
          WebkitBoxOrient: 'vertical',
          width: width,
          maxHeight: height
        }}
      >
        <Typography dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
      {isOverflowing && (
          <Links  to={link} text="Afficher plus" />
        )
    }
  </>
  );
};

export default TruncatedTextCard;