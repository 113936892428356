import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { TypeVenteClient } from '../api/type vente';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { useModalAction } from '../components/ui/modal/modal.context';
import { GetParams, TypeVente } from '../types';
import { TypeVenteQueryOptions } from '../types/queryOptions';
import { TypeVentePaginator } from '../types/paginator';

export const useCreateTypeVenteMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(TypeVenteClient.create, {
      onSuccess: (data: any) => {
        
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEVENTES]);
      },
    });
  };
  
  export const useDeleteTypeVenteMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(TypeVenteClient.delete, {
      onSuccess: (data:any) => {
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEVENTES]);
      },
    });
  };
  
  export const useUpdateTypeVenteMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(TypeVenteClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEVENTES]);
      },
    });
  };
  
  export const useTypeVenteQuery = ({ slug, language }: GetParams) => {
    return useQuery<TypeVente, Error>({
     queryKey: [API_ENDPOINTS.TYPEVENTES, { slug, language }],
     queryFn: () => TypeVenteClient.get({ id:slug, language })
    });
  };
  
  export const useTypeVentesQuery = (options?: Partial<TypeVenteQueryOptions>) => {
    const { data, isLoading, error } = useQuery<TypeVentePaginator, Error>({
     queryKey:[API_ENDPOINTS.TYPEVENTES, options],
     queryFn: ({ queryKey, pageParam }) =>
        TypeVenteClient.alls(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      typeVentes: data ?? [],
      loading: isLoading,
      error,
    };
  };