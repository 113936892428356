import React, { useState } from 'react';
import {
  Container,
  Grid2 as Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { SEO } from '../../components/Seo';
import PageContainer from '../../components/layout/container';
import { useMeQuery } from '../../data/user';
import ProfileEditForm from '../../components/auth/ModifProfile';

export default function ProfilePage() {
  const { data } = useMeQuery();
  const [isEditing, setIsEditing] = useState(false);
  console.log(data) 
  return (
    <React.Fragment>
       <SEO title='Profile'/>
       <PageContainer>
       <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent='center'>
          {/* <Grid size={{ xs:12 }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="#">
                Home
              </Link>
              <Link color="inherit" href="#">
                User
              </Link>
              <Typography color="textPrimary">User Profile</Typography>
            </Breadcrumbs>
          </Grid> */}

          <Grid  size={{ lg:4 }}>
            <Card>
              <CardContent sx={{textAlign:'center'}}>
                <Box component="img" src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=1024x1024&w=is&k=20&c=-mUWsTSENkugJ3qs5covpaj-bhYpxXY-v9RDpzsw504=" alt="logo" width={{lg:300,md:300,sm:180,xs:150}}/>
                <Typography color='secondary' variant="h4">
                  {/* {data?.lastname} */}
                  <strong>{data?.name} {data?.lastname}</strong>
                </Typography>
                <Typography variant='h6' color='warning' gutterBottom>
                <strong>{data?.email} </strong>
                </Typography>
                <Button color='secondary' fullWidth variant='contained'  onClick={() => setIsEditing(true)}>Modifier Profile</Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid size={{ lg:8 }}>
            <Card>
              <CardContent>
                {isEditing ?(
                  <ProfileEditForm userData={data} onClose={() => setIsEditing(false)} />
                ):(
                  <>
                  <Grid container spacing={2} m={3}>
                  <Grid size={{ sm:3 }}>
                    <Typography color='secondary'>Full Name</Typography>
                  </Grid>
                  <Grid size={{ sm:9 }}>
                    <Typography color="textPrimary">{data?.name} {data?.lastname}</Typography>
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} m={3}>
                  <Grid size={{ sm:3 }}>
                    <Typography color='secondary'>Email</Typography>
                  </Grid>
                  <Grid size={{ sm:9 }}>
                    <Typography color="textPrimary">{data?.email}</Typography>
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} m={3}>
                  <Grid size={{ sm:3 }}>
                    <Typography color='secondary'>Phone</Typography>
                  </Grid>
                  <Grid size={{ sm:9 }}>
                    <Typography color="textPrimary">+261 33 00 000 00</Typography>
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} m={3}>
                  <Grid size={{ sm:3 }}>
                    <Typography color='secondary'>Date de naissance</Typography>
                  </Grid>
                  <Grid size={{ sm:9 }}>
                    <Typography color="textPrimary">15 Septembre 1997</Typography>
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} m={3}>
                  <Grid size={{ sm:3 }}>
                    <Typography  color='secondary'>Lieu de naissance</Typography>
                  </Grid>
                  <Grid size={{ sm:9 }}>
                    <Typography color="textPrimary">Manjakaray Antananarivo 101</Typography>
                  </Grid>
                </Grid><hr />
                <Grid container spacing={2} m={3}>
                  <Grid size={{ sm:3 }}>
                    <Typography  color='secondary'>Numero CIN</Typography>
                  </Grid>
                  <Grid size={{ sm:9 }}>
                    <Typography color="textPrimary">101 00 0000 00</Typography>
                  </Grid>
                </Grid><hr />
                <Grid container spacing={2} m={3}>
                  <Grid size={{ sm:3 }}>
                    <Typography  color='secondary'>Date CIN</Typography>
                  </Grid>
                  <Grid size={{ sm:9 }}>
                    <Typography color="textPrimary">10 Avril 1999</Typography>
                  </Grid>
                </Grid>
                  </>
                )
              }
                
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
       </PageContainer>
    </React.Fragment>
      
  );
}