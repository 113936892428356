import { Container, Grid2 as Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageContainer from '../../layout/container';
import { SEO } from '../../Seo';
import { HeaderTitle } from '../../header/title';
import { useTypePaiementQuery } from '../../../data/type paiement';
import Logo from '../../ui/image/logo';

export default function DetailTypePaiement() {
    const { slug } = useParams();
    const { data,isLoading, error } = useTypePaiementQuery({ slug });
    if (isLoading) return <Typography>Chargement...</Typography>;
    if (error) return <Typography color="error">Erreur : {error.message}</Typography>;
    return (
        <PageContainer>
            <SEO title={data?.nom_type || 'Détails'} />
            <HeaderTitle title='Détail du Type de Paiement' />
            <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent='center'>
                <Grid  size={{ lg:4 }}>
                    <Logo logo={String(data?.logo)} width={300} height={300}/>
                </Grid>
                <Grid size={{lg:8}}>
                    <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Type Paiement</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.nom_type}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Numero</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.numero}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Code transfert</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.code_transfert}</Typography>
                    </Grid>
                </Grid>
            </Grid>
          
            </Grid>
            </Container>
        </PageContainer>
    );
}