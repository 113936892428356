import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import PropTypes from 'prop-types'; 

interface IProps {
  label: any;
  items: any;
  text: string;
  name: any;
  sx?: any;
  icon?: React.ReactNode;
  setSelected?: any;
  value?: any; 
}

const AutoComplete = React.forwardRef<any, IProps>(({ label, sx, items, name, text, setSelected, value, ...rest }, ref) => {
  const getOptionLabel = (option: any) => (option[text] ? String(option[text]) : "");

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setSelected(newValue);
    } else {
      setSelected(null); 
    }
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Autocomplete
        disablePortal
        freeSolo
        ref={ref}
        {...rest}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        options={items}
        value={value} 
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0, cursor: "pointer" } }} {...props}>
            {option[text]}
          </Box>
        )}
        renderInput={params => (
          <TextField
            name={name}
            {...params}
            color="secondary"
            label={label}
            variant="outlined"
            sx={sx}
          />
        )}
      />
    </Stack>
  );
});

AutoComplete.displayName = "AutoComplete";

AutoComplete.propTypes = {
  label: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sx: PropTypes.object,
  setSelected: PropTypes.func,
  value: PropTypes.any,
};

export default AutoComplete;