import React, { ReactNode } from 'react'
import { Typography, useMediaQuery, useTheme } from '@mui/material';

interface TypoProps {
    variant:any
    children:ReactNode
    sx?:any
  }
  const  TypographyResponsive: React.FC<TypoProps> = ({variant,children,sx})=>{
   
    const theme = useTheme()
    const screen = theme.breakpoints
    const variants = ["h1","h2","h3","h4","h5","h6","subtitle1","subtitle2","body1","body2","caption"]
    const index = variants.indexOf(variant)
    const isLg = useMediaQuery(screen.up('lg'))
    const isMd = useMediaQuery(screen.up('md'))
    const isSm = useMediaQuery(screen.up('sm'))
  
    const type =isLg ? variant :  isMd  ? variants[index+1] : isSm  ?  variants[index+2] : variants[index+3]
  
    return(
      <> 
        <Typography variant={type} sx={sx}>{children}</Typography>
      </>
    )
  }

  export default  TypographyResponsive