
import logocopy from "../../Logocopy.png";
import { Box, Button, Stack, Typography } from '@mui/material';
import { Grid2 } from '@mui/material';
import {  typographyFont } from '../../theme';
import { ReactNode } from 'react';

interface IHeader {
  children?:ReactNode;
  description:string;
  

}
export default function Header({description,children}:IHeader){
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
    return(
     <>
      <Stack width='100%' alignItems='center'justifyContent='space-between' paddingX={{xs:1.5,md:5}} direction={{xs:'column',md:'row'}}>
            <Grid2 data-aos="fade-right"  width='100%' sx={{ backgroundImage:{xs:`url(${logocopy})`,sm:`url(')`,md:`url(')`},backgroundSize: 'cover', backgroundRepeat: "no-repeat"}} size={{xs:12,md:8}} marginTop={{xs:7,md:0}}  padding={{xs:1,md:2,xl:4}}>
                <Typography  sx={{...typographyFont.h1,fontWeight:900,lineHeight:{md:1.3 ,xl:1.3} }}>
                  We work as a <br />Freelancer<br /><span >Together</span>
                </Typography>

                  <Typography  maxWidth={{md:400}} sx={{...typographyFont.h5}}>
                    {description} 
                  </Typography>
                <Grid2 container  spacing={2} sx={{marginY:{md:2,xs:3}}}>
                    {
                       children
                    }
                    <Button   color="inherit" variant="contained">Contactez nous</Button>
                </Grid2>
            </Grid2>
            <Grid2 sx={{display:{xs:'none',sm:'block'}}} margin={1}  size={{xs:12,sm:4,md:4, }} padding={{xs:1 ,md:2}} textAlign={{xs:'center'}}>
              <Box width={{xs:100,sm:100,md:500,xl:700}}  component='img' src={logocopy}  alt="logo" />
            </Grid2>
          </Stack>
    
     </>
    )
}

