import { useState } from 'react'
import { useCreatePlateformMutation, useUpdatePlateformMutation } from '../../../data/plateform'
import { Plateforme } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import logos from '../../../logo 4x4.png';
import { Box, Button, Typography,Grid2 as Grid } from '@mui/material';
import { typographyFont } from '../../../theme';
import ButtonLoading from '../../ui/button/button-loading';
import Input from '../../Input';
import { TypePlateformInput } from '../type platefom/select';
import Uploader from '../../ui/input/uploader';
import TextEditor from '../../ui/input/text-editor';

export const PlateformForm = ({defaultValues}:{defaultValues:Plateforme}) => {

    const { mutate: createType, isLoading: loading } = useCreatePlateformMutation()
    const { mutate: updateType, isLoading } = useUpdatePlateformMutation()
    const update = Boolean(defaultValues?.id_plateforme)
    
    const { closeModal } = useModalAction()
    const defaultLogo = update ? JSON.parse(defaultValues.logo) : {thumbnail:logos}

    const [plateform, setPlateform] = useState(defaultValues.nom_plateforme);
    const [lien, setLien] = useState(defaultValues.link);
    const [logo,setLogo] = useState<any>(defaultLogo)
    const [typePlateform, setTypePlateform] = useState<any>(defaultValues.type_plateforme);
    const [description, setDescription] = useState(defaultValues.description);  

   const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
     
      if(update){
          updateType({
            id: String(defaultValues?.id_plateforme),
            nom_plateforme: plateform,
            description: description,
            link: lien,
            type_id: typePlateform.id_type,
            logo: JSON.stringify(logo),
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
            nom_plateforme: plateform,
            description: description,
            link: lien,
            type_id: typePlateform.id_type,
            logo: JSON.stringify(logo)
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
  
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:1000 }} >
         <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire de Plateforme</Typography>
         <Grid container> 
          <Grid size={{ xs:12 ,sm:5,md:4}} sx={{textAlign:'center'}}>
               <Box component="img" src={logo.thumbnail} alt="logo" width={{sm:180,xs:150}}/>
          </Grid>
          <Grid size={{ xs:12 ,sm:7,md:8}}>
            <Uploader multiple={false}
                      setSelectedFile={setLogo}
                      value={logo}
                      // acceptFile={acceptFile}
                      // helperText={helperText} 
                 />
              <Input name='nom_plateforme'
                    placeholder='Nom de Plateform'
                    type='text' required 
                    value={plateform}
                    onChange={(e) => setPlateform(e.target.value)}
              />
              <Input name='link'
                    placeholder='Lien de Plateform'
                    type='text' required 
                    value={lien}
                    onChange={(e) => setLien(e.target.value)}
              />
              <TypePlateformInput setSelected={setTypePlateform} initialValue={typePlateform}/>
              <Box sx={{marginY:1}} >
                   <TextEditor editorContent={description} setEditorContent={setDescription}/>
              </Box>
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid>
            </Grid>
              <Grid size={{ xs:12 ,sm:7,md:8}}>
                 
                    <Uploader multiple={false}
                          setSelectedFile={setLogo}
                          value={logo}
                          // acceptFile={acceptFile}
                          // helperText={helperText} 
                    />
                    <Input name='nom_plateforme'
                          placeholder='Nom de Plateform'
                          type='text' required 
                          value={plateform}
                          onChange={(e) => setPlateform(e.target.value)}
                    />
                    <Input name='link'
                          placeholder='Lien de Plateform'
                          type='text' required 
                          value={lien}
                          onChange={(e) => setLien(e.target.value)}
                    />
                  <TypePlateformInput setSelected={setTypePlateform} />
                  <Box sx={{marginY:1}} >
                      <TextEditor editorContent={description} setEditorContent={setDescription}/>
                  </Box>
                    <Grid   size={{ xs:12,md:6}} >
                            <ButtonLoading loading={loading||isLoading}
                              variant="contained"
                              color="secondary"
                              type="submit"
                              label={update?"Modifier" : "Enregistrer"} />
                    </Grid>
                    <Grid   size={{ xs:12,md:6 }}  >
                            <Button sx={{my:1}}  
                            fullWidth 
                            variant="outlined" 
                            color="inherit" 
                            onClick={clearForm} >
                              Annuler
                            </Button>
                    </Grid>
              </Grid> 
        </Grid>
      </Box>
    )
  }