import { Box, Grid2, Typography, useTheme } from '@mui/material';
import FormationCard from './card';
import { tokens, typographyFont } from '../../../../theme';
import { SEO } from '../../../Seo';
import { useFormationsQuery } from '../../../../data/formation';
// import { useState } from 'react';
// import { useTypeFormationQuery } from '../../../../data/type formation';


export default function ListeDetailFormation({slug}:{slug:string}){
   
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    // const [nomFormation ,setNom] = useState("")

    const { formations } = useFormationsQuery({slug:slug})
    // const { data } = useTypeFormationQuery({slug:slug})
  
    // setNom(!data?"404: Formation introuvable" : (data && formations ? data?.nom_type  : "Formation indisponible"))

    return(
        <>
            <SEO title={"nomFormation"} />
            {
             (
                <Box width='100%' sx={{background:colors.primary[500],paddingBlock:3}}>
                    <Grid2>
                        <Typography variant='h1'sx={{textAlign:'center',...typographyFont.h2,fontWeight:900}}>
                            Formations 
                        </Typography>
                    </Grid2>
                    {Array.isArray(formations) && formations.map((item:any,index:number) => (
                        <Grid2 key={index}
                            bgcolor={index%2!==0 ? colors.primary[600] : ""} >
                            <FormationCard item={item}  />
                        </Grid2>
                    ))}
                </Box>
             )
            }
        </>
    )
}