import { Box, Button, Container, Paper, TextField, Typography, useTheme } from '@mui/material'
import React,{ useState } from 'react'
import { SEO } from '../../components/Seo'
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import { tokens } from '../../theme';

const VerificationPassword2 = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const fields = Array.from({ length: 6 });
  const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
  const image = `${process.env.PUBLIC_URL}/illustration/green/Authentication-amico.png`;
  const image1 = `${process.env.PUBLIC_URL}/illustration/yellow/Authentication-cuate.png`;
  const [values, setValues] = useState<string[]>(Array(6).fill(''));
  const handleChange = (index: number, newValue: string) => {
    if (/^[0-9]?$/.test(newValue)) {
      const newValues = [...values]; 
      newValues[index] = newValue; 
      setValues(newValues); 
    }
  };
  const handleVerify = () => {
    console.log('Valeurs des champs :', values);
  };
  return (
    <Box component='div' sx={{ backgroundColor: colors.primary[600],minHeight:'100vh' }}>
      <SEO title='Verification' />
      <Container component="main" maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <Grid  size={{xs:12 ,md:7}} display="flex" justifyContent="center" sx={{ position: 'relative' }}>
            <Box 
              component="img" 
              src={image} 
              alt="Illustration"
              top={70}
              sx={{
                position: 'relative',
                width: { sm: '100%' },
                height: 'auto',
                maxHeight: { sm: '500px', xl:'100vh' },
                right:{xl:'10%'},
                objectFit: 'contain',
                display: { xs: 'none', sm: 'block' },
              }}
            />
               <Box 
                 component="img" 
                 src={image1} 
                 alt="Illustration"
                 bottom={{md:90}}
                 left={{md:-60, xl:-150 }}
                 sx={{
                 position: 'relative',
                 width: { md: '50%', xl:'50%'},
                 height: 'auto',
                 maxHeight: { sm: '500px' },
                right: { sm: '5%' },
                top: { sm: '80%' },
                objectFit: 'contain',
                display: { xs: 'none', sm: 'block' },
              }}
            />
        </Grid>
          <Grid  size={{xs:12, md:5}}>
              <Paper elevation={3} sx={{boxShadow:'2px 2px 5px 2px black', padding: { xs: 3, sm: 4 }, backgroundColor:  colors.grey[800], borderRadius: 5, marginTop: {xs:15, md:10, xl:20}, width: { xl: '70vh' } }}>
              <Box display="flex" alignItems="center" justifyContent='center' flexDirection='column' sx={{ marginBottom: 2 }}>
                <Box component="img" src={logo} alt="Logo" sx={{ width: 'auto', height: {xs:50, md:50,xl:70} }} />
                <Typography variant="h1"  fontSize={{xl:50}} align="center" gutterBottom sx={{ fontFamily: 'inika', color: 'white' }}>
                  Verification
                </Typography>
                <Typography fontFamily='Inter' fontSize={{md:17,xl:30}} marginTop={5} marginBottom={2} align="center">
                Nous vous envoyons un code de 6 chiffre  sur votre mail. 
                </Typography>
                <Typography fontFamily='Inter' fontSize={{md:17,xl:30}} >
                Veuillez entrer ici
                </Typography>
              </Box>
              <Grid display="flex" flexDirection="row" gap={{xs:'none', md: 1}} justifyContent='center'>
              {fields.map((_, index) => (
        <TextField
          key={index} 
          name={`field-${index}`} 
          required
          type='password'
          value={values[index]}
          onChange={(e) => handleChange(index, e.target.value)}
          sx={{
            padding:1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              backgroundColor: 'white',
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
            '& .MuiInputBase-input': {
              color: 'black',
              height: {xs:5,md:5,xl:20},
              width: {xs:30, md:30,xl:50},
            },
          }}
        />
      ))}
              </Grid>
              <Box>
              <Button variant="contained" fullWidth sx={{marginTop: {xs:2, md:2, xl:5}, backgroundColor: '#1F6F42', borderRadius: 5,fontSize:{xl:20} }} onClick={handleVerify} type='submit'>
                  Verifier
             </Button>
             <Typography fontSize={{xl:20}} variant="body2" align="center" sx={{ marginTop: {md:2,xl:5}, color: 'white' }}>
                <Link to='*' style={{ textDecoration: 'none' }}>
                <span style={{ color: '#1F6F42' }}>Renvoyer code</span>
                </Link>
                  <span >  dans 50s</span>
                </Typography>
              </Box>
              </Paper>
          </Grid>
        </Grid> 
      </Container>
    </Box>
  )
}

export default VerificationPassword2
