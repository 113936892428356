import { useTypeVentesQuery } from '../../../data/type vente'
import { ListeProps } from '../../../types/mui'
import AutoComplete from '../../ui/input/autocomplete'

interface TypeVenteInputProps extends ListeProps {
    initialValue?: { id_type: string; nom_type: string } | null;
  }

export function TypeVenteInput({setSelected, initialValue}:TypeVenteInputProps){

    const { typeVentes } = useTypeVentesQuery({})

    return(
        <>
        <AutoComplete
              text="nom_type"
              items={typeVentes}
              label="Type de vente"
              name="typeVente"
              sx={{mt:2}}
              setSelected={setSelected}
              value={initialValue}
              />
        </>
    )
}