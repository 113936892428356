import React from 'react';
import PageContainer from '../../../components/layout/container';
import { Box } from '@mui/material';
import { SEO } from '../../../components/Seo';
import { HeaderTitle } from '../../../components/header/title';
import ClientList from '../../../components/admin/client/liste';

export default function ClientPage (){
  return(
    <React.Fragment>
       <SEO title='Liste des clients'/>
        <PageContainer>
            <Box sx={{px:4,pt:4,borderRadius:2}}>
                <HeaderTitle title="Clients" />
            </Box>
            <ClientList />
        </PageContainer>
    </React.Fragment>
  )
}