import { Box } from '@mui/material'
import  Header  from '../../../../components/header/Header'
import Footer from '../../../../components/footer/Footer'
import { useParams } from 'react-router-dom'
import ListeDetailFormation from '../../../../components/clients/formations/details/liste'
import React from 'react'

const FormationByTypePage:React.FC = () =>{
  
    // const [seoTitle, setSeoTitle] = useState<string>("") 
    
    const { slug } = useParams()
    return(
        <>
            
            <Header description='' />
            <Box>
                <ListeDetailFormation slug={String(slug)} />
            </Box>
            <Footer/>
        </>
    )
}

export default FormationByTypePage