import { Candidat, CandidatInput, QueryOptions } from '../types';
import { CandidatPaginator } from '../types/paginator';
import { CandidatQueryOptions } from '../types/queryOptions';
import { API_ENDPOINTS } from './api-endpoints';
import { crudFactory } from './crud-factory';
import { HttpClient } from './http-clients';

export const CandidatClient = {
    ...crudFactory<Candidat, QueryOptions, CandidatInput>(API_ENDPOINTS.CANDIDAT),
    alls: ({ id_candidat, ...params }: Partial<CandidatQueryOptions>) => {
      return HttpClient.get<CandidatPaginator>(API_ENDPOINTS.CANDIDAT, {
        searchJoin: 'and',
        ...params,
        search: HttpClient.formatSearchParams({ id_candidat }),
      });
    },
  };
  