import React from 'react'
import { useDeleteTypeFormationMutation, useTypeFormationsQuery } from '../../../data/type formation'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { TypeFormation } from '../../../types'
import Logo from '../../ui/image/logo'

export default function TypeFormationList(){

    const { typeFormations } = useTypeFormationsQuery({})
    const headers =[
      {name:"Image",value:'image',
        template: function Render(record: TypeFormation){
        
          return(
            <>
         {    record?.image &&( <Logo logo={record?.image} />) }
            </>
          )
        }
      },
      {name:"Type de formation",value:"nom_type"},
      {name:"Description",value:"description",align:'left'},
      {name:"Action",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_type,
                  deleteMutation:useDeleteTypeFormationMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.nom_type}</b> de la liste des types de formation ?`,
                  ...record
                }}
                deleteModalView='DELETE'
                editModalView="ADD_TYPE_FORMATION"
                detailLink={`/admin/type-formation/${record?.id_type}`} 
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={typeFormations} headers={headers}/>
      </React.Fragment>
    )
  }
  