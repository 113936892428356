import React from 'react'
import { useDeleteVenteMutation, useVentesQuery } from '../../../data/vente'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { Vente } from '../../../types'
import Logo from '../../ui/image/logo'
import Links from '../../ui/Typography/link'
import { Typography } from '@mui/material'

export default function VenteList(){

    const { ventes } = useVentesQuery()
    const headers =[
      {name:"Image",value:'image',
        template: function Render(record: any){
        
          return(
            <>
              <Logo logo={record?.image} />
            </>
          )
        }
      },
      {name:"Plateforme", value:"plateforme",
        template: (record:Vente)=>{
          return(
            <>
             <Links text={record?.plateforme?.nom_plateforme} to={record?.plateforme?.link} />
            </>
          )
        }
      },
      {name:"Type Vente", value:"type_vente",
        template: (record:Vente)=>{
          return(
            <>
              { record?.type_vente?.nom_type}
            </>
          )
        }
      },
      {name:"Prix",value:"prix",
        template: (record: Vente) => (
          <Typography>Ar {record.prix}</Typography>
      )
      },
      {name:"Delais",value:"delais",
        template: (record: Vente) => (
          <Typography>{record.delais} Jours</Typography>
      )
      },
      { name: "Disponible", value: "is_disponible",
        template: function Render(record: any){
            
          return record.is_disponible == 1 ? 'Oui' : 'Non';
        }

      },
      {name:"Description", value:"description"},
      {name:"Action",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_vente,
                  deleteMutation:useDeleteVenteMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.nom_type}</b> de la liste des vente ?`,
                  ...record
                }}
                deleteModalView='DELETE'
                editModalView="ADD_VENTE"
                detailLink={`/admin/ventes/${record?.slug}`}
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={ventes} headers={headers}/>
      </React.Fragment>
    )
  }
  