import { useState } from 'react'
import { useCreateTypeFormationMutation, useUpdateTypeFormationMutation } from '../../../data/type formation'
import { TypeFormation } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import { Box, Button, Grid2 as Grid, Typography } from '@mui/material'
import { typographyFont } from '../../../theme'
import Input from '../../Input'
import TextEditor from '../../ui/input/text-editor'
import ButtonLoading from '../../ui/button/button-loading'
import logos from '../../../logo 4x4.png';
import Uploader from '../../ui/input/uploader'

export const TypeFormationForm = ({defaultValues}:{defaultValues:TypeFormation}) => {

    const { mutate: createType, isLoading: loading } = useCreateTypeFormationMutation()
    const { mutate: updateType, isLoading } = useUpdateTypeFormationMutation()
    const update = Boolean(defaultValues?.nom_type)
    
    const { closeModal } = useModalAction()
    const defaultImage = update ? JSON.parse(defaultValues.image) : {thumbnail:logos}
  
    const [typeFormation, setTypeFormation] = useState(defaultValues.nom_type);
    const [description, setDescription] = useState(defaultValues.description);  
    const [image,setImage] = useState<any>(defaultImage)
      const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
        console.log('Type Formation:', typeFormation,'','Description:',description);
        if(update){
          updateType({
            id: String(defaultValues?.id_type),
            description: description,
            nom_type: typeFormation,
            image: JSON.stringify(image)
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
            nom_type: typeFormation,
            description: description,
            image: JSON.stringify(image),
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
  
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
          <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire Type de Formation</Typography>
           <Grid container> 
              <Grid size={{ xs:12 ,sm:5,md:4}} sx={{textAlign:'center'}}>
                  <Box component="img" src={image.thumbnail} alt="logo" width={{lg:200,md:200,sm:180,xs:150}}/>
              </Grid>
              <Grid size={{ xs:12 ,sm:7,md:8}}>
              <Uploader multiple={false}
                        setSelectedFile={setImage}
                        value={image}
                />
                <Input name='typeformation'
                      placeholder='Type Formation'
                      type='text' required 
                      value={typeFormation}
                      onChange={(e) => setTypeFormation(e.target.value)}
                />
                <TextEditor editorContent={description} setEditorContent={setDescription}/>
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid> 
                 </Grid>
            </Grid>
        </Box>
    )
  }