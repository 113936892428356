export const Routes = {
    dashboard: '/dashboard',
    login: '/login',
    logout: '/logout',
    register: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    adminMyAgences: '/my-agences',
    profile: '/profile',
    verifyCoupons: '/coupons/verify',
    settings: '/settings',
    storeSettings: '/vendor/settings',
    storeKeepers: '/vendor/store_keepers',
    profileUpdate: '/profile-update',
    checkout: '/orders/checkout',
    verifyEmail: '/verify-email',
    visite:{
        ...routesFactory('/visites'),
      },
      reservation:{
        ...routesFactory('/reservations'),
      },
    propriete:{
      ...routesFactory('/proprietes'),
    },
    user: {
      ...routesFactory('/users'),
    },
    type: {
      ...routesFactory('/type'),
    },
    typebien: {
      ...routesFactory('/type-biens'),
    },
    typeagence: {
      ...routesFactory('/type-agence'),
    },
    typepublication: {
      ...routesFactory('/type-publication'),
    },
    banner: {
      ...routesFactory('/banners'),
    },
    subscription: {
      ...routesFactory('/subscription'),
    },
    category: {
      ...routesFactory('/categories'),
    },
    attribute: {
      ...routesFactory('/attributes'),
    },
    attributeValue: {
      ...routesFactory('/attribute-values'),
    },
    tag: {
      ...routesFactory('/tags'),
    },
    reviews: {
      ...routesFactory('/reviews'),
    },
    abuseReviews: {
      ...routesFactory('/abusive_reports'),
    },
    abuseReviewsReport: {
      ...routesFactory('/abusive_reports/reject'),
    },
    proprietaire: {
      ...routesFactory('/proprietaires'),
    },
    coupon: {
      ...routesFactory('/coupons'),
    },
    manufacturer: {
      ...routesFactory('/manufacturers'),
    },
    location: {
      ...routesFactory('/location'),
    },
    orderStatus: {
      ...routesFactory('/order-status'),
    },
    orderCreate: {
      ...routesFactory('/orders/create'),
    },
    appartement: {
      ...routesFactory('/appartements'),
    },
    chambres: {
      ...routesFactory('/chambres'),
    },
    terrains: {
      ...routesFactory('/terrains'),
    },
    agence: {
      ...routesFactory('/agences'),
    },
    tax: {
      ...routesFactory('/taxes'),
    },
    shipping: {
      ...routesFactory('/shippings'),
    },
    withdraw: {
      ...routesFactory('/withdraws'),
    },
    staff: {
      ...routesFactory('/agents'),
    },
    refund: {
      ...routesFactory('/refunds'),
    },
    question: {
      ...routesFactory('/questions'),
    },
    message: {
      ...routesFactory('/message'),
    },
    agenceMessage: {
      ...routesFactory('/agence-message'),
    },
    conversations: {
      ...routesFactory('/message/conversations'),
    },
    storeNotice: {
      ...routesFactory('/store-notices'),
    },
    storeNoticeRead: {
      ...routesFactory('/store-notices/read'),
    },
  };

  function routesFactory(endpoint: string) {
    return {
      list: `${endpoint}`,
      create: `${endpoint}/create`,
      editWithoutLang: (slug: string, agence?: string) => {
        return agence
          ? `/${agence}${endpoint}/${slug}/edit`
          : `${endpoint}/${slug}/edit`;
      },
      edit: (slug: string, language: string, agence?: string) => {
        return agence
          ? `/${language}/${agence}${endpoint}/${slug}/edit`
          : `${language}${endpoint}/${slug}/edit`;
      },
      translate: (slug: string, language: string, agence?: string) => {
        return agence
          ? `/${language}/${agence}${endpoint}/${slug}/translate`
          : `${language}${endpoint}/${slug}/translate`;
      },
      details: (slug: string) => `${endpoint}/${slug}`,
    };
  }
