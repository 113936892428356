import { usePlateformsQuery } from '../../../data/plateform'
import { ListeProps } from '../../../types/mui'
import AutoComplete from '../../ui/input/autocomplete'

interface plateformInputProps extends ListeProps {
    initialValue?: { id_plateforme: number; nom_plateforme: string } | null;
  }

export function PlateformInput({setSelected,initialValue}:plateformInputProps){

    const { plateforms } = usePlateformsQuery({})

    return(
        <>
        <AutoComplete
              text="nom_plateforme"
              items={plateforms}
              label="Plateforme"
              name="nom_plateforme"
              sx={{mt:2}}
              setSelected={setSelected}
              value={initialValue}
              />
        </>
    )
}