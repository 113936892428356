import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { ReservationClient } from '../api/reservation';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { useModalAction } from '../components/ui/modal/modal.context';
import { GetParams, Reservation } from '../types';
import { ReservationQueryOptions } from '../types/queryOptions';
import { ReservationPaginator } from '../types/paginator';

export const useCreateReservationMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(ReservationClient.create, {
      onSuccess: (data: any) => {
        
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.RESERVATION]);
      },
    });
  };
  
  export const useDeleteReservationMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(ReservationClient.delete, {
      onSuccess: (data:any) => {
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.RESERVATION]);
      },
    });
  };
  
  export const useUpdateReservationMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(ReservationClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.RESERVATION]);
      },
    });
  };
  
  export const useReservationQuery = ({ slug, language }: GetParams) => {
    return useQuery<Reservation, Error>({
     queryKey: [API_ENDPOINTS.RESERVATION, { slug, language }],
     queryFn: () => ReservationClient.get({ id:slug, language })
    });
  };
  
  export const useReservationsQuery = (options?: Partial<ReservationQueryOptions>) => {
    const { data, isLoading, error } = useQuery<ReservationPaginator, Error>({
     queryKey:[API_ENDPOINTS.RESERVATION, options],
     queryFn: ({ queryKey, pageParam }) =>
        ReservationClient.alls(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      reservations: data ?? [],
      loading: isLoading,
      error,
    };
  };