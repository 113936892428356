

import { Box } from '@mui/material';
import { SEO } from '../../../components/Seo';
import PostulationForm from '../../../components/clients/emploies/postulation';
import { useParams } from 'react-router-dom';
import Footer from '../../../components/footer/Footer';


 export default function PostulationPage() {
  
  const { slug } = useParams<{ slug: string }>();
    

  return (
    <>
      <SEO title='Formulaire de candidature' />
      <Box>
          <PostulationForm  slug={String(slug)} />
      </Box>
      <Footer />
    </> 
  );
}

