import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Input from '../../components/Input';
import { getFormErrors } from '../../api/http-clients';
import { allowedRoles, getAuthCredentials, hasAccess, isAuthenticated, setAuthCredentials } from '../../utils/auth-utils';
import { useNavigate } from 'react-router-dom';
import { useSnackbarAction } from '../ui/snackbar/snackbar-context';
import { userType } from '../../types/enum';
import { useRegisterMutation } from '../../data/user';

const RegisterForm: React.FC = () => {
    const [form, setForm] = useState({
        nom: '',
        prenom: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const { mutate: register,isLoading } = useRegisterMutation();
    const { token, permissions } = getAuthCredentials()
    const navigate= useNavigate();
    // const { mutate: login } = useLogin();
    const { openSnackbar } = useSnackbarAction()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (form.password !== form.confirmPassword) {
            return alert("Les mots de passe ne correspondent pas");
        }
        register({
            name: form.nom,
            lastname: form.prenom,
            email: form.email,
            password: form.password,
            permission:userType.CLIENT
        },
        {
            onSuccess: (data:any) => {
              if (data?.token) {
               if (hasAccess(allowedRoles, data?.permissions)) {
                    setAuthCredentials(data?.token, data?.permissions);
                    return;
                }
                 openSnackbar("Vous n'avez pas de permission",'warning'); 
              }
            },
           
           onError:(data:any)=>{
              openSnackbar(getFormErrors(data),'error');  
           },
          });
       
    };
    useEffect(()=>{
        if(isAuthenticated({token,permissions })){
          navigate('/success-create-account');
        }
      })
    

    return (
        <Box component="form"  onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid size={{xs:12, md:6 }}>
                    <Input 
                      name="nom" 
                      placeholder='Nom' 
                      value={form.nom} 
                      required 
                      onChange={handleChange}
                    />
                </Grid>
                <Grid size={{xs:12, md:6 }} >
                    <Input 
                      name="prenom" 
                      placeholder='Prénom' 
                      value={form.prenom} 
                      required 
                      onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Input 
              name="email" 
              placeholder='Email'
              value={form.email} 
              required 
              onChange={handleChange}
            />
            <Input 
              name="password" 
              placeholder='Mot de passe'
              value={form.password} 
              type='password' 
              required 
              onChange={handleChange}
            />
            <Input name="confirmPassword" placeholder='Retapez mot de passe' value={form.confirmPassword} type='password' required onChange={handleChange}
            />
            <Button 
                variant="contained" 
                fullWidth 
                sx={{ marginTop: 2, backgroundColor: '#1F6F42', borderRadius: '15px', fontSize: { xl: 20 } }} 
                type="submit"
                disabled={isLoading} 
            >
                {isLoading ? 'Création en cours...' : 'Créer un compte'}
            </Button>
        </Box>
    );
};

export default RegisterForm;