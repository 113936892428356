import { CastForEducation, ListAlt, Person, Storefront } from '@mui/icons-material';
import { Card, CardContent, Grid2 as Grid, styled, Typography } from '@mui/material'

const StyledCard = styled(Card)(({ theme }) => ({
    textAlign: 'start',
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    margin:5
}));
export const DashboardStats = () => {
  
    const stats = [
        { title: "Clients", value: 10, icon: <Person sx={{fontSize:50}}/> },
        { title: "Ventes", value: "500", icon:  <Storefront sx={{fontSize:50}}/> },
        { title: "Formations", value: "9", icon:  <CastForEducation sx={{fontSize:50}}/> },
        { title: "Services", value: 10, icon:  <ListAlt sx={{fontSize:50}}/> },
    ];
  return (
    <Grid container spacing={2}>
    {stats.map((stat, index) => (
        <Grid  size={{xs:12, sm:6, md:3}} key={index}>
            <StyledCard>
                <CardContent>
                    <Typography variant='h1'>{stat.icon}</Typography>
                    <Typography variant="h4" fontSize={30}>{stat.value}</Typography>
                    <Typography variant="body2" fontSize={15}  color='secondary'>{stat.title}</Typography>
                </CardContent>
            </StyledCard>
        </Grid>
    ))}
</Grid>
  )
}
