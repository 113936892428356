
import { Box, List, ListItem, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { tokens, typographyFont } from '../../../../theme';
import { useServicesQuery } from '../../../../data/service';
import Logo from '../../../ui/image/logo';
import TruncatedTextCard from '../../../ui/Typography/description';
import { Service } from '../../../../types';

export default function ServiceDevPage() {

    const {services } = useServicesQuery()
    
    const theme = useTheme()
    const colors= tokens(theme.palette.mode)

    return(
        <>
        <Stack width='100%' sx={{background:colors.primary[500],}}>
            <Typography padding={{xs:1,md:2}} sx={{...typographyFont.h2,fontWeight:900,textAlign:'center'}}>Nos Services de developpement</Typography>
            {Array.isArray(services) && services.map((item: Service,index:number) => 
            <List  key={item.id_service} sx={{ color:'balck', padding:'1rem' ,
                                                fontSize:'2rem',
                                                bgcolor:index%2!==0 ? colors.primary[600] : ""
            }}>
              <ListItem >
              <ListItemText color={"primary"}
                primary={
                    <React.Fragment>
                      <Stack direction="row" spacing={2}>
                        <Logo logo={String(item.image)} />
                        <Typography
                          component="span"
                          variant="h3"
                        >
                        {item.nom_service}
                        </Typography>
                      </Stack>
                      <Box paddingX={6} paddingBlock={2}>
                          <TruncatedTextCard text={item.description} link={item.slug}  width="65%"/>
                      </Box>
                    </React.Fragment>
                    } 
      
              />
             
              </ListItem>
           </List>
            )}
         </Stack>
        </>
    )
}