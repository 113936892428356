import { createContext, useMemo, useState} from 'react'
// import { createTheme } from '@mui/material/styles'
import { createTheme, PaletteMode, Theme } from '@mui/material'

export {tokens,themeSettings,useMode,ColorModeContext,typographyFont}

const tokens = (mode:PaletteMode) =>({
...(mode ==='dark'
   ?{
    grey: {
        100: '#e0e0e0',
        200: '#c2c2c2',
        300: '#a3a3a3',
        400: '#858585',
        500: '#666666',
        600: '#525252',
        700: '#3d3d3d',
        800: '#292929',
        900: '#141414',
    },
    primary: {
        100: '#d5d5d6',
        200: '#aaabad',
        300: '#808285',
        400: '#55585c',
        500: '#2b2e33',
        600: '#222529',
        700: '#1a1c1f',
        800: '#111214',
        900: '#09090a',
    },
    green: {
        100: '#d3e5dc',
        200: '#a7ccb9',
        300: '#7ab296',
        400: '#4e9973',
        500: '#227f50',
        600: '#1b6640',
        700: '#144c30',
        800: '#0e3320',
        900: '#071910',
    },
    redAccent: {
        100: '#f8dcdb',
        200: '#f1b9b7',
        300: '#e99592',
        400: '#e2726e',
        500: '#db4f4a',
        600: '#af3f3b',
        700: '#832f2c',
        800: '#58201e',
        900: '#2c100f',
    },
    yellow: {
        100: '#fdfbd3',
        200: '#faf7a7',
        300: '#f8f47a',
        400: '#f5f04e',
        500: '#f3ec22',
        600: '#c2bd1b',
        700: '#928e14',
        800: '#615e0e',
        900: '#312f07',
    },
    } :
   {
        grey: {
            100: '#141414',
            200: '#292929',
            300: '#3d3d3d',
            400: '#525252',
            500: '#666666',
            600: '#858585',
            700: '#a3a3a3',
            800: '#c2c2c2',
            900: '#e0e0e0',
        },
        primary: {
            100: "#333333",
            200: "#666666",
            300: "#999999",
            400: "#cccccc",
            500: "#cccccc",
            600: "#ffffff",
            700: "#ffffff",
            800: "#ffffff",
            900: "#ffffff",
        },
        green: {
            100: '#071910',
            200: '#0e3320',
            300: '#144c30',
            400: '#1b6640',
            500: '#227f50',
            600: '#4e9973',
            700: '#7ab296',
            800: '#a7ccb9',
            900: '#d3e5dc',
        },
        redAccent: {
            100: '#2c100f',
            200: '#58201e',
            300: '#832f2c',
            400: '#af3f3b',
            500: '#db4f4a',
            600: '#e2726e',
            700: '#e99592',
            800: '#f1b9b7',
            900: '#f8dcdb',
        },
        yellow: {
            100: '#312f07',
            200: '#615e0e',
            300: '#928e14',
            400: '#c2bd1b',
            500: '#f3ec22',
            600: '#f5f04e',
            700: '#f8f47a',
            800: '#faf7a7',
            900: '#fdfbd3',
        },
    }
    ),
})

//mui theme settings
 const themeSettings = (mode :PaletteMode) =>{
    const colors = tokens(mode);

    return {
        palette:{
            mode:mode,
            ...(mode ==='dark'
            ?
            {
                primary:{
                    main: colors.primary[500],
                },
                secondary:{
                    main:colors.green[500],
                },
                neutral:{
                    dark:colors.grey[700],
                    main:colors.grey[500],
                    light:colors.grey[100],
                },
                background:{
                    default: colors.primary[500],
                },
            }:{
                primary:{
                    main: colors.primary[100],
                },
                secondary:{
                    main:colors.green[500],
                },
                neutral:{
                    dark:colors.grey[700],
                    main:colors.grey[500],
                    light:colors.grey[100],
                },
                background:{
                    default: '#fcfcfc',
                },
            }),
        },
        typography:{
            fontFamily:['Source Sans Pro','sans-serif'].join(','),
            fontSize:12,
            fontWeight:100,
            h1:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:40,
            },
            h2:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:32,
            },
            h3:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:24,
            },
            h4:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:20,
            },
            h5:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:16,
            },
            h6:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:14,
            },
    }
    }
}
// /context
 const ColorModeContext = createContext<{ toggleColorMode: () => void }>({
    toggleColorMode: () => {},
})

const typographyFont = { 
            fontFamily:['Source Sans Pro','sans-serif'].join(','),
            fontSize:14,
            fontWeight:100,
            h1:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:64,lg:58,md:46,sm:40,xs:36},
            },
            h2:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:40,lg:38,md:36,sm:34,xs:32},
            },
            h3:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:32,lg:30,md:28,sm:26,xs:24},
            },
            h4:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:24,lg:23,md:22,sm:21,xs:20},
            },
            h5:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:20,lg:19.5,md:19,sm:18.5,xs:18},
            },
            h6:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:18,lg:17.5,md:15,sm:16.5,xs:16},
            },
            subtitle1:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:16,lg:15.5,md:15,sm:14.5,xs:14},
                fontWeight:400
            },
            subtitle2:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:14,lg:13.5,md:13,sm:12.5,xs:12},
                fontWeight:400
            },
            body1:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:16,lg:15.5,md:15,sm:14.5,xs:14},
            },
            body2:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:14,lg:13.5,md:13,sm:12.5,xs:12},
            },
            caption:{
                fontFamily:['Source Sans Pro','sans-serif'].join(','),
                fontSize:{xl:12,lg:11.5,md:11,sm:10.5,xs:10},
            }
        }

 const useMode = ():[Theme, { toggleColorMode: () => void }] => {
     const [mode,setMode] = useState<PaletteMode>('dark')

     const colorMode = useMemo(
        ()=> ({
            toggleColorMode: () =>
             setMode((prev)=>(prev==='light' ? 'dark' : 'light' ))
        }),
        []
     );

     const theme = useMemo(() => createTheme(themeSettings(mode)),[mode]);

     return [theme, colorMode]
}

