import { useTypeFormationsQuery } from '../../../data/type formation';
import { ListeProps } from '../../../types/mui';
import AutoComplete from '../../ui/input/autocomplete';

interface TypeFormationInputProps extends ListeProps {
  initialValue?: { id_type: string; nom_type: string } | null;
}

export function TypeFormationInput({ setSelected, initialValue }: TypeFormationInputProps) {
  const { typeFormations } = useTypeFormationsQuery({});

  return (
    <>
      <AutoComplete
        text="nom_type"
        items={typeFormations}
        label="Type de formation"
        name="typeFormation"
        sx={{ mt: 2 }}
        setSelected={setSelected}
        value={initialValue}
      />
    </>
  );
}