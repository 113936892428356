import axios from 'axios';

import Cookies from 'js-cookie';
import invariant from 'tiny-invariant';
import { AUTH_CRED } from '../utils/constants';

invariant(
  process.env.REACT_APP_PUBLIC_REST_API_URL,
  'REACT_APP_PUBLIC_REST_API_URL is not defined, please define it in your .env file'
);
const Axios = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_REST_API_URL,
  // withCredentials: true,
  // timeout: 50000,
  headers: {
    'Content-Type': 'application/json',
  },
});
// Change request data/error
// const AUTH_TOKEN_KEY = process.env.REACT_APP_PUBLIC_AUTH_TOKEN_KEY ?? 'authToken';
Axios.interceptors.request.use((config: any) => {
  const cookies = Cookies.get(AUTH_CRED);
  let token = '';
  if (cookies) {
    token = JSON.parse(cookies)['token'];
  }

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };
  return config;
});


// Change response data/error here
Axios.interceptors.response.use(
  (response: any) => response,
  (error :any) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403) ||
      (error.response &&
        error.response.data.message === 'PICKBAZAR_ERROR.NOT_AUTHORIZED')
    ) {
      Cookies.remove(AUTH_CRED);
      // Router.reload();
    }
    return Promise.reject(error);
  }
);

function formatBooleanSearchParam(key: string, value: boolean) {
  return value ? `${key}:1` : `${key}:`;
}

interface SearchParamOptions {
  type_name: string;
  name: string;
  is_approved: boolean;
  surnom:string;
  num_voiture: string;
  id_voiture:string;
  nom_ligne:string;
  nom_zone:string;
  abrev_cop:string;
  id_voyage:string;
  num_place:number;
  nom_voyageur:string;
  nom_type:string;
  reference:string;
  nom_plateform:string;
  nom_formation:string;
  nom_service:string;
  id_vente:string;
  id_reservation:string;
  id_emploie:number;
  id_candidat:number;
  id_plateforme:string
}

export class HttpClient {
  static async get<T>(url: string, params?: unknown) {
    const response = await Axios.get<T>(url, { params });
    return response.data;
  }

  static async post<T>(url: string, data: unknown, options?: any) {
    const response = await Axios.post<T>(url, data, options);
    return response.data;
  }

  static async put<T>(url: string, data: unknown) {
    const response = await Axios.put<T>(url, data);
    return response.data;
  }

  static async delete<T>(url: string) {
    const response = await Axios.delete<T>(url);
    return response.data;
  }

  static formatSearchParams(params: Partial<SearchParamOptions>) {
    return Object.entries(params)
      .filter(([, value]) => Boolean(value))
      .map(([k, v]) =>
        ['type', 'categories', 'tags', 'author', 'manufacturer'].includes(k)
          ? `${k}.slug:${v}`
          : ['is_approved'].includes(k)
          ? formatBooleanSearchParam(k, v as boolean)
          : `${k}:${v}`
      )
      .join(';');
  }
}

export function getFormErrors(error: any) {
  if (axios.isAxiosError(error)) {
    return error.response?.data.message;
  }
  return null;
}

export function getFieldErrors(error: any) {
  if (axios.isAxiosError(error)) {
    return error.response?.data.errors;
  }
  return null;
}
