import Cookies from 'js-cookie'
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryOptionsType } from '../types';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { userClient } from '../api/user';
import { setEmailVerified } from '../utils/auth-utils';
// import { Routes } from 'react-router-dom';
import { useModalAction } from '../components/ui/modal/modal.context';
import { AUTH_CRED } from '../utils/constants';
import { UserPaginator } from '../types/paginator';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../config/routes';
import { User } from '../types/users';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { getFormErrors } from '../api/http-clients';
// import { mapPaginatorData } from '../utils/data-mappers';

export const useMeQuery = () => {
  const queryClient = useQueryClient();
  // const router = useRouter();
  // const pathname = usePathname()

  return useQuery<User, Error>([API_ENDPOINTS.ME], userClient.me, {
     retry: false,

    onSuccess: () => {
      // if (pathname === Routes.verifyEmail) {
        setEmailVerified(true);
        // router.replace(Routes.dashboard);
      // }
    },

    onError: (err) => {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 409) {
          setEmailVerified(false);
          // router.replace(Routes.verifyEmail);
          return;
        }
        queryClient.clear();
        // router.replace(Routes.login);
      }
    },
  });
};

export const useActiveUserMutation = () => {
  const queryClient = useQueryClient(); 
  const { openSnackbar } = useSnackbarAction()
  const { closeModal } = useModalAction()
  return useMutation(userClient.activate, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
      closeModal()
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.USERS]});
    },
  });
};

export function useLogin() {
  return useMutation(userClient.login);
}

export const useLogoutMutation = () => {

  const navigate= useNavigate();
  const { openSnackbar } = useSnackbarAction()
  const { closeModal } = useModalAction()

  return useMutation(userClient.logout, {
    onSuccess: (data: any) => {
      Cookies.remove(AUTH_CRED);
      closeModal()
      navigate(Routes.login);
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
  });
};

export const useRegisterMutation = () => {
  const queryClient = useQueryClient();
 const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.registerAgent, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.REGISTER]});
    },
  });
};

export const useUpdateUserMutation = () => {

  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.update, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.ME]});
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.USERS]});
    },
  });
};
export const useUpdateUserEmailMutation = () => {

  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.updateEmail, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.ME]});
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.USERS]});
    },
  });
}

export const useChangePasswordMutation = () => {
  return useMutation(userClient.changePassword);
};

export const useForgetPasswordMutation = () => {
  return useMutation(userClient.forgetPassword);
};
export const useResendVerificationEmail = () => {
  const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.resendVerificationEmail, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
  });
}

export const useVerifyForgetPasswordTokenMutation = () => {
  return useMutation(userClient.verifyForgetPasswordToken);
};

export const useResetPasswordMutation = () => {
  return useMutation(userClient.resetPassword);
};

export const useMakeOrRevokeAdminMutation = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.makeAdmin, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.USERS]});
    },
  });
};

export const useBlockUserMutation = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.block, {
    
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.USERS]});
      // queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.STAFFS]});
    },
  });
};

export const useUnblockUserMutation = () => {
  const queryClient = useQueryClient();
 const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.unblock, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.USERS]});
      // queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.STAFFS]});
    },
  });
};

export const useAddWalletPointsMutation = () => {

  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.addWalletPoints, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.USERS]});
    },
  });
};

export const useUserQuery = ({ id }: { id: string }) => {
  return useQuery<User, Error>(
    [API_ENDPOINTS.USERS, id],
    () => userClient.fetchUser({ id }),
    {
      enabled: Boolean(id),
    }
  );
};

export const useUsersQuery = (params: Partial<QueryOptionsType>) => {
  const { data, isLoading, error } = useQuery<UserPaginator, Error>({
      queryKey: [API_ENDPOINTS.USERS,params],
      queryFn:()=> userClient.fetchUsers(params),
      keepPreviousData : true
    }
  );

  return {
    users: data ?? [],
    // paginatorInfo: mapPaginatorData(data as any),
    loading: isLoading,
    error,
  };
};

// export const useAdminsQuery = (params: Partial<QueryOptionsType>) => {
//   const { data, isLoading, error } = useQuery<UserPaginator, Error>(
//     [API_ENDPOINTS.ADMIN_LIST, params],
//     () => userClient.fetchAdmins(params),
//     {
//       keepPreviousData: true,
//     }
//   );

//   return {
//     admins: data?.data ?? [],
//     // paginatorInfo: mapPaginatorData(data as any),
//     loading: isLoading,
//     error,
//   };


// };


export const useDeleteUser = () => {
  const queryClient = useQueryClient();
 const { openSnackbar } = useSnackbarAction()

  return useMutation(userClient.delete, {
    onSuccess: (data: any) => {  
      openSnackbar( data?.message);
    },
    onError:(data:any)=>{
       openSnackbar(getFormErrors(data));  
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.ME]});
      queryClient.invalidateQueries({queryKey:[API_ENDPOINTS.USERS]});
    },
  });
};

