import logo from '../logo 4x4.png';
import '../App.css';
import { SEO } from '../components/Seo';
import { Box, Typography } from '@mui/material';
import PageContainer from '../components/layout/container';
import { typographyFont } from '../theme';

function Accueil() {
  
  return (
    <>
       <SEO  title='Accueil'/>
        <PageContainer sx={{display:'flex',justifyContent:'center',justifyItems:'center',paddingX:4}}>
          <Box sx={{marginY:'auto',textAlign:'center'}}>
              <Box component="img" src={logo} className="" alt="logo" width={{xl:300,lg:250,md:150,sm:200,xs:150}}/>
              <Box sx={{marginY:5}}>
                <Typography sx={{...typographyFont.h3}} >Bienvenue sur la plateforme<br /></Typography>
                <Typography sx={{...typographyFont.h1,fontWeight:900}} variant="h1" >GASY M<span style={{color:'#f3ec22'}}>&apos;</span>KETRIKA ONLINE <br />
                    <span  style={{color:'#f3ec22'}}>FREELANCE</span>
                </Typography>
              </Box>
              <Typography sx={{...typographyFont.h5,marginX:'auto',width:{lg:'62%',md:'80%' ,sm:'90%',xs:'100%'}}}>
                    Découvrez notre plateforme complète qui vous accompagne dans chaque étape de 
                    votre parcours freelance : création de comptes, configuration de portefeuilles 
                    électroniques, formations en trading et microtâches, vente de comptes freelance, 
                    ainsi que des services de développement web, mobile, UI/UX et maintenance pour 
                    propulser vos projets au niveau supérieur.
               </Typography >
          </Box> 
        </PageContainer>
    </>
  );
}

export default Accueil;
