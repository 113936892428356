import { Box, Button, Grid2, TextField } from '@mui/material';
import { typographyFont } from '../../theme';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
// import pdp from "../../../../public/illustration/profile-client/avatar.jpeg";
export default  function ProfileClient () {
       const pdp = `${process.env.PUBLIC_URL}/illustration/profile-client/avatar.jpeg`;
       const [inputs, setInputs] = useState([
        { label: 'Nom', defaultValue: 'Rakotonirina', value: 'John Doe',   },
        { label: 'Prenom', defaultValue: 'john', value: 'John Doe',   },
        { label: 'Email', defaultValue: 'john.doe@example.com', value: 'john.doe@example.com',  },
        { label: 'Mot de passe', defaultValue: 'Mot de passe', type:'password', value: '0123456789', },
        { label: 'Mettre a jour votre PDP', type:'file' },
    ]);

    const [isReadOnly, setIsReadOnly] = useState(true);

    const handleEditClick = () => {
        setIsReadOnly(false);
    };

    const handleSaveClick = () => {
        setIsReadOnly(true);
        console.log('Valeurs enregistrées:', inputs);
    };

    const handleInputChange = (index: number, value: string) => {
        const newInputs = [...inputs];
        newInputs[index].value = value;
        setInputs(newInputs);
    };

    return(
        <>
          <Box>
            <Grid2 justifyContent='center' padding={{xs:2,md:'6.5rem 2rem',xl:'10rem 2rem'}} container spacing={{xs:2,md:10,xl:8}}>
                <Grid2 size={{xs:12,md:4}}>
                    <Box component='img' width={{xs:350,md:400,xl:500}} borderRadius={2} sx={{}}   src={pdp}/>
                </Grid2>
                <Grid2  size={{xs:12,md:8}}>
                  {inputs.map((input, index) => (
                      <Box key={index} padding={1}>
                      <TextField
                          id="standard-helperText"
                          label={input.label}
                          defaultValue={input.defaultValue}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          variant="standard"
                          type={input.type}
                          slotProps={{
                            input: {
                              readOnly: isReadOnly,
                            },
                          }}
                          sx={{...typographyFont.h2}}
                          color='secondary'
                        fullWidth
                        />
                      </Box>
                      ))}
                     <Box margin={2} display='flex'gap={2}>
                    <Button color='primary'onClick={handleEditClick} variant='contained'><EditNoteIcon/>Modifier</Button>
                    {!isReadOnly && (
                    <Button color='secondary'  onClick={handleSaveClick}  variant='contained'><SaveIcon/>Enregirtrer</Button>
                  )}
                     </Box>
                </Grid2>
            </Grid2>
          </Box>
        </>
    )
}