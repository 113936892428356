import {  Button, Typography, Box, Stack } from '@mui/material';
import GradeIcon from '@mui/icons-material/Grade';
import { typographyFont } from '../../../theme';
import CardSlider from '../../ui/slide/slider';
import  { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
export interface NosFormation
{
  id :number,
  titre:string,
  image:string,
  href:any,
}

const FormationCard = (formation:any) =>{
  useEffect(() => {
    
    AOS.init({
      duration: 1000,  
      easing: 'ease-in-out',  
      once: false,  
    });
  }); 
   return(
          <>
              <Box data-aos="fade-right" key={formation.id}   
                      sx={{textAlign:'center' ,background:'#EDEDF4',borderRadius:5,paddingX:4,cursor:"pointer"}} >
                <Typography color='primary'  sx={{marginTop:4,...typographyFont.h4}}>{formation.titre}</Typography>
                <Box component="img" sx={{bgcolor:"white",width:'100%',height:200,border:'solid 1px grey',borderRadius:2}} src={formation.image} alt="" />

              <Typography color='primary' component="p" style={{display:'flex' ,alignItems:'center',justifyContent:'space-between',padding:'.5rem'}}>
                <Stack direction="row" spacing={0.5} style={{color:'black',padding:'0.5rem'}}>
                    <GradeIcon sx={{color:'#FFB300'}} />
                    <Typography variant='h5' sx={{fontWeight:600}}>4.4/5</Typography>
                  </Stack>
                <Typography component="span" style={{color:'black',fontSize:'1rem'}}>
                    <Typography  variant='h5' ><b>1000</b> inscrit(s)</Typography>
                  </Typography>
              </Typography> 
              </Box> 
          </>
   )
}
export default function Formations()  {

  const formations: NosFormation[] = [
    { id: 1,  titre: 'Trading',href:'/ListFormationTrading', image:'/illustration/green/Teaching-pana.png' },
    { id: 2, titre: 'Microtache',href:'/ListFormationMicroTache', image:'/illustration/yellow/Teaching-cuate.png' },
    { id: 4, titre: 'Call Center',href:'/ListFormationMicroTache', image:'/illustration/yellow/Teaching-cuate.png' },
    { id: 3, titre: 'Develop',href:'/ListformationDev', image:'/illustration/green/Teaching-bro.png' },
   
];

    return(
        <>          
          <Box sx={{paddingX:2,margin:{xs:1,md:2}}}>
              <Typography variant='h1' sx={{...typographyFont.h2,fontWeight:800,paddingY:4}} data-aos="zoom-in-right" >Nos formations</Typography>
              
                        
                    <CardSlider   items={formations} template={FormationCard} />
    
              
              <Box sx={{paddingY:'2rem'}}>
                  <Button  variant='outlined' sx={{color:'white',background:'primary'}} href='formations' data-aos="fade-down-left">plus d'info</Button>
              </Box>
          </Box>
        </>
    )
}