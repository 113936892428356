import { crudFactory } from './crud-factory';
import { API_ENDPOINTS } from './api-endpoints';
import { QueryOptions, Paiement, PaiementInput } from '../types';
import { HttpClient } from './http-clients';
import { PaiementPaginator } from '../types/paginator';
import { PaiementQueryOptions } from '../types/queryOptions';


export const PaiementClient = {
  ...crudFactory<Paiement, QueryOptions, PaiementInput>(API_ENDPOINTS.PAIEMENTS),
  all: ({ reference, ...params }: Partial<PaiementQueryOptions>) => {
    return HttpClient.get<PaiementPaginator>(API_ENDPOINTS.PAIEMENTS, {
      searchJoin: 'and',
      ...params,
      search: HttpClient.formatSearchParams({ reference }),
    });
  },
};
