
import { UserPaginator } from '../types/paginator';
import { UserQueryOptions } from '../types/queryOptions';
import { AuthResponse, BlockUserInput, ChangePasswordInput, ForgetPasswordInput, LoginInput, MakeAdminInput, RegisterInput, ResetPasswordInput, UpdateUser, User, VerifyForgetPasswordTokenInput, WalletPointsInput } from '../types/users';
import { API_ENDPOINTS } from './api-endpoints';
import { HttpClient } from './http-clients';

  export const userClient = {
    me: () => {
      return HttpClient.get<User>(API_ENDPOINTS.ME);
    },
    login: (variables: LoginInput) => {
       return HttpClient.post<AuthResponse>(API_ENDPOINTS.LOGIN, variables);
    },
    logout: () => {
      return HttpClient.get<any>(API_ENDPOINTS.LOGOUT, {});
    },
    register: (variables: RegisterInput) => {
     return HttpClient.post<AuthResponse>(API_ENDPOINTS.REGISTER_ADMIN, variables);
    },
    registerAgent: (variables: RegisterInput) => {
      return HttpClient.post<AuthResponse>(API_ENDPOINTS.REGISTER, variables);
     },
    activate: (id: any  ) => {
      return HttpClient.post<any>(`${API_ENDPOINTS.ACTIVATE}/${id}`, {});
     },
    update: ({ id, input }: { id: string; input: UpdateUser }) => {
      return HttpClient.put<User>(`${API_ENDPOINTS.USERS}/${id}`, input);
    },
    changePassword: (variables: ChangePasswordInput) => {
      return HttpClient.post<any>(API_ENDPOINTS.CHANGE_PASSWORD, variables);
    },
    forgetPassword: (variables: ForgetPasswordInput) => {
      return HttpClient.post<any>(API_ENDPOINTS.FORGET_PASSWORD, variables);
    },
    verifyForgetPasswordToken: (variables: VerifyForgetPasswordTokenInput) => {
      return HttpClient.post<any>(
        API_ENDPOINTS.VERIFY_FORGET_PASSWORD_TOKEN,
        variables
      );
    },
    resetPassword: (variables: ResetPasswordInput) => {
      return HttpClient.post<any>(API_ENDPOINTS.RESET_PASSWORD, variables);
    },
    makeAdmin: (variables: MakeAdminInput) => {
      return HttpClient.post<any>(API_ENDPOINTS.MAKE_ADMIN, variables);
    },
    block: (variables: BlockUserInput) => {
      return HttpClient.post<any>(API_ENDPOINTS.BLOCK_USER, variables);
    },
    unblock: (variables: BlockUserInput) => {
      return HttpClient.post<any>(API_ENDPOINTS.UNBLOCK_USER, variables);
    },
    addWalletPoints: (variables: WalletPointsInput) => {
      return HttpClient.post<any>(API_ENDPOINTS.ADD_WALLET_POINTS, variables);
    },
    fetchUsers: ({ ...params }: Partial<UserQueryOptions>) => {
      return HttpClient.get<UserPaginator>(API_ENDPOINTS.USERS, {
        searchJoin: 'and',
        ...params,
      });
    },
    fetchUser: ({ id }: { id: string }) => {
      return HttpClient.get<User>(`${API_ENDPOINTS.USERS}/${id}`);
    },
    resendVerificationEmail: () => {
      return HttpClient.post<any>(API_ENDPOINTS.SEND_VERIFICATION_EMAIL, {});
    },
    updateEmail: ({ email }: { email: string }) => {
      return HttpClient.post<any>(API_ENDPOINTS.UPDATE_EMAIL, { email });
    },
    delete: ({ id }: { id: string}) => {
      return HttpClient.delete<User>(`${API_ENDPOINTS.USERS}/${id}`);
    },

  };
