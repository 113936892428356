import React from 'react'
import { useDeleteReservationMutation, useReservationsQuery } from '../../../data/reservation'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'
import { Reservation } from '../../../types'
import { Chip, Typography } from '@mui/material'
import Tooltips from '../../ui/tooltip'
import Logo from '../../ui/image/logo'
import { toMoney } from '../../../utils/transform'
import { useUpdatePaiementMutation } from '../../../data/paiement'
import { getStatus, getStatusColor } from '../../../utils/color'
import { getItemName } from '../../../utils/get-label'

export default function ReservationList(){

    const { reservations } = useReservationsQuery({})

//  console.log(reservations)
    const headers =[
     {name:"Article",value:"service",
      template:(record:Reservation)=>{
        return (
          <Typography>{ getItemName(record)}</Typography>
        )
      }
     },
     {name:"Client",value:'user_id',
      template:(record:Reservation)=>{
          return (
               <Tooltips variant="light"
                        placement="top"
                        arrow={true}
                        title={`${record.client?.name} ${record.client?.lastname}`}>
                        <Typography color="inherit" component="a" href={`${record.client?.slug}`}>
                           {record.client?.lastname} 
                        </Typography>
               </Tooltips>
          )
      }
     },
     {name:"Montant",value:"paiement_id",
      template:(record:Reservation)=>{
      
        return(
                <Typography>{toMoney(record?.paiement?.montant)}</Typography>
              )
      }
     },
     {name:"Réf transfert",value:"reference",
      template:(record:Reservation)=>{
      
        return(
                <Typography>{record?.paiement?.reference}</Typography>
              )
      }
     },
     {name:"Type de paiement",value:"paiement_id",
      template:(record:Reservation)=>{
      
        return(
                <Logo logo={record?.paiement?.type_paiement?.logo} />
              )
      }
     },
      {name:"Status du paiement",value:"is_verified",
        template:(record:Reservation)=>{
          
        return(
                <Chip size="small" color={getStatusColor(record?.paiement?.status)}  variant="filled" label={getStatus(record?.paiement?.status).toUpperCase()}/> 
              )
      }
     },
     {name:"Action",value:"action",
        template:function Render(record:Reservation){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_reservation,
                  deleteMutation:useDeleteReservationMutation,
                  deleteMessage: `Voulez-vous supprimer la réséravation n° <b>${record?.id_reservation}</b> de la liste des reservations ?`,
                  actionMessage:["Avez-vous réçu le payement de cette réservation?","Vous n'avez pas vraiement réçu le payement de cette réservation?"],
                  actionMutation:useUpdatePaiementMutation,
                  actionTitle:["Valider","Réfuser"],
                  actionData:[{id:record?.paiement_id,status:"accepted"},{id:record?.paiement_id,status:"refused"}],
                  ...record
                }}
                deleteModalView='DELETE'
                publishLink="VALIDATE_MODAL"
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable 
                    data={reservations}
                    headers={headers} />
      </React.Fragment>
    )
  }
  