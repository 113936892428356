import { SEO } from '../components/Seo';
import Box from '@mui/material/Box';
import {Button, Grid2,Stack,Typography} from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import BorderColorIcon from '@mui/icons-material/BorderColor';
// import SendIcon from '@mui/icons-material/Send';
import PublicIcon from '@mui/icons-material/Public';
import Footer from '../components/footer/Footer';
// import AutoComplete from '../components/clients/inputAutocomplet/AutocompleteInput';
import Freelance from '../components/clients/home/compte-freelance';
import FindGoodjob from '../components/clients/home/emploies';
import Formations from '../components/clients/home/formations';
import PortefeuilleElectroniques from '../components/clients/home/porte-feuille-electronique';
import AnnonceCall from '../components/clients/home/vente-campagne';
import VenteLoginFreelance from '../components/clients/home/vente-compte-freelance.';
import Subscribe from '../components/clients/home/subscribe';
import Header from '../components/header/Header';
import { typographyFont } from '../theme';
// import AutoComplete from '../components/clients/inputAutocomplet/AutocompleteInput';
import 'aos/dist/aos.css';
import  { useEffect } from 'react';
import  AOS from "aos";
// import MessageIconToggle from '../components/messageAssistant';
export default function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1500, 
      easing: 'ease-in-out', 
      once: false, 
    });
  }, []);
  const developper = `${process.env.PUBLIC_URL}/illustration/green/programming-concept-illustration_114360-1351.jpg`;
  const developments= [
    { id: 1, titre: 'Conception',logo:<BorderColorIcon fontSize="large" />,detail:" Nous transformerons votre idée en maquette de votre choix avec nos experts dans ce metier."},
   { id: 2, titre: 'Integration',logo:<CodeIcon fontSize="large" sx={{marginTop:2}}/>,detail:"Nous  développons votre maquette de votre idéé avec  le langage deprogrammation maitrisé par notre développeur." },
   { id: 3, titre: 'Deploiement',logo:<PublicIcon fontSize="large"sx={{marginTop:2}} />,detail:"Nous assurons le déploiment de votre site si vous n’avez pas de serveur ou des technichiens pour les assurée." },
   
   ];
   
  //  const suggestions = [''];
  //  const initialTags = ['I need a formation','I need developement service'];
  return (
    <>
      <SEO  title='Accueil'/>
        <Header description=" Découvrez notre plateforme complète qui vous accompagne dans chaque étape de 
           votre parcours freelance : création de comptes, configuration de portefeuilles"
          >
            <Button  variant="contained" color="secondary"  href='/service'>Nos services</Button>
        </Header> 
           
      <Box sx={{ background:'#EDEDF4',padding:2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }}spacing={{ xs: 1, sm: 1, md: 2 }}>
       
              <Box padding={2}>
                <Box  data-aos="fade-right" component='img'src={developper} alt="" sx={{width:{xs:320,md:450},borderRadius:'1rem'}}/>
              </Box>

              <Stack>
                <Box sx={{ }}>
                  <Typography color="primary" sx={{...typographyFont.h2,fontWeight:800}} data-aos="fade-left">Developpement Informatique</Typography>
                </Box>

              {developments.map(devItem => (
                    <Grid2 data-aos="fade-up" key={devItem.id}>
                      <Typography color="primary"  sx={{...typographyFont.h4,fontWeight:600}}>
                          <Typography component="span" sx={{fontSize:'3rem',marginX:1,fontWeight:600,marginTop:2}}>
                              {devItem.logo}
                          </Typography>
                          {devItem.titre}
                      </Typography>
                      <Typography color="primary"  component="p" sx={{paddingX:4,width:{xs:"80%",md:"60%"}}}>
                        {devItem.detail}
                      </Typography>
                    </Grid2>
                  ))}
              <Box style={{padding:'1rem'}}>
                <Button  variant='contained' style={{color:'beige', padding:'.5rem 2rem'}} href='/services/developpement-informatique'>Plus info</Button>
              </Box>
              </Stack>
          </Stack> 
     </Box>
     {/* <Box sx={{position:'fixed',left:{xs:1,md:'110rem'},top:{xs:2,md:'50rem'}}}>
        <MessageIconToggle/>
     </Box> */}
                    
         {/* input bg grey */}
       {/* <Grid2  sx={{background:'white',padding:{xs:2,md:3},textAlign:'center'}}>
        <Grid2  data-aos="fade-right" container  width='100%' justifyContent={{xs:'center', md:'center',xl:'center'}} flexDirection={{xl:'column'}} spacing={{ xs: 2,md:3}}  sx={{ background:'#EDEDF4',padding:'2rem',borderRadius:'1rem',textAlign:'center'}}>
          <Grid2 data-aos="fade-up" data-aos-anchor-placement="top-bottom" size={12} sx={{ position:{xl:'relative'},left:{xl:450},background:'#E4FEEB',width:{xs:289,md:800},height:{xs:300,md:200},textAlign:'center',borderRadius:'1rem',color:'black'}}>

            <Typography variant='h1' sx={{margin:'1rem',fontSize:{xs:30,md:40}}}>Obtenez des réponses instantanées</Typography>

                <p style={{fontSize:'1rem'}}>
                  Hi there! I'm Djauck , Admin of Gasy M’ketrika Online.<br /> How can i help you !
                  I need a foramation!I need a development web service!
                </p>

            </Grid2>
          
            <Grid2 size={12} sx={{position:{xl:'relative'},left:{xl:450},textAlign:'center',borderRadius:'1rem',background:{},width:{xs:300,md:700}}}>
              <Grid2 data-aos="fade-left"  sx={{padding:'0.4rem',position:{xs:'relative'},left:{xs:-15,md:0},width:{xs:320,md:720,xl:800},background:'black',alignItems:'center',display:'flex',justifyContent:'center',borderRadius:'2.4rem'}}>
              <AutoComplete suggestions={suggestions} initialTags={initialTags} />
                <Button><SendIcon sx={{fontSize:'3rem',color:'white'}}/></Button>
              </Grid2>
            </Grid2>
          </Grid2>
      </Grid2> */}

        <Formations />
         
         <Freelance/>
        
        <PortefeuilleElectroniques/>
       
        <VenteLoginFreelance />
       
       
        <AnnonceCall/>
        
        <FindGoodjob/>
        
        <Subscribe /> 
        
        <Footer/>
        
   </>
   
  );
}

