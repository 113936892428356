import React from 'react'
import { SEO } from '../../../components/Seo'
import PageContainer from '../../../components/layout/container'
import { DashboardStats } from '../../../components/admin/dashboard/Stats'
import { Grid2 as Grid }  from '@mui/material'
import GraphBar from '../../../components/admin/dashboard/GraphBar'
import GraphPie from '../../../components/admin/dashboard/GraphPie'
import ChartLine from '../../../components/admin/dashboard/GraphLine'
import Chart from '../../../components/admin/dashboard/ChartLine'

export const DashboardPage = () => {
  return (
    <React.Fragment>
        <SEO title='Dashboard' />
        <PageContainer>
            <DashboardStats />
            <Grid container spacing={2}>
              <Grid size={{xs:12, sm:6, md:6}}>
                  <GraphBar />
              </Grid>
              <Grid size={{xs:12, sm:6, md:6}}>
                <GraphPie />
              </Grid>
              <Grid size={{xs:12, sm:6, md:4}}>
                <ChartLine />
              </Grid>
              <Grid size={{xs:12, sm:6, md:8}}>
                <Chart />
              </Grid>
            </Grid>
        </PageContainer>
    </React.Fragment>
  )
}
