import { getAuthCredentials } from './auth-utils';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import usePathName from './router';
import { Routes } from '../config/routes';

const PrivateRoute: React.FC<{
  // authProps: any;
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const pathName = usePathName()
  const { token/*, permissions*/ } = getAuthCredentials();
  const isUser = !!token;

  // const hasPermission =
  //   Array.isArray(permissions) &&
  //   !!permissions.length &&
  //   hasAccess(authProps.permissions, permissions);


  React.useEffect(() => { 
    if (!isUser) navigate(Routes.login); // If not authenticated, force log in
  }, [isUser]);

  if (!isUser && pathName !=='/login') {
     return <>{children}</>;
     }

   return <>{children}</>;
  // if (isUser && hasPermission) {
  //   return <>{children}</>;
  // }
  // if (isUser && !hasPermission) {
  //   return <AccessDeniedPage />;
  // }
  // // Session is being fetched, or no user.
  // // If no user, useEffect() will redirect.
  // return <LoaderCicular variant='indeterminate' />;
};

export default PrivateRoute;
