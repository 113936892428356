import { Box, TextField, useTheme } from '@mui/material';
import { SEO } from '../../../components/Seo';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { tokens, typographyFont } from '../../../theme';
import Footer from '../../../components/footer/Footer';
import  Header  from '../../../components/header/Header';
import ListeVente from '../../../components/clients/ventes/liste';

export default function VentePage() {
    
    const theme= useTheme()
    const colors = tokens(theme.palette.mode)

    return( 
    <>
        <SEO title='Ventes'/>
        <Header description="Découvrez notre plateforme complète qui vous accompagne dans chaque étapevotre parcours freelance : création de comptes, configuration de portefeuilles " /> 
        <Box bgcolor={colors.primary[600]}>
            <Typography  sx={{...typographyFont.h2,fontWeight:800,textAlign:'center',padding:'2rem'}} 
                        color='inherit' 
                        variant="h1">
                NOS COMPTES DISPONIBLE
            </Typography>
            <Box margin={{xs:'0rem 1rem',md:'0rem 18rem',xl:'0rem 25rem'}} alignItems='center' display='flex' justifyContent='center' padding={1} sx={{background:'',}}>
                <TextField  sx={{width:{xs:200,md:500,xl:600},borderRadius:'0.4rem'}}   label="Rechercher votre..."placeholder='recherche...'  multiline maxRows={4}/>
                <Button sx={{height:'3rem'}}  variant='contained' color='secondary'>Recherche</Button>
            </Box>
            <ListeVente />
        </Box>
        <Footer/>
    </>
    )
}