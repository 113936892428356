export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum StatusPaiement{
   PENDING = 'En attente',
   SEEN = 'Vu',
   LOADING = 'En cours',
   ACCEPTED = 'Accepté',
   REFUSED = 'Réfuseé',

}

export enum userType {

   SUPER_ADMIN = 'super_admin',
   ADMIN = 'admin',
   STAFF = 'staff',
   CLIENT = 'client',
   FOLLOWER= "follower",
   BLOQUER ='bloquer'
}
