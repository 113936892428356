import * as React from 'react';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box"
import IconButton from '@mui/material/IconButton';
import { ListItemIcon, Stack, Typography, useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';


interface menuProps{
    icon?:any,
    text?:string,
    variant?:any
    color?:any
    items?:any,
    children?:React.ReactNode
    sx?:any
    }
export default function MenuPopup({icon,sx,text,items,color,variant,children}:menuProps) { 
const navigate = useNavigate()
const theme = useTheme()
const colors = tokens(theme.palette.mode)
const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const open = Boolean(anchorEl);

const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};
  
  return (
        <React.Fragment>
          {icon ?(<IconButton   onClick={handleClick} sx={{ my:2 }} >{icon}</IconButton>) :
                (<Button variant={variant} color={color}  onClick={handleClick} sx={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap", my: 2, color: colors.grey[100] , display: 'block', ...sx }} >
                  <Stack direction="row">
                      <Typography> {text }</Typography> 
                      {anchorEl ? (<ArrowDropUp/>) :(<ArrowDropDown/>)}
                    </Stack>
                </Button>)}
          <Menu key="popup"
            anchorEl={anchorEl}
            sx={{p:0}} open={open} onClose={handleClose} >
            <Box sx={{m:0,bgcolor:colors.primary[800]}}>
            { items && items.map((item:any,index:number)=>(
                <MenuItem key={index} onClick={()=>{handleClose();navigate(item.to)}}>
                      {item.icon && (<ListItemIcon>{item.icon}</ListItemIcon>)}
                      {item.text}
                  </MenuItem> 
                ))
            }
            {
                children && (children)
            }
            </Box>
          </Menu>
        </React.Fragment>
  );
}
