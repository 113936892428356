import { useState } from 'react'
import { useCreateFormationMutation, useUpdateFormationMutation } from '../../../data/formation'
import { Formation } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import { Box, Typography,Grid2 as Grid, Button, FormControlLabel, Checkbox} from '@mui/material'
import { typographyFont } from '../../../theme'
import TextEditor from '../../ui/input/text-editor'
import ButtonLoading from '../../ui/button/button-loading'
import { TypeFormationInput } from '../type formation/select'
import Input from '../../Input'
import { PlateformInput } from '../plateform/select'
import logos from '../../../logo 4x4.png';
import Uploader from '../../ui/input/uploader'

export const FormationForm = ({defaultValues}:{defaultValues:Formation}) => {

    const { mutate: createType, isLoading: loading } = useCreateFormationMutation()
    const { mutate: updateType, isLoading } = useUpdateFormationMutation()
    const update = Boolean(defaultValues?.id_formation)
    
    const { closeModal } = useModalAction()
    const defaultImage = update ? JSON.parse(defaultValues.image) : {thumbnail:logos}

    const [image,setImage] = useState<any>(defaultImage)
    const [nomFormation, setNomFormation] = useState(defaultValues.nom_formation)
    const [prix, setPrix] = useState(defaultValues.prix);
    const [delais, setDelais] = useState(defaultValues.delais);
    const [typeFormation, setTypeFormation] = useState<any>(defaultValues.type_formation);
    const [plateform, setPlateform] = useState<any>(defaultValues.plateforme);
    const [description, setDescription] = useState(defaultValues.description);  
    const [isDisponible, setIsDisponible] = useState<boolean>(update ? defaultValues.is_disponible : false); 
      const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
        // console.log('Plateform:',plateform,'Type formation:',typeFormation,'Prix:',prix,'Delais:',delais,'description:',description)
       if(update){
          updateType({
            id: String(defaultValues?.id_formation),
            nom_formation:nomFormation,
            prix: prix,
            delais: delais,
            description: description,
            type_id: typeFormation.id_type,
            plateforme_id: plateform.id_plateforme,
            image: JSON.stringify(image),
            is_disponible: isDisponible
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
            nom_formation:nomFormation,
            prix: prix,
            delais: delais,
            description: description,
            type_id: typeFormation.id_type,
            plateforme_id: plateform.id_plateforme,
            image: JSON.stringify(image),
            is_disponible: isDisponible
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
           <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire Formation</Typography>
           <Grid container> 
              <Grid size={{ xs:12 ,sm:5,md:4}} sx={{textAlign:'center'}}>
                  <Box component="img" src={image.thumbnail} alt="logo" width={{lg:200,md:200,sm:180,xs:150}}/>
              </Grid>
              <Grid size={{ xs:12 ,sm:7,md:8}}>
                  <Uploader multiple={false}
                          setSelectedFile={setImage}
                          value={image}
                  />
                  <Input name='formation'
                        placeholder='Nom de Formation'
                        type='text' required 
                        value={nomFormation}
                        onChange={(e) => setNomFormation(e.target.value)}
                  />
                 <Box display='flex' gap={2}>
                    <TypeFormationInput setSelected={setTypeFormation} initialValue={typeFormation}/>
                    <PlateformInput setSelected={setPlateform} initialValue={plateform}/>
                 </Box>
                 <Box display='flex' gap={2}>
                    <Input 
                       name='prix'
                       placeholder='Prix'
                       type='number' required 
                       value={prix !== undefined ? prix.toString() : ''}
                       onChange={(e) => setPrix(Number(e.target.value))}
                            /> 
                    <Input name='delais'
                          placeholder='Delais'
                          type='text' required 
                          value={delais !== undefined ? delais.toString() : ''}
                          onChange={(e) => setDelais(Number(e.target.value))}
                    />
                  </Box>
                  <FormControlLabel
                        control={
                            <Checkbox
                                color='secondary'
                                name='isDisponible'
                                checked={isDisponible} 
                                onChange={(e) => setIsDisponible(e.target.checked)} 
                            />
                        }
                        label="Disponible"
                    />
                  <TextEditor editorContent={description} setEditorContent={setDescription}/>
        
                  <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid> 
              </Grid>
              </Grid>
        </Box>
    
    )
  }