import { Box, Checkbox, Container, FormControlLabel, Paper, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SEO } from '../../components/Seo';
import Grid from '@mui/material/Grid2';
import Input from '../../components/Input';
import { allowedRoles, getAuthCredentials, hasAccess, isAuthenticated, setAuthCredentials } from '../../utils/auth-utils';
import { getFormErrors } from '../../api/http-clients';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../data/user';
import { useSnackbarAction } from '../../components/ui/snackbar/snackbar-context';
import ButtonLoading from '../../components/ui/button/button-loading';
import { tokens } from '../../theme';

const Relogin = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
  const image = `${process.env.PUBLIC_URL}/illustration/yellow/Enthusiastic-rafiki.png`;
  const image1 = `${process.env.PUBLIC_URL}/illustration/yellow/Enthusiastic-bro.png`;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  const { token, permissions } = getAuthCredentials()
  const { mutate: login, isLoading } = useLogin();
  const navigate= useNavigate();
  const { openSnackbar } = useSnackbarAction()

  const handleLogin = () => {
    login(
       {
         email:email,
         password:password,
       },
       {
         onSuccess: (data) => {

           if (data?.token) {
             if (hasAccess(allowedRoles, data?.permissions)) {
                 setAuthCredentials(data?.token, data?.permissions);

               return;

             }
              openSnackbar("Vous n'avez pas de permission",'warning'); 
           } else {
            openSnackbar("Email ou mot de passe incorrecte!",'error'); 
           }
         },
        
        onError:(data:any)=>{
           openSnackbar(getFormErrors(data),'error');  
        },
       }
     )
  };

  useEffect(()=>{
    if(isAuthenticated({token,permissions })){
      navigate('/');
    }
  })
  return (
    <Box component='div' sx={{ backgroundColor: colors.primary[600], minHeight: '100vh' }}>
      <SEO title='relogin' />
      <Container component="main" maxWidth="lg">
        <Grid container paddingTop={{xl : 5 }} spacing={5} justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
          <Grid size={{xs:12, md:7 }} display="flex" justifyContent="center" flexDirection='column' alignItems="center">
            <Typography 
              component='h2'
              color='#F2C700' 
              fontFamily='Inter' 
              textAlign={{md:'center',xl:'left', xs:'center'}}
              sx={{
                opacity: 1, 
                fontSize: { xs: '32px', sm: '70px',xl:'10vh' }, 
                fontWeight: 700,
              }}
            >
              Congrulation !
            </Typography>
            <Grid display='flex' flexDirection='row'>
            <Box
              component="img" 
              src={image} 
              alt="Illustration"
              sx={{
                width: { sm: '50%', md: '60%',xl:'100%' }, 
                height: 'auto',
                maxHeight: { sm: '150px', md: '400px',xl:'600px' }, 
                display: { xs: 'none', sm: 'block' },
                margin: '0 auto',
                right:{xl:'40%'},
                position:'relative'
              }}
            />
            <Box 
              component="img" 
              src={image1} 
              alt="Illustration"
              sx={{
                position: 'relative',
                width: { sm: '50%', md: '50%',xl:'70%' }, 
                height: 'auto',
                right:{md:'10%',xl:'55%'},
                maxHeight: { sm: '150px', md: '350px',xl:'500px' }, 
                display: { xs: 'none', sm: 'block' },
                margin: '0 auto',
              }}
            />
            </Grid>
            
            <Typography fontFamily='inter'  width='100%' fontSize={{ xs: '14px', sm: '16px', md: '25px',xl:'40px' }} textAlign={{md:'center',xl:'left', xs:'center'}}>
              Votre mot de passe a été changé. Veuillez-vous reconnecter !
            </Typography>
          </Grid>
          <Grid size={{ xs:12, md:5 }} >
            <Paper elevation={3} sx={{boxShadow:'2px 2px 5px 2px black', padding: { xs: 3, sm: 4 }, backgroundColor: colors.grey[800], borderRadius: 5, marginTop: {md:6,xl:10}, marginBottom: {md:5},marginLeft:{xl:5},width: { xl: '70vh' } }}>
              <Box display="flex" alignItems="center" justifyContent='center' sx={{ marginBottom: 2 }}>
                <Box component="img" src={logo} alt="Logo" sx={{ width: 'auto', height: '50px', marginRight: 2, marginBottom: 2 }} />
                <Typography variant="h2" fontSize={{ xl:70 }} fontFamily='inika' color='white' align="left" gutterBottom >
                  Login
                </Typography>
              </Box>
              <Input 
                name='email' 
                placeholder="Email ou nom d'utilisateur" 
                type='text' 
                required 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input 
                name='password'
                placeholder="Mot de passe"
                type='password'
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <Box>
              <ButtonLoading
                variant="contained"
                label=' Se connecter'
                loading={isLoading}
                handleClick={handleLogin}
                color='secondary'
              />
                <FormControlLabel
                  control={<Checkbox value="remember" color="secondary" />}
                  label="Se souvenir"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      color: 'white',
                      fontSize:{xl:20},
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid> 
      </Container>
    </Box>
  );
}

export default Relogin;