import React from 'react'
import { useDeleteTypePlateformMutation, useTypePlateformsQuery } from '../../../data/type plateform'
import ActionsComponent from '../../ui/table/action'
import CollapsibleTable from '../../ui/table/collapsible'

export default function TypePlateformList(){

    const { typePlateforms } = useTypePlateformsQuery({})
    const headers =[
      {name:"Type de plateform",value:"nom_type"},
      {name:"Description", value:"description"},
      {name:"Actions",value:"action",
        template:function Render(record:any){
        return(
            <ActionsComponent
                id={{
                  id:record?.id_type,
                  deleteMutation:useDeleteTypePlateformMutation,
                  deleteMessage: `Voulez-vous supprimer <b>${record?.nom_type}</b> de la liste des types de plateform ?`,
                  ...record
                }}
                deleteModalView='DELETE'
                editModalView="ADD_TYPE_PLATEFORM"
                detailLink={`/admin/type-plateforme/${record?.id_type}`}
               />
        )
     }
    }
    ]
  
  
    return(
      <React.Fragment>
            <CollapsibleTable data={typePlateforms} headers={headers}/>
      </React.Fragment>
    )
  }
  