import { Box, Button, colors, Grid2, Typography } from '@mui/material';
import { typographyFont } from '../../../../theme';
import { useParams } from 'react-router-dom';
// import {  } from "../../../../../public/illustration/service/card-credit.jpg";
// import { Header } from '../contentHome/Header';
 export default function CreePortfeuillClient () {
  const { slug } = useParams<{ slug: string }>();
    const freelance = `${process.env.PUBLIC_URL}/illustration/service/card-credit.jpg`;
    return(
        <>
               {/* <Header description=" Découvrez notre plateforme complète qui vous accompagne dans chaque étapevotre parcours freelance : création de comptes, configuration de portefeuilles "
             button=""/> */ }
     
     <Box padding={2} sx={{position:'absolute',left:{xs:0,md:'40%'},top:{xs:10,md:'25%'},transform:{xs:0,md:'translateX(-50%)'}}} >
    
     
    <Grid2 direction={{xs:'column-reverse',md:'row'}} container spacing={2}>
      
        <Grid2 padding={0} size={{xs:12,md:8}}>
          <Typography width='100%' variant='h1' sx={{...typographyFont.h1,fontWeight:900}}>Creer votre Portefeuille electronique </Typography>
            <Typography component='p' sx={{color:colors.grey[500]}}>
            Contactez-nous sur whatsapp  C’est une activité d'achat et de vente d'actifs financiers, tels que des actions, des devises, des matières premières, 
            des cryptomonnaies ou des obligations,dans le but de réaliser un profit. Contrairement à l'investissement à long terme, 
            le trading se concentre sur des transactions à court ou moyen terme, en tirant parti des fluctuations de prix sur les marchés financiers.
            </Typography>

            <Box margin={2}>
              <Button color='secondary' href={`/service/detail/${slug}`} variant='contained'>Commencer</Button>
            </Box>
        </Grid2>

        <Grid2 padding={1} size={{xs:12,md:4}}>
          <Box component='img' sx={{width:{xs:350,md:550},height:{xs:200,md:350}, borderRadius:3}} src={freelance}/>
        </Grid2>

    </Grid2>
   </Box>

        </>
    )
}
