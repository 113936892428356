import React from 'react';
import { SEO } from '../../../components/Seo';
import PageContainer from '../../../components/layout/container';
import { Box } from '@mui/material';
import { HeaderTitle } from '../../../components/header/title';
import PaiementList from '../../../components/admin/paiement/liste';

export default function PaiementPage (){
  return(
    <React.Fragment>
       <SEO title='Liste des paiements'/>
        <PageContainer>
            <Box sx={{px:4,pt:4,borderRadius:2}}>
                <HeaderTitle title="Paiements"  />
            </Box>
            <PaiementList />
        </PageContainer>
    </React.Fragment>
  )
}