import { useParams } from 'react-router-dom';
import { useTypeServiceQuery } from '../../../data/type service';
import { SEO } from '../../Seo';
import { HeaderTitle } from '../../header/title';
import { Box, Container, Grid2 as Grid, Typography } from '@mui/material';
import PageContainer from '../../layout/container';

export default function DetailTypeService() {
    const { slug } = useParams();
    const { data } = useTypeServiceQuery({ slug });
    console.log(data)
    const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;

    return (
        <PageContainer>
            <SEO title={data?.nom_type|| 'Détails'} />
            <HeaderTitle title='Détail du Type de Service' />
            <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent='center'>
                <Grid  size={{ lg:4 }}>
                <Box 
              component="img" 
              src={logo} 
              alt="Illustration" 
            />
                </Grid>
                <Grid size={{lg:8}}>
                    <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Type Service</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                       <Typography >{data?.nom_type}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} m={3}>
                        <Grid size={{ sm:3 }}>
                            <Typography color='secondary' component='h1'> Description</Typography>
                        </Grid>
                    <Grid size={{ sm:9 }}>
                    <Typography 
                        component="div"
                        dangerouslySetInnerHTML={{ __html: String(data?.description) }} 
                     />
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            </Container>
        </PageContainer>
    );
}