import { Box,Typography } from '@mui/material';
import Footer from '../../../components/footer/Footer';
import { SEO } from '../../../components/Seo';
import { typographyFont } from '../../../theme';
import ListeEmploies from '../../../components/clients/emploies/liste';
import  Header  from '../../../components/header/Header';
export default function EmploiePage() {

    return(
    <>
      <SEO title='Emploies'/>
      <Header description=" Découvrez notre plateforme complète qui vous accompagne dans chaque étapevotre parcours freelance : création de comptes, configuration de portefeuilles "/> 
      <Box width='100%' sx={{background:''}}> <hr />
            <Typography  padding={{xs:1,md:2}} sx={{...typographyFont.h3,fontWeight:800,color:'',textAlign:'center'}}> OFFRE D'EMPLOIES</Typography>
            <ListeEmploies />
      </Box>
     <Footer/>
    </>
    )
}