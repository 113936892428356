import { Box, Button, Fade, Paper, Stack, Typography } from '@mui/material';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import { getTokenAccepted, setTokenAccepted } from '../../../utils/auth-utils';
import { useState } from 'react';

 

 export function Cookies(){

    const { cookieAccepted }= getTokenAccepted()
    const [bannerOpen, setBannerOpen] = useState(cookieAccepted==true? false : true);
    
    const acceptCoockie = () => {
      setBannerOpen(false)
      setTokenAccepted(true)
    };

    const refuseCoockie = () => {

      setBannerOpen(false)
      setTokenAccepted(false)

    };


    return(
        <>
        <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={bannerOpen}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ justifyContent: 'space-between', gap: 2 }}
            >
              <Box
                sx={{ flexShrink: 1, alignSelf: { xs: 'flex-start', sm: 'center' } }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                     Nous utilisons des cookies pour améliorer votre expérience utilisateur.
                </Typography>
                <Typography variant="body2">
                     En poursuivant votre navigation sur notre site, vous acceptez l'utilisation de 
                     cookies pour personnaliser le contenu, analyser notre trafic, 
                     et offrir des fonctionnalités relatives aux réseaux sociaux. 
                     Vous pouvez gérer vos préférences ou obtenir plus d'informations dans notre  
                    <Typography color="secondary" sx={{textDecoration: 'none',mx:0.5}} component='a' noWrap href="/politique-de-confidencialite">
                       Politique de confidentialité
                    </Typography>.
                </Typography>
              </Box>
              <Stack
                direction={{
                  xs: 'row-reverse',
                  sm: 'row',
                }}
                sx={{
                  gap: 2,
                  flexShrink: 0,
                  alignSelf: { xs: 'flex-end', sm: 'center' },
                }}
              >
                <Button size="small" color="secondary" variant="contained" onClick={acceptCoockie} >
                  Accepter 
                </Button>
                <Button size="small" color="inherit" onClick={refuseCoockie}>
                  Rejeter 
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </TrapFocus>
        </>
    )
 }