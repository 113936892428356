
import React, { useState } from 'react';
 
import { TextField, Typography, Radio, RadioGroup, FormControlLabel, Box, Button, Grid , colors } from '@mui/material';
import  InputPaiment  from './inputPaiment';
import { typographyFont } from '../../../theme';
import logo from "../../../logo 4x4.png";
const PaymentForm: React.FC = () => {
  const [paymentMethod, setPaymentMethod] = useState('card');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (paymentMethod === 'card') {
   // paiements par carte bancaire
      console.log("Paiement par carte bancaire");
    } else if (paymentMethod === 'paypal') {
      //  paiements PayPal
      console.log("Paiement par PayPal");
    }
  };
  const paypal = `${process.env.PUBLIC_URL}/illustration/paiement/images.jpg`;
  const visa = `${process.env.PUBLIC_URL}/illustration/paiement/carte-banck.jpg`;

  const inputs =[
    {id:1 , value:'',label:'Nom du titulaire de la carte',placeholder:'Votre nom'},
    {id:2 , value:'',label:'Numéro de carte',placeholder:'1228 4584 4584 4594'},
    {id:3 , value:'',label:"Date d'expiration",placeholder:'MM/YY'},
   
  ]
  return (
    <form onSubmit={handleSubmit}>
      
      <Grid  container spacing={3}>
        <Grid  xs={12}>
          <Box display='flex' gap={2} alignItems='center' textAlign='center'>
            <Box component='img' width={80} src={logo}/>
            <Typography variant="h1" sx={{...typographyFont.h3,fontWeight:900}}>Méthode de paiement</Typography>
          </Box>
          <RadioGroup value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>

            <Box display='flex'alignItems='center'>
              <FormControlLabel   value="card" color='secondary' control={<Radio sx={{color:colors.green[900]}}/>} label="Carte Bancaire" /> 
              <Box component='img' src={visa} width={60} /> 
            </Box>

            <Box  display='flex'alignItems='center'>
            <FormControlLabel value="paypal" control={<Radio sx={{color:colors.green[900]}} />} label="PayPal" />
             <Box component='img' src={paypal} width={40} />
            </Box>
          </RadioGroup>
        </Grid>
        
        {paymentMethod === 'card' && (
          <Grid   xs={12}>
                    {inputs.map( input => (
                <InputPaiment value={input.value} placeholder={input.placeholder} label={input.label} key={input.id} />    
              
            ))}
           
            <Box display='flex' gap={3} padding={1}>
              <TextField fullWidth label="CVC" variant="outlined"  required />
              <TextField fullWidth label="Code securite" variant="outlined"  required />
            </Box>
          </Grid>
        )}
        {paymentMethod === 'paypal' && (
          <Grid  xs={12}>
            
                <Box display='flex' gap={3} padding={1}>
              <TextField placeholder='Jordy' fullWidth label="Nom" variant="outlined"  required />
              <TextField placeholder='john' fullWidth label="prenom" variant="outlined"  required />
              </Box>
            <Box padding={1}>
             <TextField fullWidth placeholder='+26138 85 489 43' label="Numero phone" variant="outlined" required />
            </Box>
            <Box padding={1}>
             <TextField fullWidth placeholder='10000Ar' label="Montent" variant="outlined" required />
            </Box>
            <Box padding={1}>
              <TextField fullWidth placeholder='john@exemple.com' label="Email" variant="outlined"  required />
            </Box>
            
          </Grid>
        )}
        <Grid sx={{display:'flex',gap:2,marginLeft:3, padding:2}} alignItems='center' justifyContent='center' xs={11}>
          <Button type="submit"  variant="contained"sx={{background:colors.yellow[800]}} fullWidth>
            Annuler
          </Button>
        {paymentMethod === 'paypal' && (
        
          <Button type="submit"  variant="contained"sx={{background:colors.blue[900]}} fullWidth>
            Payer
          </Button>
        )}
        {paymentMethod === 'card' && (
        
          <Button type="submit"  variant="contained"sx={{background:colors.green[900]}} fullWidth>
            Payer
          </Button>
        )}
        
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentForm;
