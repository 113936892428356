import { useCreateEmploieMutation, useUpdateEmploieMutation } from '../../../data/emploie'
import { Emploie } from '../../../types'
import { useModalAction } from '../../ui/modal/modal.context'
import logos from '../../../logo 4x4.png';
import { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid2 as Grid, Typography } from '@mui/material';
import { typographyFont } from '../../../theme';
import Uploader from '../../ui/input/uploader';
import Input from '../../Input';
import TextEditor from '../../ui/input/text-editor';
import ButtonLoading from '../../ui/button/button-loading';

export const EmploieForm = ({defaultValues}:{defaultValues:Emploie}) => {

    const { mutate: createType, isLoading: loading } = useCreateEmploieMutation()
    const { mutate: updateType, isLoading } = useUpdateEmploieMutation()
    const update = Boolean(defaultValues?.id_emploie)
    
    const { closeModal } = useModalAction()
    const defaultImage = update ? JSON.parse(defaultValues.image) : {thumbnail:logos}

    const [image,setImage] = useState<any>(defaultImage)
    const [titreEmploie, setTitreEmploie] = useState(defaultValues.titre_emploie)
    const [reference, setReference] = useState(defaultValues.reference);
    const [description, setDescription] = useState(defaultValues.description); 
    const [isDisponible, setIsDisponible] = useState<boolean>(update ? defaultValues.is_disponible : false); 
      const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
       if(update){
          updateType({
            id: String(defaultValues?.id_emploie),
            image: JSON.stringify(image),
            titre_emploie: titreEmploie,
            reference: reference,
            description: description,
            is_disponible: isDisponible
          },{
            onSuccess: ()=>{
                  closeModal()
            }
          })
        }else{
          createType({
              image: JSON.stringify(image),
              titre_emploie: titreEmploie,
              reference: reference,
              description: description,
              is_disponible: isDisponible
          },{
            onSuccess: ()=>{
                  closeModal()
    
            }
          })
        }
    };
  
      const clearForm = () =>{
        closeModal()
      }
    return (
      
        <Box component='form' onSubmit={handleSubmit} width={{xs:300,sm:600,md:700 }} >
          <Typography  sx={{mb:4,...typographyFont.h3,textAlign:'center'}}>Formulaire Emploie</Typography>
           <Grid container> 
           <Grid size={{ xs:12 ,sm:5,md:4}} sx={{textAlign:'center'}}>
               <Box component="img" src={image.thumbnail} alt="logo" width={{lg:200,md:200,sm:180,xs:150}}/>
          </Grid>
          <Grid size={{ xs:12 ,sm:7,md:8}}>
          <Uploader multiple={false}
                      setSelectedFile={setImage}
                      value={image}
                      // acceptFile={acceptFile}
                      // helperText={helperText} 
                 />
           <Input name='emploie'
                      placeholder='Titre emploie'
                      type='text' required 
                      value={titreEmploie}
                      onChange={(e) => setTitreEmploie(e.target.value)}
                />
               <Input name='reference'
                      placeholder='Reference'
                      type='text' required 
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                />
                   <FormControlLabel
                        control={
                            <Checkbox
                                color='secondary'
                                name='isDisponible'
                                checked={isDisponible} 
                                onChange={(e) => setIsDisponible(e.target.checked)} 
                            />
                        }
                        label="Disponible"
                    />
                <TextEditor editorContent={description} setEditorContent={setDescription}/>
                 <Grid   size={{ xs:12,md:12}} >
                        <ButtonLoading loading={loading||isLoading}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          label={update?"Modifier" : "Enregistrer"} />
                </Grid>
                <Grid   size={{ xs:12,md:12 }}  >
                        <Button sx={{my:1}}  
                         fullWidth 
                         variant="outlined" 
                         color="inherit" 
                         onClick={clearForm} >
                           Annuler
                        </Button>
                 </Grid> 
                 </Grid> 
            </Grid>
        </Box>
    )
  }