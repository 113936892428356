import { Box, Container, Paper, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { SEO } from '../../components/Seo';
import Grid from '@mui/material/Grid2';
import Input from '../../components/Input';
import { useChangePasswordMutation } from '../../data/user';
import ButtonLoading from '../../components/ui/button/button-loading';
import { tokens } from '../../theme';

const ResetPassword = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const logo = `${process.env.PUBLIC_URL}/logo 4x4.png`;
  const image1 = `${process.env.PUBLIC_URL}/illustration/green/Reset password-bro (1).png`;
  const [newMpd, setNewMdp] = useState('');
  const [retaperMdp, setRetaperMdp] = useState('');

  const { mutate: changePassword, isLoading } = useChangePasswordMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('mot de passe 1:', newMpd, 'Mot de passe 2:', retaperMdp);
    if (newMpd === retaperMdp) {
      console.log('mot de passe vérifié');
    } else {
      console.error('error !!!');
    }
    changePassword({
      newPassword: newMpd,
      oldPassword: retaperMdp
    })
  };

  return (
    <Box component='div' sx={{ backgroundColor:  colors.primary[600], minHeight: '100vh' }}>
      <SEO title='Changer le mot de passe' />
      <Container component="main" maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
          <Grid size={{xs:12, md:7 }} display="flex" justifyContent="center" padding={5}>
            <Box 
              component="img" 
              src={image1} 
              alt="Illustration"
              sx={{
                position: 'relative',
                width: { sm: '70%', md: '100%',xl:'150%' },
                height: 'auto',
                maxHeight: { sm: '500px',xl:'80vh'},
                display: { xs: 'none', sm: 'block' },
                right:{xl:'30%'},
                marginTop:{xl:3}
              }}
            />
          </Grid>
          <Grid size={{xs:12, md:5 }}>
            <Paper elevation={3} sx={{boxShadow:'2px 2px 5px 2px black', padding: { xs: 3, sm: 4 }, backgroundColor: colors.grey[800], borderRadius: 5, marginTop: {md:5}, marginBottom: {md:5},width: { xl: '70vh' } }}>
              <Box display="flex" alignItems="center" justifyContent='center' flexDirection='column' sx={{ marginBottom: 2 }}>
                <Box component="img" src={logo} alt="Logo" sx={{ width: 'auto', height: '40px' }} /> 
                <Typography variant="h2" align="left" gutterBottom sx={{ fontFamily: 'Inika', color: 'white', fontSize: { xs: '20px', sm: '24px',xl:'50px' } }}>
                  Changer le mot de passe
                </Typography>
              </Box>
              <Input 
                name='new-password' 
                placeholder="Nouveau mot de passe" 
                type='password' 
                required 
                value={newMpd} 
                onChange={(e) => setNewMdp(e.target.value)}
              />
              <Input 
                name='confirm-password'
                placeholder="Retapez votre nouveau mot de passe"
                type='password'
                value={retaperMdp}
                required
                onChange={(e) => setRetaperMdp(e.target.value)}
              />
              <Box>
              <ButtonLoading
                variant="contained"
                label='Changer mot de passe'
                loading={isLoading}
                handleClick={handleSubmit}
                color='secondary'
              />
              </Box>
            </Paper>
          </Grid>
        </Grid> 
      </Container>
    </Box>
  );
}

export default ResetPassword;