import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbarAction } from '../components/ui/snackbar/snackbar-context';
import { API_ENDPOINTS } from '../api/api-endpoints';
import { useModalAction } from '../components/ui/modal/modal.context';
import { GetParams, TypeFormation } from '../types';
import { TypeFormationQueryOptions } from '../types/queryOptions';
import { TypeFormationPaginator } from '../types/paginator';
import { TypeFormationClient } from '../api/type formation';

export const useCreateTypeFormationMutation = () => {

    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(TypeFormationClient.create, {
      onSuccess: (data: any) => {
        
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEFORMATIONS]);
      },
    });
  };
  
  export const useDeleteTypeFormationMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
  
    return useMutation(TypeFormationClient.delete, {
      onSuccess: (data:any) => {
        console.log(data)
        openSnackbar( data?.message);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEFORMATIONS]);
      },
    });
  };
  
  export const useUpdateTypeFormationMutation = () => {
  
    const queryClient = useQueryClient();
    const { openSnackbar } = useSnackbarAction()
    const { closeModal} = useModalAction()
    
    return useMutation(TypeFormationClient.update, {
      onSuccess: (data:any) => {
        openSnackbar(data?.message);
        closeModal()
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.TYPEFORMATIONS]);
      },
    });
  };
  
  export const useTypeFormationQuery = ({ slug, language }: GetParams) => {
    return useQuery<TypeFormation, Error>({
     queryKey: [API_ENDPOINTS.TYPEFORMATIONS, { slug, language }],
     queryFn: () => TypeFormationClient.get({ id:slug, language })
    });
  };
  
  export const useTypeFormationsQuery = (options?: Partial<TypeFormationQueryOptions>) => {
    const { data, isLoading, error } = useQuery<TypeFormationPaginator, Error>({
     queryKey:[API_ENDPOINTS.TYPEFORMATIONS, options],
     queryFn: ({ queryKey, pageParam }) =>
        TypeFormationClient.alls(Object.assign({}, queryKey[1], pageParam)),
     keepPreviousData: true,
  
    });
    return {
      typeFormations: data ?? [],
      loading: isLoading,
      error,
    };
  };
  