import PageContainer from '../../../components/layout/container';
import { Box } from '@mui/material';
import { SEO } from '../../../components/Seo';
import { HeaderTitle } from '../../../components/header/title';
import React from 'react';
import TypeFormationList from '../../../components/admin/type formation/liste';

export default function TypeFormationPage (){
  return(
    <React.Fragment>
       <SEO title='Liste des types de formation'/>
        <PageContainer>
            <Box sx={{px:4,pt:4,borderRadius:2}}>
                <HeaderTitle title="Type Formations" addModalView="ADD_TYPE_FORMATION" />
            </Box>
            <TypeFormationList />
        </PageContainer>
    </React.Fragment>
  )
}